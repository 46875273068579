import { Component, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Task } from '../models/common-classes';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { User } from '../models/user';

@Component({
  selector: 'app-dialog-tasks',
  templateUrl: './dialog-tasks.component.html',
  styleUrls: ['./dialog-tasks.component.scss']
})
export class DialogTasksComponent implements OnInit, OnDestroy {
  tasks: Task[];
  claimId: number;
  user: User | null = null;
  user$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogTasksComponent>,
    private router: Router,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      this.user$ = this.userService.user.subscribe((res: User) => {
        this.user = res
      })
    }

  ngOnInit(): void {
    this.tasks = this.data.tasks.filter(task => task.status != 'Completed');
    this.claimId = this.data.claimId
  }

  ngOnDestroy(): void {
    if (this.user$) this.user$.unsubscribe()
  }

  openClaim(task) {
    this.closeDialog()
    const url = this.router.serializeUrl(this.router.createUrlTree(['/claim', this.claimId, 'task-type', task.taskTypeId, 'task', task.id]));
    window.open(url, '_blank')
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
