import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[currency]',
})
export class ThousandsDotSeparatorDirective {
  constructor(private _inputEl: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: any) {
    const inputValue: string = this._inputEl.nativeElement.value;
    const previousInputValue = inputValue.slice(0, inputValue.length - 1);
		const abstractControl = this.control.control;

    if (inputValue === '') {
      abstractControl?.setValue(null);
      return;
    }
    //check if string start with point or '0,'
    if (inputValue === ',' || inputValue === '0,') {
      abstractControl?.setValue('0,');
      return;
    }
    
    if (inputValue.includes('.')) {
      abstractControl?.setValue(new Number(inputValue.replaceAll('.', '')));
      return;
    }

    //check for invalid char input - the regex will handle the decimal points
    if (!/^[0-9.]+(?:\,\d{0,2})?$/.test(inputValue)) {
      abstractControl?.setValue(previousInputValue);
      return;
    }

    //check if user input a comma
    // if (inputValue.includes(',')) {
    //   if (!inputValue.includes('.')) {
    //     abstractControl?.setValue(this.checkStringWithCommaInsertedByUser(inputValue));
    //     return;
    //   } else {
    //     const valueCommasSplitted = inputValue.split(',');
    //     //check invalid dot after comma
    //     if (inputValue[inputValue.length - 2] === ',') {
    //       abstractControl?.setValue(previousInputValue);
    //       return;
    //     }
    //     //check invalid dot for value < 3digit
    //     if (
    //       valueCommasSplitted.length > 1 &&
    //       valueCommasSplitted[valueCommasSplitted.length - 1].length < 4
    //     ) {
    //       abstractControl?.setValue(previousInputValue);
    //       return;
    //     }
    //   }
    // }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    if (value === '')
      this.control.control?.setValue(null)
    if (value.includes(','))
      this.control.control?.setValue(new Number(value.replaceAll(',', '.')));
    else if (value) this.control.control?.setValue(+value);
    // console.log(value)
    // if (value[value.length - 1] === ',' || value[value.length - 1] === '.'){
    // }
  }

  // private checkStringWithCommaInsertedByUser(value: string) {
  //   if (value.length === 1) {
  //     return '';
  //   } else if (
  //     // length > 4 ==> user need to input a number without thousand separator
  //     value.length > 4 &&
  //     value[value.length - 1] === ',' &&
  //     !value.slice(0, value.length - 1).includes(',')
  //   ) {
  //     return value.slice(0, value.length - 1);
  //   } else {
  //     const commasSplitted = value.split(',');
  //     const length = commasSplitted.length;
  //     let commaNotValid: boolean = commasSplitted[0].length < 4 ? false : true;
  //     for (let i = 1; i < length; i++) {
  //       if (i !== length - 1 && commasSplitted[i].length != 3) {
  //         commaNotValid = true;
  //         break;
  //       }
  //       if (i === length - 1 && commasSplitted[i].length > 3) {
  //         commaNotValid = true;
  //         break;
  //       }
  //     }
  //     return commaNotValid ? value.slice(0, value.length - 1) : value;
  //   }
  // }
}
