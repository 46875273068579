import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";


import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";

import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { DynamicHeaderComponent } from "./headers/dynamic-header/dynamic-header.component";
import { HeaderComponent } from "./headers/inbox-header/header.component";
import { InsertClaimHeaderComponent } from "./headers/insert-claim-header/insert-claim-header.component";
import { ClaimDetailsHeaderComponent } from "./headers/claim-details-header/claim-details-header.component";
import { UsersSettingsHeaderComponent } from "./headers/users-settings-header/users-settings-header.component";
import { ThousandsDotSeparatorDirective } from "./directives/currency-number.directive";
import { CurrencyFormatterPipe } from "./pipes/currency-formatter.pipe";
import { DateFormatterPipe } from "./pipes/date-formatter.pipe";
import { HistoricLogbookComponent } from "./historic-logbook/historic-logbook.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { TextFieldModule } from "@angular/cdk/text-field";
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatRadioModule,
    MatToolbarModule,
    MatDialogModule,
    MatExpansionModule,
    TextFieldModule,
    MatSelectModule,
    TranslateModule
  ],
  declarations: [
    HeaderComponent,
    UsersSettingsHeaderComponent,
    InsertClaimHeaderComponent,
    ClaimDetailsHeaderComponent,
    DynamicHeaderComponent,
    ConfirmPopupComponent,
    ThousandsDotSeparatorDirective,
    CurrencyFormatterPipe,
    DateFormatterPipe,
    HistoricLogbookComponent
  ],
  exports: [
    DynamicHeaderComponent,
    ConfirmPopupComponent,
    ThousandsDotSeparatorDirective,
    CurrencyFormatterPipe,
    DateFormatterPipe,
    HistoricLogbookComponent
  ],
  providers: [
    CurrencyFormatterPipe,
    DateFormatterPipe
  ]
})

export class SharedComponentsModule {}