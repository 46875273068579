import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotificationComponent } from '../shared/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {
  defaultDuration: number = 5000;
  defaultHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultVerticalPosition: MatSnackBarVerticalPosition = 'bottom';
  defaultPanelClass: string[] = [];

  defaultConfig = {
    duration: this.defaultDuration,
    horizontalPosition: this.defaultHorizontalPosition,
    verticalPosition: this.defaultVerticalPosition,
    panelClass: this.defaultPanelClass,
    data: null
  }

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  created(claimNumber: string) {
    let config = JSON.parse(JSON.stringify(this.defaultConfig))

    config.panelClass.push('success')
    config.data = { claimNumber: claimNumber, }
    
    this._snackBar.openFromComponent(NotificationComponent, config)
  }

  info(msg: string) {
    let config = JSON.parse(JSON.stringify(this.defaultConfig))

    config.data = { message: msg }
    config.panelClass.push('info')
    
    this._snackBar.openFromComponent(NotificationComponent, config)
  }

  error(error: string) {
    let config = JSON.parse(JSON.stringify(this.defaultConfig))

    config.data = { message: error }
    config.panelClass.push('error')
    
    this._snackBar.openFromComponent(NotificationComponent, config)
  }
}
