import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LoaderService } from './services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType, InteractionType, IdTokenClaims, PromptValue } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { HttpService } from './services/common/http.service';
import { Language } from './models/enums/front-end';
import { ClaimNotificationService } from './services/claim-notification.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
   
export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class AppComponent implements OnInit {
  public baseUrl: string = environment.businessLayerUrl;
  showHead: boolean = false;
  title = 'AEA.Frontend';
  isLoading: boolean = false;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private httpService: HttpService,
    private loaderService: LoaderService,
    private router: Router,
    public translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public dateAdapter: DateAdapter<Date>,
    private claimNotificationService:ClaimNotificationService
  ) {
    // // on route change to '/login', set the variable showHead to false
    // this.router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     if (event['url'] == '/') {
    //       this.showHead = false;
    //     } else {
    //       this.showHead = true;
    //     }
    //   }
    // });
    this.matIconRegistry.addSvgIcon(
      `sharePointEnabled`, this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sharePoint-enabled.svg"));
    this.matIconRegistry.addSvgIcon(
      `sharePointDisabled`, this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sharePoint-disabled.svg"));
    this.matIconRegistry.addSvgIcon(
      `sort_by_zeta`, this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sort_by_zeta.svg"));
    this.matIconRegistry.addSvgIcon(
      `sort_by_alpha`, this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/sort_by_alpha.svg"));
    this.matIconRegistry.addSvgIcon(
      `red_light`, this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/red_light.svg"));
    this.matIconRegistry.addSvgIcon(
      `green_light`, this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/green_light.svg"));
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    localStorage.setItem('selectedLanguage', Language.Italian);
    this.dateAdapter.setLocale(localStorage.getItem('selectedLanguage'));
    this.switchLang();
    this.loaderService.httpProgress().subscribe((status: boolean) => this.isLoading = status);
    this.setLoginDisplay();

    this.authService
      .handleRedirectObservable()
      // .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          if (window.location.pathname == '/post-login') {
            this.router.navigateByUrl('/');
          }
          this.claimNotificationService.startConnection();
        },
        error: (err) => { console.error(err); this.router.navigateByUrl('/'); },
      });

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // console.log('In progress');
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // console.log(result.eventType);
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (idtoken.acr === environment.b2cPolicies.names.signUpSignIn || idtoken.tfp === environment.b2cPolicies.names.signUpSignIn) {
          this.authService.instance.setActiveAccount(payload.account);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result.error.message);
        //   if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
        //     let resetPasswordFlowRequest: RedirectRequest | PopupRequest  = {
        //         //authority: environment.b2cPolicies.authorities.resetPassword.authority,
        //         authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
        //         scopes: [],
        //     };

        //     this.login(resetPasswordFlowRequest);
        // };
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  async getUserInfo() {
    const url: string = '/user/getuserbymail';
    var result = await this.httpService.get<string>(this.baseUrl + url).subscribe(x => console.log(x));

    return result;
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  switchLang() {
    this.translate.use(localStorage.getItem('selectedLanguage'));
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
