import { environment } from 'src/environments/environment';

class CompaniesRoutes {
  private readonly blUrl: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/company`;
  public readonly getAll: string = this.blUrl;
}

class InboxRoutes {
  private readonly inboxUrl: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/search`;
  public readonly search: string = this.inboxUrl;
  public readonly advancedSearch: string = this.inboxUrl + '/advanced';
  public readonly getActors: string = ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/actors`;
}

class ClaimsRoutes {
  public readonly getById: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}`;
  public readonly create: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/`;
  public readonly update: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}`;
  public readonly cancel: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/cancel`;
  public readonly updateUrgent: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/updateurgent`;
  public readonly addFavorite: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/addfavorite`;
  public readonly removeFavorite: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/removeFavorite`;
  public readonly getTasks: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/tasks`;
  public readonly getActors: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/actors`;
  public readonly deleteAppointment: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/appointments/{1}`;
  public readonly checkNullValues: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/openingchecknullvalues/{0}`;
  public readonly checkCompletedData: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/openingcheckcompleteddata/{0}`;
}

class HistoryRoutes {
  public readonly getHistory: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/logbook/search`;
  public readonly sendEmail: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/logbook/communication/email/{0}`;
  public readonly sendSms: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/logbook/communication/sms/{0}`;
}

class AccountRoutes {
  public readonly accountUrl: string = `/api/{apiVersion}/profile/account/`
  public readonly getBrokers: string =
    ApiRoutes.profileUrl + this.accountUrl + `brokers`;
  public readonly getAdministrators: string =
    ApiRoutes.profileUrl + this.accountUrl + `buildingadministrators`;
  public readonly getInsuranceCompanies: string =
    ApiRoutes.profileUrl + this.accountUrl + `insurancecompanies`;
  public readonly getCompanies: string =
    ApiRoutes.profileUrl + this.accountUrl + `companies`;
  public readonly getClds: string =
    ApiRoutes.profileUrl + this.accountUrl + `clds`;
  public readonly getRepairers: string =
    ApiRoutes.profileUrl + this.accountUrl + `repairers`;
  public readonly getAgencies: string = this.accountUrl + 'agencies/ofinsurancecompany/{0}';
}

class DamageTypeRoutes {
  private readonly blUrl: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/damageType`;
  public readonly getAll: string = this.blUrl;
}

class InsuranceCompanyRoutes {
  private readonly blUrl: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/insuranceCompany`;
  public readonly getAll: string = this.blUrl;
}

class InstructionTypeRoutes {
  private readonly blUrl: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/instructiontype`;
  public readonly getAll: string = this.blUrl;
}

class agencyRoutes {
  private readonly agenciesUrl: string =
    ApiRoutes.profileUrl + `/api/{apiVersion}/profile/account/agencies/`;
  public readonly getAll: string = this.agenciesUrl + 'ofinsurancecompany/{0}';
}

class portalsRoutes {
  private readonly portalsUrl: string =
    ApiRoutes.profileUrl + `/api/{apiVersion}/profile/account/insurancecompanyportals/`;
  public readonly getByInsuranceCompany: string = this.portalsUrl + 'ofinsurancecompany/{0}';
}

class goodsRoutes {
  private readonly blUrl: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/goodfamilies`;
  public readonly getAll: string = this.blUrl;
}

class citiesRoutes {
  private readonly cityUrl: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/city/`;
  public readonly getAll: string = this.cityUrl + 'cities';
}

class cgaRoutes {
  private readonly blUrl: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/cga/`;
  public readonly getByInsuranceCompany: string = this.blUrl + 'GetByCrmAccountId/{0}';
  public readonly getById: string = this.blUrl + 'getbyid/{0}';
  public readonly getByName: string = this.blUrl + 'getbyname/{0}';
  public readonly create: string = this.blUrl + 'create';
  public readonly getAllCategory: string = this.blUrl + 'getallcategory';
}

class UserRoutes {
  private readonly blUrl: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/`;
  public readonly taskReassignment: string = this.blUrl + 'tasktouserassignment/taskreassignment/{0}';
  public readonly tasksReassignment: string = this.blUrl + 'tasktouserassignment/tasksReassignment';
  public readonly getUser: string = this.blUrl + 'usersettings/{0}';
}

class TasksRoutes {
  public readonly getById: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/task/{0}`;
  public readonly sellRepair: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/sellrepair`
  public readonly completeClaimOpeningExit: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/completeClaimOpeningExit`;
  public readonly completeClaimCreation: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/completeClaimCreation`;
  public readonly manualChoice: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/lastServiceType`;
  public readonly assignInspection: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/assignInspection`;
  public readonly assignInspectionPCE: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/assignInspectionPCE`;
  public readonly getAssignableUsers: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/tasktouserassignment/getenrichedpotentialtaskreassignees/{0}`;
  public readonly getAllUsersForReassignment: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/tasktouserassignment/getallusersforreassignment`;
  public readonly getNonSeqTasks: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/userQualificationsNonSeq/{0}`;
  public readonly getVisioUserAvailability: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/visio/useravailability`;
  public readonly getDeskUserAvailability: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/desk/useravailability`;
  public readonly getVisioActorAppointmentAvailability: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/visio/actorswhocanpartecipate`;
  public readonly getDeskActorAppointmentAvailability: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/desk/actorswhocanpartecipate`;
  public readonly getInspectionUserAvailability: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/tasktocatassignment/getpotentialtaskreassigneesmanual`;
  public readonly getAllInspectionUsers: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/tasktocatassignment/getallcats`;
  public readonly completeStartVisio: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/visio/start/complete`;
  public readonly completeStartDesk: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/desk/start/complete`;
  public readonly completeInspection: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/inspection/start/complete`;
  public readonly sendVisioAppointment: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/visio/createAppointment`;
  public readonly sendDeskAppointment: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/desk/createAppointment`;
  public readonly sendInspectionAppointment: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/services/inspection/createAppointment`;
  public readonly getAllAppointments: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/claim/{0}/getallappointments`;
}

class NonSeqTastsRoutes {
  public readonly sendSms: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/messages/sendCommunication/{0}`;
  public readonly sendEmail: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/email/sendCommunication/{0}`
  public readonly getCompanyInsSettings: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/company/getCompanyInsSettings`
  public readonly getDocumentList: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/document/listForDocumentsRequest/getall`
  public readonly sendDocumentsRequest: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/email/sendCommunicationRequestDocuments/{0}`
  public readonly getAllSuspendMotivations: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/task/getSuspendMotivations`;
  public readonly suspendAssignment: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/task/suspendAssignment`;
}

class UsersSettingsRoutes {
  public readonly getUsers: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/usersettings/getAllUserSetting`;
  public readonly createUser: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/userSettings`;
  public readonly updateUser: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/userSettings/{0}`;
  public readonly deleteUser: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/userSettings/delete`;
}

class DmnEngineRoutes {
  public readonly getDmnFiles: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine`;
  public readonly openDmnFileWithoutReviewing: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/{0}`;
  public readonly openDmnFileInReviewWithoutEdit: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/dmns/{0}/reviews`;
  public readonly openDmnFileReview: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/dmns/{0}/reviews/start`;
  public readonly closeDmnFileReview: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/dmns/{0}/reviews/close`;
  public readonly updateDmnFileRow: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/dmns/{0}/reviews/rows`;
  public readonly lockDmnFileRow: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/dmns/{0}/reviews/rows/lock`;
  public readonly unlockeDmnFileRow: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/dmns/{0}/reviews/rows/unlock`;
  public readonly downloadDmnFile: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/{0}/download`;
  public readonly downloadDmnInReviewFile: string =
    ApiRoutes.blUrl + `/api/{apiVersion}/dmnengine/dmns/{0}/reviews/download`;
}

export class ApiRoutes {
  public static readonly blUrl: string = environment.businessLayerUrl;
  public static readonly profileUrl: string = environment.profileUrl;
  public static readonly defaultVersion: string = environment.defaultApiVersion;
  public static readonly claimsRoutes: ClaimsRoutes = new ClaimsRoutes();
  public static readonly inboxRoutes: InboxRoutes = new InboxRoutes();
  public static readonly accountRoutes: AccountRoutes = new AccountRoutes();
  public static readonly tasksRoutes: TasksRoutes = new TasksRoutes();
  public static readonly nonSeqTasksRoutes: NonSeqTastsRoutes = new NonSeqTastsRoutes();
  public static readonly cgaRoutes: cgaRoutes = new cgaRoutes();
  public static readonly companyRoutes: CompaniesRoutes = new CompaniesRoutes();
  public static readonly damageTypeRoutes: DamageTypeRoutes = new DamageTypeRoutes();
  public static readonly insuranceCompanyRoutes: InsuranceCompanyRoutes = new InsuranceCompanyRoutes();
  public static readonly instructionTypeRoutes: InstructionTypeRoutes = new InstructionTypeRoutes();
  public static readonly agencyRoutes: agencyRoutes = new agencyRoutes();
  public static readonly portalsRoutes: portalsRoutes = new portalsRoutes();
  public static readonly goods: goodsRoutes = new goodsRoutes();
  public static readonly cities: citiesRoutes = new citiesRoutes();
  public static readonly userRoutes: UserRoutes = new UserRoutes();
  public static readonly userSettingsRoutes: UsersSettingsRoutes = new UsersSettingsRoutes();
  public static readonly dmnEngineRoutes: DmnEngineRoutes = new DmnEngineRoutes();
  public static readonly historyRoutes: HistoryRoutes = new HistoryRoutes();
}
