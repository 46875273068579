import { ActorType, TaskTypeId } from "./enums/data-base"
import { ClaimAnchors } from "./enums/front-end"

export const CESI = [
    { id: 1, value: "Da rilevare" },
    { id: 2, value: "Positivo con fulmine entro 1.5 Km" },
    { id: 3, value: "Presenza fulmini oltre 1.5 Km" },
    { id: 4, value: "Assenza di fulmini" },
    { id: 5, value: "Non rilevabile" },
    { id: 6, value: "Non necessario" },
    { id: 7, value: "CESI non funzionante" }
]

export const AccountTypes = [ActorType.Agenzia, ActorType.Broker, ActorType.AmministratoreCondomino, ActorType.CLD, ActorType.AziendaRiparazione]
export const ContactTypes = [ActorType.ReferenteAgenzia, ActorType.ReferenteBroker, ActorType.ReferenteAmministratoreDiCondomino, ActorType.Liquidatore, ActorType.ReferenteRiparatore]

export const ContractorTypes = [
    { id: 1, value: "Privato" },
    { id: 2, value: "Condominio" },
    { id: 3, value: "Azienda" },
    { id: 4, value: "Ente Pubblico" },
    { id: 5, value: "Uffici" },
    { id: 6, value: "Negozio" },
    { id: 7, value: "Ente Religioso" },
    { id: 8, value: "Fondo Immobiliare" },
    { id: 9, value: "Azienda Ospedaliera" },
    { id: 10, value: "Comune" }
]

export const ServiceTypes = [
    { id: 4, value: "Ripristino e riparazione " },
    { id: 6, value: "Desk" },
    { id: 7, value: "Visio" },
    { id: 10, value: "Sopralluogo" },
    { id: 11, value: "Sopralluogo con PCE" },
    { id: 12, value: "Invio link Selfcare" },
]

export const LastServiceTypes = [
    { id: 'PeriziaDocumentale', value: "DESK" },
    { id: 'PeriziaTecnica', value: "CAT" },
    { id: 'RipristinoERiparazione', value: "RIP" },
    { id: 'Visio', value: "VISIO" },
    { id: 'SelfCare', value: "SELF" },
    { id: 'PeriziaTelefonica', value: "PTEL" },
    { id: 'Perizia', value: "PER" },
]

export const InstructionTypes = [
    { id: 1, value: "Perizia" },
    { id: 2, value: "Perizia Tecnica" },
    { id: 3, value: "Perizia Telefonica" },
    { id: 4, value: "Ripristino e Riparazione" },
    { id: 5, value: "Consulenza" },
    { id: 6, value: "Perizia Documentale" },
    { id: 7, value: "Visio" },
    { id: 8, value: "Gestione da Portale" },
    { id: 9, value: "Express" },
    { id: 10, value: "Sopralluogo" },
    { id: 11, value: "Sopralluogo con PCE" },
    { id: 12, value: "Invio link Selfcare" },
    { id: 13, value: "Perizia con INS" },
    { id: 14, value: "REP diretta" },
    { id: 15, value: "Perizia di Riscontro" },
    { id: 16, value: "Terza Perizia" },
    { id: 17, value: "Controllo qualità perizia" },
    { id: 18, value: "Riapertura Sinistro" },
    { id: 19, value: "Altro" },
]

export const CoverageTypes = [
    { id: 1, value: "Acqua condotta" },
    { id: 2, value: "All Risks" },
    { id: 3, value: "Altri Danni Domestici" },
    { id: 4, value: "Atti Vandalici" },
    { id: 5, value: "Danni Elettrici" },
    { id: 6, value: "Danni Indiretti" },
    { id: 7, value: "Demolizione e Sgombero" },
    { id: 8, value: "Elettronica" },
    { id: 9, value: "Esplosione e Scoppio" },
    { id: 10, value: "Eventi Atmosferici" },
    { id: 11, value: "Fenomeno Elettrico" },
    { id: 12, value: "Fuoriuscita di Acqua per Rottura Apparecchiature Domestiche" },
    { id: 13, value: "Gelo" },
    { id: 14, value: "Grandine" },
    { id: 15, value: "Guasti causati da Ladri" },
    { id: 16, value: "Guasto Macchina" },
    { id: 17, value: "Incendio" },
    { id: 18, value: "Inondazione/Alluvione" },
    { id: 19, value: "Maggiori Costi" },
    { id: 20, value: "Mancato Freddo" },
    { id: 21, value: "Merci in Refrigerazione" },
    { id: 22, value: "Occlusione di Condutture" },
    { id: 23, value: "Pannelli Solari" },
    { id: 24, value: "Responsabilit? Civile verso Terzi" },
    { id: 25, value: "Ricerca e Riparazione per danni da Acqua Condotta" },
    { id: 26, value: "Rischio Locativo" },
    { id: 27, value: "Rottura Lastre" },
    { id: 28, value: "Sovraccarico di Neve" },
    { id: 29, value: "Traboccamento Acqua da Rigurgito Fognature" },
    { id: 30, value: "Vento" },
    { id: 31, value: "Supporto Dati" },
    { id: 32, value: "Furto" },
    { id: 33, value: "Eventi Naturali" },
    { id: 34, value: "Altri danni ai Beni" }
]

export const OptSetForm = [
    { id: 1, value: "Valore Intero" },
    { id: 2, value: "Primo Rischio Assoluto" },
    { id: 3, value: "Primo Rischio Relativo" },
    { id: 4, value: "P.R.A. fino a €1000" },
    { id: 5, value: "dipende da quanto espresso in polizza" }
]

export const OptSetIndCriterias = [
    { id: 0, value: "dipende da quanto espresso in polizza" },
    { id: 1, value: "Valore a Nuovo (VN)" },
    { id: 2, value: "Valore a Stato d'Uso (VSU)" },
    { id: 3, value: "VN con limite 2xVSU" },
    { id: 4, value: "VN con limite 3xVSU" },
    { id: 5, value: "VN con SI in proporzione a somma assicurata (VSU+SI%)" },
    { id: 6, value: "VN con SI (VSU+SI)" },
    { id: 7, value: "VN con SI con limite 2xVSU" }
]

export const NonSeqTasks = [
    { id: 1, value: "Sospendi incarico", default: "CancelAssignment", status: false },
    { id: 2, value: "Annulla sospensione", default: "CancelSuspension", status: false },
    { id: 3, value: "Gestione contestazione", default: "DisputeManagement", status: false },
    { id: 4, value: "Pratica ibernata", default: "PracticeHibernated", status: false },
    { id: 5, value: "Gestione fatturazione", default: "BillingManagement", status: false },
    { id: 6, value: "Perizia negativa", default: "NegativeExpertise", status: false },
    { id: 7, value: "Annulla/Cancella Incarico", default: "CancelAssignment", status: false },
    { id: 8, value: "Ri-Assegnazione Task", default: "ReAssignmentTask", status: false },
    { id: 9, value: "Invio atto", default: "SubmitAct", status: false },
    { id: 10, value: "Invio comunicazioni", default: "SendCommunications", status: false },
    { id: 11, value: "Scrivi interlocutoria", default: "WriteInterlocutory", status: false },
    { id: 12, value: "Note portale di compagnia", default: "NotesPortalCompany", status: false },
    { id: 13, value: "Collega incarico", default: "LinkAssignment", status: false },
    { id: 14, value: "Richiesta documenti", default: "RequestDocuments", status: false },
    { id: 15, value: "Nuovi documenti ricevuti", default: "NewDocumentsReceived", status: false },
    { id: 16, value: "Sollecito", default: "Solicitation", status: false }
]

export const ExecuteVisioActions = [
    { id: 1, value: "Rifiuta incarico"},
    { id: 2, value: 'Modifica appuntamento'},
    { id: 3, value: 'Annulla appuntamento '},
    { id: 4, value: 'Completa e avvia nuova visio'},
    { id: 5, value: 'Completa e termina processo'}
]

export const SideNavAnchors = [
    { id: ClaimAnchors.AssignmentData, value: "Dati dell'incarico", selected: true, visible: true },
    { id: ClaimAnchors.ClaimData, value: 'Dati sinistro', selected: false, visible: true },
    { id: ClaimAnchors.Notes, value: 'Note e indicazioni', selected: false, visible: true },
    // { id: ClaimAnchors.Accounts, value: 'Account', selected: false, visible: true },
    { id: ClaimAnchors.Actors, value: 'Attori', selected: false, visible: true },
    { id: ClaimAnchors.Policy, value: 'Polizza', selected: false, visible: true },
    { id: ClaimAnchors.Appointments, value: 'Appuntamenti', selected: false, visible: false },
    // { id: ClaimAnchors.Coverages, value: 'Garanzie', selected: false },
    // { id: ClaimAnchors.InsuredItems, value: 'Partite assicurate', selected: false },
    { id: ClaimAnchors.Reserves, value: 'Storico riserve', selected: false, visible: true },
]

export const MissingCrmActors = {
  agency: {value: false, id: 'c647d3d2-e733-eb11-a813-000d3ab54fa7'},
  cld: {value: false, id: 'b54c1920-93e0-ee11-904d-000d3a666d74'},
  liquidator: {value: false, id: '416fac98-93e0-ee11-904d-000d3a666d74'}
}

export const crmInfo = {
    true_short: 'CRM',
    true_long: 'CLAIM_DETAILS.ACTORS_MANAGEMENT.PRESENT_CRM',
    false_short: '!CRM',
    false_long: 'CLAIM_DETAILS.ACTORS_MANAGEMENT.MISSING_CRM'
}

export const MockedAssignee = ['utente.mancante@mail.com', 'utente.mock@mail.com']

export const validTaskTypeIds = [
  TaskTypeId.CompleteClaimCreation,
  TaskTypeId.CompleteClaimforOpeningExit,
  TaskTypeId.SellingRepair,
  TaskTypeId.ManualServiceChoice,
//   TaskTypeId.ExecuteVisio,
  TaskTypeId.ManualStartVisio,
  TaskTypeId.ManualStartDocumental,
  TaskTypeId.Inspection,
  TaskTypeId.ManualAssignInspector,
  TaskTypeId.ManualAssignInspectorPCE
]

export const addOnlyTaskTypeIds = [
  //   TaskTypeId.ExecuteVisio,
    TaskTypeId.ManualStartVisio,
    TaskTypeId.ManualStartDocumental,
    TaskTypeId.Inspection,
    TaskTypeId.ManualAssignInspector,
    TaskTypeId.ManualAssignInspectorPCE
]

export const validTaskTypeIdsNotYetDeveloped = [
  TaskTypeId.Repair,
  TaskTypeId.ExecuteDocumental,
  TaskTypeId.ExecuteVisio,
  TaskTypeId.ExecuteExpertise,
  TaskTypeId.SellingRepairPA1,
  TaskTypeId.SelfCareManagement,
]

