import { ApiVersion } from "src/app/services/common/api-version";

export const environment = {
  production: false,
  businessLayerUrl: 'https://app-sc-bl-prod.azurewebsites.net',
  // middlewareUrl: 'https://app-sc-aggregator-prod.azurewebsites.net/',
  profileUrl: 'https://app-sc-profile-prod.azurewebsites.net/',
  meteorageUrl: 'http://10.8.3.42:8090/',
  msalClientId: 'ec0b2ca2-e7ac-4e0a-878e-851cfea882ce',
  msalTenantId: 'bf983ca5-7c4b-4f83-85cf-f60aeec1b57b',
  defaultApiVersion: ApiVersion.v1,

  msalConfig: {
    auth: {
        clientId: 'ec0b2ca2-e7ac-4e0a-878e-851cfea882ce',
    }
  },
  apiConfig: {
    scopes_businesslayer: ['https://aeaitaliaapps.onmicrosoft.com/sc_businesslayer/access_as_user', 'openid','offline_access'],
    uri_businesslayer: 'https://app-sc-bl-prod.azurewebsites.net',

    scopes_profile: ['https://aeaitaliaapps.onmicrosoft.com/sc_profile/access_as_user', 'openid','offline_access'],
    uri_profile: 'https://app-sc-profile-prod.azurewebsites.net',

    // scopes_aggregator: ['https://aeaitaliaapps.onmicrosoft.com/sc_aggregator/access_as_user', 'openid','offline_access'],
    // uri_aggregator: 'https://app-sc-aggregator-prod.azurewebsites.net'
  },
  b2cPolicies: {
    names: {
        signUpSignIn: "b2c_1_SignupSignin",
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://aeaitaliaapps.b2clogin.com/aeaitaliaapps.onmicrosoft.com/b2c_1_SignupSignin'
        }
    },
    authorityDomain: "aeaitaliaapps.b2clogin.com"
  }
};