import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'users-settings-header',
  templateUrl: './users-settings-header.component.html',
  styleUrls: ['./users-settings-header.component.scss'],
})
export class UsersSettingsHeaderComponent {
  pageTitle: string;
  loadInbox: boolean = false;
  isAutenticated: boolean = false;
  activeUser: string | undefined = '';
  constructor(
    private msalService: MsalService,
    private msalBroadCastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    public translate: TranslateService,
    private userService: UserService
  ) {
    translate.addLangs(['it-IT', 'en-GB']);

    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) this.translate.use(selectedLanguage);
    // set default language if nothing is stored in localStorage
    else this.translate.use('it-IT');
  }

  ngOnInit(): void {
    this.msalBroadCastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) =>
            status === InteractionStatus.None ||
            status === InteractionStatus.Startup
        )
      )
      .subscribe(() => {
        this.setAuthenticationStatus();
      });
    const url = this.router.url.split('/').pop();
    this.pageTitle = url.includes('dmn') ? 'Dmn Manager' : 'User settings';
  }

  goToInbox() {
    this.router.navigateByUrl('inbox/' + window.localStorage.getItem('filter'))
  }

  logout() {
    this.msalService.logout();
  }

  setAuthenticationStatus(): void {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      activeAccount = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }
    this.isAutenticated = !!activeAccount;
    this.activeUser = activeAccount?.name;
    this.loadInbox = this.isAutenticated;

    if (this.isAutenticated) {
      this.userService.setUser(this.activeUser);
    }
  }
}
