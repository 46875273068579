<mat-toolbar class="horizontal-menu">
    <span class="link" (click)="goToInbox()">
        <mat-icon iconPositionEnd>arrow_back_ios</mat-icon>
        <p>torna alla inbox</p>
    </span>
    <button mat-flat-button class="btn-round btn-gray" *ngIf="false">Cerca
        <mat-icon iconPositionEnd>search</mat-icon>
    </button>

    <button mat-button class="btn-round btn-white" routerLink="/claims" routerLinkActive="active">
        {{ "MAIN_HEADER_MENU.NEW_PRACTICE" | translate }}
        <mat-icon iconPositionEnd>add_circle</mat-icon>
    </button>

    <p class="tab-separator">|</p>
    <button mat-button class="btn-round btn-yellow" [disabled]="urls.siteReady != sharePointStatus.Created"
        (click)="goToSharePoint()">sharepoint
        <mat-icon iconPositionEnd>open_in_new</mat-icon>
    </button>
    <!-- <button mat-button class="btn-round btn-yellow" [disabled]="!urls.easyPerizia"
        (click)="goToEasyPerizia()">easyperizia
        <mat-icon iconPositionEnd>open_in_new</mat-icon>
    </button> -->
    <button mat-button class="btn-round btn-yellow"
        (click)="goToMeteorage()">meteorage
        <mat-icon iconPositionEnd>open_in_new</mat-icon>
    </button>
    <button mat-button class="btn-round btn-gray" *ngIf="false">
        <p class="more-icon">...</p>
    </button>
</mat-toolbar>