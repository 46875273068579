import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ClaimDetails } from 'src/app/models/claim-details';
import { InsurancePractice } from 'src/app/models/common-classes';

import { ClaimsSharedService } from 'src/app/services/claims-shared.service';

@Component({
  selector: 'inbox-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent {
  @Output() close$: EventEmitter<null> = new EventEmitter<null>();
  @Output() searchRequest$: EventEmitter<any> = new EventEmitter<any>();
  component: ClaimDetails | InsurancePractice | string | null = null;
  isHistory: boolean = false;
  claim: ClaimDetails | InsurancePractice | null = null;

  constructor(
    private _claimSharedService: ClaimsSharedService,
  ) {
    this._claimSharedService.drawerComponent.subscribe(component => {
      if (component) {
        if (Object.keys(component).includes('claimReferenceNumber')) {
          this.isHistory = true;
        }
        else
          this.isHistory = false
      }
    })
  }

  closeHistoryDrawer() {
    this.close$.emit();
  }

  getInboxClaims(request) {
    this.searchRequest$.next(request)
  }
}
