

export enum FieldsToTranslate{
    Task = "Task",
    DamageType = "DamageType",
    InstructionType = "InstructionType",
    GoodFamilies = "GoodFamilies",
    ClaimDetailsAnchors = "ClaimDetailsAnchors",
    Cesi = "CESI",
    ContractorTypes = "ContractorTypes",
    CoverageTypes = "ConverageTypes",
    FormTypes = "OptSetForm",
    ChooseServices = "ChooseServices",
    ExecuteVisioActions = "ExecuteVisioActions",
    Processes = "Processes",
    NonSeqTasks = "NonSeqTasks",
    ActorTypeIds = "ActorType",
}