import { Injectable } from '@angular/core';
import { ApiVersion } from './api-version';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlBuilderService {
  private url!: string | null;
  private routedValues!: string[];
  private apiVersion!: ApiVersion;

  constructor() {
    this.initialize();
  }

  public withUrl(url: string) {
    this.url = url;
    return this;
  }

  public withVersion(apiVersion: ApiVersion): UrlBuilderService {
    this.apiVersion = apiVersion;
    return this;
  }

  public withRoutedValues(...routedValues: string[]): UrlBuilderService {
    this.routedValues = routedValues;
    return this;
  }

  public build(): string {
    if (!this.url) throw "withUrl call required!";

    let versionedUrl = this.url.replace("{apiVersion}", this.apiVersion);
    let result = this.formatUrl(versionedUrl, this.routedValues);
    this.initialize();
    return result;
  }

  private initialize(): void {
    this.url = null;
    this.routedValues = [];
    this.apiVersion = environment.defaultApiVersion;
  }

  private formatUrl(formatted: string, routedValues: string[]) {
    for (let i = 0; i < routedValues.length; i++) {
      let regexp = new RegExp('\\{' + i + '\\}', 'gi');
      formatted = formatted.replace(regexp, routedValues[i]);
    }
    return formatted;
  };
}