import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ClaimService } from 'src/app/services/claim.service';
import { SharedEntitiesService } from 'src/app/services/common/shared-entities.service';
import { TasksService } from 'src/app/services/tasks.service';

import { DamageType } from 'src/app/models/common-classes';
import { AdvancedSearchForm } from 'src/app/models/forms/forms';
import { CrmAccount } from 'src/app/models/post';
import { TaskUser } from 'src/app/models/user';
import { SharedEntitiesKeys } from 'src/app/models/enums/front-end';

@Component({
  selector: 'advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
  @Output() close$: EventEmitter<null> = new EventEmitter<null>();
  @Output() searchRequest$: EventEmitter<any> = new EventEmitter<any>();
  companies: CrmAccount[] = []
  damageTypes: DamageType[] = []
  users: TaskUser[] | null = null;
  form = new FormGroup(new AdvancedSearchForm())

  constructor(
    private tasksService: TasksService,
    private claimService: ClaimService,
    private _sharedEntitiesService: SharedEntitiesService,
  ) {
    this.loadUsers();
  }

  ngOnInit(): void {
    Promise.all([
      this._sharedEntitiesService.getSharedEntity(SharedEntitiesKeys.Companies),
      this._sharedEntitiesService.getSharedEntity(SharedEntitiesKeys.DamageTypes),
    ]).then(results => {
      this.companies = results[0];
      this.damageTypes = results[1];
    }).catch(err => {
      this.companies = [];
      this.damageTypes = [];
    });
  }

  closeHistoryDrawer() {
    this.close$.emit();
  }

  loadUsers() {
    this.tasksService.getAllUsersForReassignment().subscribe({
      next: (res) => {
        this.users = res;
      },
      error: (err) => { console.error(err) }
    })
  }

  save() {
    this.searchRequest$.next(this.form.getRawValue())
  }

}
