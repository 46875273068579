export enum ActorType {
    Assicurato = 1,
    Contraente = 2,
    Perito = 3,
    Danneggiato = 4,
    ReferenteAmministratoreDiCondomino = 5,
    ReferenteBroker = 6,
    ReferenteAgenzia = 7,
    PeritoCoAss = 1905,
    AziendaRiparazione = 1906,
    ContattoVisio = 1907,
    AmministratoreCondomino = 1908,
    ReferenteSinistro = 1909,
    Broker = 1910,
    Agenzia = 1911,
    AttoreSinistro = 1912,
    ReferenteRiparatore = 1915,
    Legale = 1920,
    Liquidatore = 1925,
    CLD = 1930,
}

export enum TaskTypeId {
    AutomaticCreationClaim = 1,
    CompleteClaimCreation = 2,
    ManualCreationClaim = 3,
    AutomaticChoiseService = 4,
    ManualServiceChoice = 5,
    SellingRepair = 6,
    SelfCareManagement = 7,
    Inspection = 8,
    Repair = 9,
    ExecuteDocumental = 10,
    ExecuteVisio = 11,
    ExecuteExpertise = 12,
    ExpertiseCollaboration = 13,
    NotifyOutcome = 14,
    ExpertiseReview = 15,
    CheckAuthority = 16,
    ExpertiseMediation = 17,
    Waitingforreciptdeed = 18,
    CheckClosureClaim = 19,
    Closure = 20,
    ReopeningMenagement = 21,
    ReopeningFinalize = 22,
    ExecuteDocumentalpostDocumental = 23,
    AutomaticClosure = 24,
    SellingRepairPA1 = 25,
    CompleteClaimforOpeningExit = 26,
    ExpertiseWithIns = 27,
    ExpertiseCorroboration = 28,
    ThirdExpertise = 29,
    CheckQualityExpertise = 30,
    LossReopening = 31,
    FinalizeMediation = 32,
    Other = 33,
    ManualStartVisio = 34,
    ManualStartDocumental = 35,
    MakeAppointment = 36,
    MakeAppointmentPCE = 37,
    SuspendedClaim = 41,
    ManualAssignInspector = 44,
    ManualAssignInspectorPCE = 45,
    CancelledClaim = 47,
    
}

export enum TaskTypeName {
    AutomaticCreationClaim = 'Automatic Creation Claim',
    CompleteClaimCreation = 'Complete Claim Creation',
    ManualCreationClaim = 'Manual Creation Claim',
    AutomaticChoiseService = 'Automatic Choise Service',
    ManualServiceChoice = 'Manual Service Choise',
    SellingRepair = 'Selling Repair',
    SelfCareManagement = 'Self Care Management',
    Inspection = 'Inspection',
    Repair = 'Repair',
    ExecuteDocumental = 'Execute Documental',
    ExecuteVisio = 'Execute Visio',
    ExecuteExpertise = 'Execute Expertise',
    ExpertiseCollaboration = 'Expertise Collaboration',
    NotifyOutcome = 'Notify Outcome',
    ExpertiseReview = 'Expertise Review',
    CheckAuthority = 'Check Authority',
    ExpertiseMediation = 'Expertise Mediation',
    Waitingforreciptdeed = 'Waiting for recipt deed',
    CheckClosureClaim = 'Check Closure Claim',
    Closure = 'Closure',
    ReopeningMenagement = 'Reopening Menagement',
    ReopeningFinalize = 'Reopening Finalize',
    ExecuteDocumentalpostDocumental = "Execute Documental post Documental",
    AutomaticClosure = "Automatic Closure",
    SellingRepairPA1 = "Selling Repair PA1",
    CompleteClaimforOpeningExit = "Complete Claim for Opening Exit"
}

export enum DocumentStatus {
    NotRequested = 0,
    Requested = 1,
    Uploaded = 2,
    Completed = 3,
    Error = 4
}

export enum SiteCreationStatus {
    NotRequested = 0,
    Requested = 1,
    Created = "Created",
    Error = 3
}

