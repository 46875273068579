<div class="notification-container" [classList]="config.panelClass">

    <div class="notification-body">
        <span class="notification-message" matSnackBarLabel *ngIf="data?.claimNumber">
            {{ "MESSAGES.CLAIM" | translate }} 
            <strong>{{ data.claimNumber }}</strong>
            {{ "MESSAGES.CREATE_SUCCESS" | translate }}
        </span>
        <span class="notification-message" matSnackBarLabel *ngIf="data?.message">
            {{ data.message }}
        </span>
        <span matSnackBarActions>
            <button mat-icon-button [cdkCopyToClipboard]="data.claimNumber" *ngIf="data.claimNumber"><mat-icon class="icon-white">content_copy</mat-icon></button>
            <button mat-icon-button matSnackBarAction (click)="snackBarRef.dismissWithAction()"><mat-icon class="icon-white">close</mat-icon></button>
        </span>
    </div>

	<mat-progress-bar mode="determinate" [value]="progress" [color]="'success'"></mat-progress-bar>
</div>