import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) { }

  public get<TResult>(url: string, params?): Observable<TResult> {
    return this.http.get<TResult>(url, { params: params });
  }

  public post<TBody, TResult>(url: string, body?: TBody): Observable<TResult> {
    return this.http.post<TResult>(url, JSON.stringify(body), {
      headers: { 'content-type': 'application/json' }
    });
  }

  public put<TBody, TResult>(url: string, body: TBody): Observable<TResult> {
    return this.http.put<TResult>(url, JSON.stringify(body), {
      headers: { 'content-type': 'application/json' }
    });
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(url);
  }
}