import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map, mergeMap } from 'rxjs';
import { AvailableSlot, City, DamageType, GoodFamily, HistoryLog, InstructionType, Lists, PageClaims, Task } from '../models/common-classes';
import { PostClaimRequest } from './requests/post-claim-request';
import { PutClaimRequest } from './requests/put-claim-request';
import { ApiRoutes } from './api-routes';
import { HttpService } from './common/http.service';
import { UrlBuilderService } from './common/url-builder.service';
import { TranslationsService } from './common/translations.service';
import { CGA, CrmAccount, VisioAppointment } from '../models/post';
import { ClaimDetails } from '../models/claim-details';
import { ActorType } from '../models/enums/data-base';
import { TasksService } from './tasks.service';
import moment from 'moment';
import { HistoryRequest } from './requests/search-request';

@Injectable({ providedIn: 'root' })
export class ClaimService {
  claimDetails = new BehaviorSubject<ClaimDetails>(null);
  lists = new BehaviorSubject<Lists>(null);
  agencies = new BehaviorSubject<CrmAccount[]>(null);
  cgas = new BehaviorSubject<CGA[] | null>(null);
  portals = new BehaviorSubject<CGA[] | null>(null);
  brokers = new BehaviorSubject<CrmAccount[] | null>(null)
  administrators = new BehaviorSubject<CrmAccount[] | null>(null)

  constructor(
    private urlBuilderService: UrlBuilderService,
    private httpService: HttpService,
    private translationsService: TranslationsService,
    private tasksService: TasksService
  ) { }

  get(claimId: number) {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.getById)
      .withRoutedValues(claimId?.toString())
      .build();
    this.httpService.get(url).subscribe({
      next: (res) => {
        let claim = new ClaimDetails(res, this.translationsService)
        this.claimDetails.next(claim)
        if (claim.insuranceCompanyCrmId) {
          this.getCGAs(claim.insuranceCompanyCrmId)
          this.getPortals(claim.insuranceCompanyCrmId)
          this.getAgencies(claim.insuranceCompanyCrmId)
        }
        this.tasksService.getAllAppointments(claimId)
      },
      error: (err) => { console.error(err) }
    })
  }

  getTasks(claimId: number): Observable<any | null> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.getTasks)
      .withRoutedValues(claimId?.toString())
      .build();
    return this.httpService.get(url).pipe(map((data) => this.tasksDto(data)));
  }

  tasksDto(tasks: any): Array<Task> {
    let taskLst: Array<Task> = [];
    tasks.forEach(el => taskLst.push(new Task(el, this.translationsService)))
    return taskLst;
  }

  getActors(claimId: number): Observable<any | null> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.getActors)
      .withRoutedValues(claimId?.toString())
      .build();
    return this.httpService.get(url);
  }

  async getLists() {
    let list = new Lists()
    await this.getCompanies().then(res => list.companies = res, err => list.companies = [])
    await this.getDamageTypes().then(res => list.damageTypes = res, err => list.damageTypes = [])
    await this.getInstructionTypes().then(res => list.instructionTypes = res, err => list.instructionTypes = [])
    await this.getInsuranceCompanies().then(res => list.insuranceCompanies = res, err => list.insuranceCompanies = [])
    await this.getGoodFamilies().then(res => list.goods = res, err => list.goods = [])
    await this.getCities().then(res => { list.cities = res; localStorage.setItem('cities', JSON.stringify(res)) }, err => list.cities = [])
    await this.lists.next(list);
  }

  create(request: PostClaimRequest): Observable<object> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.create)
      .build();
    request = this.setEmptyStringsToNull(request)
    return this.httpService.post(url, request);
  }

  update(claimId: number, request: PutClaimRequest) {
    request = this.setEmptyStringsToNull(request)
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.update)
      .withRoutedValues(claimId.toString())
      .build();
    this.httpService.put(url, request).subscribe(res => {
      this.get(claimId)
    });
  }

  updateUrgent(claimId: number, urgent): Observable<PageClaims> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.updateUrgent)
      .withRoutedValues(claimId?.toString())
      .build();
    return this.httpService.put(url, urgent);
  }

  updateFavorite(claimId: number, favorite): Observable<PageClaims> {
    let url;
    if (favorite) {
      url = this.urlBuilderService
        .withUrl(ApiRoutes.claimsRoutes.removeFavorite)
        .withRoutedValues(claimId?.toString())
        .build();
      return this.httpService.delete(url);
    }
    else {
      url = this.urlBuilderService
        .withUrl(ApiRoutes.claimsRoutes.addFavorite)
        .withRoutedValues(claimId?.toString())
        .build();
      return this.httpService.post(url);
    }
  }

  mapDocumentCommunicationRequest(request) {
    if ((request.cc && request.ccn) || (request.cc == '' && request.ccn) || (request.cc && request.ccn == '') || (request.cc == '' && request.ccn == '')) {
      let arr;
      if (request.cc == "" || !request.cc) request.cc = []
      else if (typeof request.cc == 'string') {
        arr = request.cc.split(/[ ,]+/)
        request.cc = arr.filter((el: any, i) => arr.indexOf(el) === i)
      }
      if (request.ccn == "" || !request.ccn) request.ccn = []
      else if (typeof request.ccn == 'string') {
        arr = request.ccn.split(/[ ,]+/)
        request.ccn = arr.filter((el: any, i) => arr.indexOf(el) === i)
      }
    }
    return request
  }

  sendCommunication(claimId: number, type, request) {
    let url;
    if (type == 'call')
      url = this.urlBuilderService
        .withUrl(ApiRoutes.historyRoutes.sendSms)
        .withRoutedValues(claimId.toString())
        .build();
    else
      url = this.urlBuilderService
        .withUrl(ApiRoutes.historyRoutes.sendEmail)
        .withRoutedValues(claimId.toString())
        .build();
    request = this.mapDocumentCommunicationRequest(request)
    return this.httpService.post(url, request);
  }

  getHistory(claimId: number, request) {
    request.claimId = claimId
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.historyRoutes.getHistory)
      .build();
    return this.httpService.post(url, request).pipe(map((data) => this.historyLogDto(data)));
  }

  historyLogDto(data: any): Array<HistoryLog> {
    var array: Array<HistoryLog> = [];
    data.values.forEach(el => array.push(new HistoryLog(el, this.translationsService)))
    data.values = array;
    return data;
  }

  deleteAppointment(claimId, appointmentId): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.deleteAppointment)
      .withRoutedValues(claimId.toString(), appointmentId.toString())
      .build();
    return this.httpService.delete(url);
  }

  getUserAvailability(claimId, date, taskTypeId): Observable<AvailableSlot[] | null> {
    let selectedDate = date.utc(date.utcOffset()).toJSON()
    let url;
    if (taskTypeId == 34) // Visio
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.getVisioUserAvailability)
        .withRoutedValues(claimId.toString())
        .build();
    else if (taskTypeId == 35) // Desk
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.getDeskUserAvailability)
        .withRoutedValues(claimId.toString())
        .build();
    return this.httpService.get(url, { desiredDay: selectedDate });
  }

  setEmptyStringsToNull(obj: any) {
    if (moment.isMoment(obj))
      return obj
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object') {
          if (Array.isArray(obj[key])) {
            obj[key].forEach((element: any) => {
              this.setEmptyStringsToNull(element);
            });
          } else {
            this.setEmptyStringsToNull(obj[key]);
          }
        } else if (typeof obj[key] === 'string' && obj[key].trim() === '' && this.isWritable(obj, key)) {
          obj[key] = null;
        }
      }
    }
    return obj
  }

  isWritable<T extends Object>(obj: T, key: keyof T) {
    const desc = Object.getOwnPropertyDescriptor(obj, key) || {}
    return Boolean(desc.writable)
  }

  getCities(): Promise<Array<City>> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.cities.getAll)
      .build();
    const cities = this.httpService
      .get(url)
      .pipe(map((data) => this.cityDto(data)));
    return firstValueFrom(cities);
  }

  cityDto(data: any): Array<City> {
    var array: Array<City> = [];
    data.forEach(el => array.push(new City(el)))
    return array;
  }

  getCompanies(): Promise<Array<CrmAccount>> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.accountRoutes.getCompanies)
      .build();
    const companies = this.httpService
      .get(url)
      .pipe(map((data) => this.companyDto(data)));
    return firstValueFrom(companies);
  }

  companyDto(data: any): Array<CrmAccount> {
    var companies: Array<CrmAccount> = [];
    data.forEach(el => companies.push(new CrmAccount(el)))
    return companies;
  }

  getDamageTypes(): Promise<Array<DamageType>> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.damageTypeRoutes.getAll)
      .build();
    const damageTypes = this.httpService
      .get(url)
      .pipe(map((data) => this.damageTypeDto(data)));
    return firstValueFrom(damageTypes)
  }

  damageTypeDto(data: any): Array<DamageType> {
    var damageTypes: Array<DamageType> = [];
    data.forEach(el => damageTypes.push(new DamageType(el, this.translationsService)))
    return damageTypes;
  }

  getInsuranceCompanies(): Promise<Array<CrmAccount>> {
    let url = this.urlBuilderService
      // .withUrl(ApiRoutes.insuranceCompanyRoutes.getAll)
      .withUrl(ApiRoutes.accountRoutes.getInsuranceCompanies)
      .build();
    const insuranceCompanies = this.httpService
      .get(url)
      .pipe(map((data) => this.insuranceCompanyDto(data)));
    return firstValueFrom(insuranceCompanies);
  }

  insuranceCompanyDto(data: any): Array<CrmAccount> {
    var insuranceCompanies: Array<CrmAccount> = [];
    data.forEach(el => insuranceCompanies.push(new CrmAccount(el)))
    return insuranceCompanies;
  }

  getInstructionTypes(): Promise<Array<InstructionType>> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.instructionTypeRoutes.getAll)
      .build();
    const instructionTypes = this.httpService
      .get(url)
      .pipe(map((data) => this.instructionTypeDto(data)));
    return firstValueFrom(instructionTypes)
  }

  instructionTypeDto(data: any): Array<InstructionType> {
    var instructionTypes: Array<InstructionType> = [];
    data.forEach(el => instructionTypes.push(new InstructionType(el, this.translationsService)))
    return instructionTypes;
  }

  getAgencies(insuranceCompanyId: string) {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.agencyRoutes.getAll)
      .withRoutedValues(insuranceCompanyId?.toString())
      .build();
    this.httpService.get(url).subscribe((res: CrmAccount[]) => {
      if (res && res.length > 0)
        this.agencies.next(this.crmDto(res))
      else {
        this.agencies.next([]);
        this.cgas.next(null);
      }
    })
  }

  getGoodFamilies(): Promise<Array<GoodFamily>> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.goods.getAll)
      .build();
    const goodFamilies = this.httpService
      .get(url)
      .pipe(map((data) => this.goodsDto(data)));
    return firstValueFrom(goodFamilies)
  }

  goodsDto(data: any): Array<GoodFamily> {
    var goods: Array<GoodFamily> = [];
    data.forEach(el => goods.push(new GoodFamily(el.id, el.description)))
    return goods;
  }

  getCGAs(insuranceCompanyCrmId: string) {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.cgaRoutes.getByInsuranceCompany)
      .withRoutedValues(insuranceCompanyCrmId)
      .build();
    this.httpService.get(url).subscribe((res: []) => {
      if (res && res.length > 0) this.cgas.next(res)
      else this.cgas.next(null)
    })
  }

  getPortals(insuranceCompanyCrmId: string) {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.portalsRoutes.getByInsuranceCompany)
      .withRoutedValues(insuranceCompanyCrmId)
      .build();
    this.httpService.get(url).subscribe((res: []) => {
      if (res && res.length > 0) this.portals.next(res)
      else this.portals.next(null)
    })
  }

  getBrokers(): Observable<CrmAccount[]> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.accountRoutes.getBrokers)
      .build();
    return this.httpService.get(url).pipe(map((data) => this.crmDto(data)))
  }

  getAdministrators() {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.accountRoutes.getAdministrators)
      .build();
    return this.httpService.get(url).pipe(map((data) => this.crmDto(data)))
  }

  getCrmAccounts(type, insuranceCompanyCrmId?) {
    let route = '';
    let routedValue: string | null = null;
    switch (type) {
      case ActorType.Agenzia:
        route = ApiRoutes.accountRoutes.getAgencies;
        routedValue = insuranceCompanyCrmId;
        break;
      case ActorType.Broker:
        route = ApiRoutes.accountRoutes.getBrokers
        break;
      case ActorType.AmministratoreCondomino:
        route = ApiRoutes.accountRoutes.getAdministrators
        break;
      case ActorType.AziendaRiparazione:
        route = ApiRoutes.accountRoutes.getRepairers
        break;
      case ActorType.CLD:
        route = ApiRoutes.accountRoutes.getClds
        break;
    }
    let url = this.urlBuilderService
      .withUrl(route)
      .withRoutedValues(routedValue)
      .build();
    return this.httpService.get(url).pipe(map((data) => this.crmDto(data)))
  }

  crmDto(data: any): Array<CrmAccount> {
    var accounts: Array<CrmAccount> = [];
    data.forEach(el => accounts.push(new CrmAccount(el)))
    return accounts;
  }
}
