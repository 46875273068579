export class AssignedUser {
    user: string | null = null
    insuranceCompanyPriority: string | null = null
    exclusiveAgency: string | null = null
    exclusiveAdministrator: string | null = null
    exclusiveBroker: string | null = null

    constructor(data?: AssignedUser) {
        if (data) {
            this.user = data?.user
            this.insuranceCompanyPriority = data?.insuranceCompanyPriority
            this.exclusiveAgency = data?.exclusiveAgency
            this.exclusiveAdministrator = data?.exclusiveAdministrator
            this.exclusiveBroker = data?.exclusiveBroker
        }
    }
}

export class TaskUser {
    email: string | null = null
    name: string | null = null

    constructor(data?: TaskUser) {
        if (data) {
            this.email = data?.email
            this.name = data?.name
        }
    }
}

export class User {
    userName: string | null = null
    isSuperUser: boolean = false;

    constructor(data?: User) {
        if (data) {
            this.userName = data?.userName
            this.isSuperUser = data?.isSuperUser
        }
    }
}