import { HostListener, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrmAccount } from 'src/app/models/post';
import { UrlBuilderService } from './url-builder.service';
import { ApiRoutes } from '../api-routes';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import {
  City,
  DamageType,
  GoodFamily,
  InstructionType,
} from 'src/app/models/common-classes';
import { TranslationsService } from './translations.service';
import { ActorType } from 'src/app/models/enums/data-base';
import { SessionStorageKeys, SharedEntitiesKeys } from 'src/app/models/enums/front-end';

@Injectable({
  providedIn: 'root',
})
export class SharedEntitiesService {
  private _companies: any;
  private _insuranceCompanies: any;
  private _damagesTypes: any;
  private _instructionTypes: any;
  private _cities: any;
  private _goods: any;
  private _condominioAdmins: any;
  private _brokers: any;
  private _aziendeRiparazione: any;
  private _clds: any;

  constructor(
    private _httpService: HttpClient,
    private urlBuilderService: UrlBuilderService,
    private translationsService: TranslationsService
  ) {}

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    sessionStorage.clear();
  }

  getSharedEntity(entityType: SharedEntitiesKeys) {
    switch (entityType) {
      case SharedEntitiesKeys.Companies:
        return this.getEntityAndStoreInLocal(ApiRoutes.accountRoutes.getCompanies, SessionStorageKeys.Companies);
      case SharedEntitiesKeys.InsuranceCompanies: 
        return this.getEntityAndStoreInLocal(ApiRoutes.accountRoutes.getInsuranceCompanies, SessionStorageKeys.InsuranceCompanies);
      case SharedEntitiesKeys.CondominiumAdmins: 
        // return this.getEntityAndStoreInLocal(ApiRoutes.accountRoutes.getAdministrators, SessionStorageKeys.CondominioAdmins);
        return this.getCondominiumAdmin();
      case SharedEntitiesKeys.Brokers: 
        return this.getEntityAndStoreInLocal(ApiRoutes.accountRoutes.getBrokers, SessionStorageKeys.Brokers);
      case SharedEntitiesKeys.RepairsAgencies: 
        return this.getEntityAndStoreInLocal(ApiRoutes.accountRoutes.getRepairers, SessionStorageKeys.RiparazioneAgency);
      case SharedEntitiesKeys.Clds: 
        return this.getEntityAndStoreInLocal(ApiRoutes.accountRoutes.getClds, SessionStorageKeys.Clds);
      case SharedEntitiesKeys.InstructionTypes: 
        return this.getEntityAndStoreInLocal(ApiRoutes.instructionTypeRoutes.getAll, SessionStorageKeys.InstructionTypes, SharedEntitiesKeys.InstructionTypes);
      case SharedEntitiesKeys.DamageTypes: 
        return this.getEntityAndStoreInLocal(ApiRoutes.damageTypeRoutes.getAll, SessionStorageKeys.DamageTypes, SharedEntitiesKeys.DamageTypes);
      case SharedEntitiesKeys.Cities: 
        return this.getEntityAndStoreInLocal(ApiRoutes.cities.getAll, SessionStorageKeys.Cities, SharedEntitiesKeys.Cities);
      case SharedEntitiesKeys.Goods: 
        return this.getEntityAndStoreInLocal(ApiRoutes.goods.getAll, SessionStorageKeys.Goods, SharedEntitiesKeys.Goods);
      default:
        return null;
    }
  }

  /* async getCompanies(): Promise<Array<CrmAccount>> {
    this._companies = JSON.parse(sessionStorage.getItem(SessionStorageKeys.Companies));
    if (!this._companies) {
      let url = this.urlBuilderService
        .withUrl(ApiRoutes.accountRoutes.getCompanies)
        .build();

      await lastValueFrom(this._httpService.get(url)).then((results: any) => {
        this._companies = this.crmDto(results);
        sessionStorage.setItem(SessionStorageKeys.Companies, JSON.stringify(this._companies));
      });
    }

    return new Promise<any>((resolve) => {
      resolve(this._companies);
    });
  } */

  getAgencies(insuranceCompanyId: string) {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.agencyRoutes.getAll)
      .withRoutedValues(insuranceCompanyId?.toString())
      .build();
    return this._httpService.get(url).pipe(map(data => this.crmDto(data)));
  }

  getPortals(insuranceCompanyCrmId: string): Promise<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.portalsRoutes.getByInsuranceCompany)
      .withRoutedValues(insuranceCompanyCrmId)
      .build();
    return firstValueFrom(this._httpService.get(url));
  }

  getCGAs(insuranceCompanyCrmId: string): Promise<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.cgaRoutes.getByInsuranceCompany)
      .withRoutedValues(insuranceCompanyCrmId)
      .build();
    return firstValueFrom(this._httpService.get(url));
  }

  getCrmAccounts(type: ActorType) {
    switch (type) {
      case ActorType.Broker:
        return this.getSharedEntity(SharedEntitiesKeys.Brokers);
      case ActorType.AmministratoreCondomino:
        return this.getSharedEntity(SharedEntitiesKeys.CondominiumAdmins);
      case ActorType.AziendaRiparazione:
        return this.getSharedEntity(SharedEntitiesKeys.RepairsAgencies);
      case ActorType.CLD:
        return this.getSharedEntity(SharedEntitiesKeys.Clds);
      default:
        return new Promise<any>((resolve) => {
          resolve([]);
        });
    }
  }

  async getEntityAndStoreInLocal(route: string, storageKey: string, type?: SharedEntitiesKeys): Promise<any> {
    let entityList = JSON.parse(sessionStorage.getItem(storageKey));
    if (!entityList) {
      let url = this.urlBuilderService.withUrl(route).build();

      await lastValueFrom(this._httpService.get(url)).then((results: any) => {
        switch (type) {
          case SharedEntitiesKeys.DamageTypes:
            entityList = this.damageTypeDto(results);
            break;
          case SharedEntitiesKeys.InstructionTypes:
            entityList = this.instructionTypeDto(results);
            break;
          case SharedEntitiesKeys.Cities:
            entityList = this.cityDto(results);
            break;
          case SharedEntitiesKeys.Goods:
            entityList = this.goodsDto(results);
            break;
          default:
            entityList = this.crmDto(results);
            break;
        }
        sessionStorage.setItem(storageKey, JSON.stringify(entityList));
      });
    }

    return new Promise<any>((resolve) => {
      resolve(entityList);
    });
  }

  async getCondominiumAdmin(): Promise<Array<CrmAccount>> {
    // this._condominioAdmins = JSON.parse(sessionStorage.getItem(SessionStorageKeys.CondominioAdmins));
    if (!this._condominioAdmins) {
      let url = this.urlBuilderService
        .withUrl(ApiRoutes.accountRoutes.getAdministrators)
        .build();

      await lastValueFrom(this._httpService.get(url)).then((results: any) => {
        this._condominioAdmins = this.crmDto(results);
        // sessionStorage.setItem(SessionStorageKeys.CondominioAdmins, JSON.stringify(this._condominioAdmins));
      });
    }

    return new Promise<any>((resolve) => {
      resolve(this._condominioAdmins);
    });
  }

  crmDto(data: any): Array<CrmAccount> {
    var companies: Array<CrmAccount> = [];
    data.forEach((el) => companies.push(new CrmAccount(el)));
    return companies;
  }

  damageTypeDto(data: any): Array<DamageType> {
    var damageTypes: Array<DamageType> = [];
    data.forEach((el) =>
      damageTypes.push(new DamageType(el, this.translationsService))
    );
    return damageTypes;
  }
  
  instructionTypeDto(data: any): Array<InstructionType> {
    var instructionTypes: Array<InstructionType> = [];
    data.forEach((el) =>
      instructionTypes.push(new InstructionType(el, this.translationsService))
    );
    return instructionTypes;
  }

  cityDto(data: any): Array<City> {
    var array: Array<City> = [];
    data.forEach((el) => array.push(new City(el)));
    return array;
  }
  
  goodsDto(data: any): Array<GoodFamily> {
    var goods: Array<GoodFamily> = [];
    data.forEach((el) => goods.push(new GoodFamily(el.id, el.description)));
    return goods;
  }

}
