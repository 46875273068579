<div class="drawer-container light">
  <div class="drawer-header">
    <div class="history-title-header">
      <div>
        <div class="history-title">{{ "INB_SEARCH_PANEL.ADVANCED_SEARCH" | translate }}</div>
      </div>
      <div (click)="closeHistoryDrawer()" class="btn-close">
        <span>{{ "BUTTONS.CLOSE" | translate }}</span>
        <mat-icon>close</mat-icon>
      </div>
    </div>
  </div>

  <div class="drawer-content">
    <div class="drawer-section" [formGroup]="form">

      <mat-form-field>
        <mat-label>{{ 'INB_SEARCH_PANEL.ADVANCED_SEARCH_PLACEHOLDER' | translate }}</mat-label>
        <input type="tel" matInput formControlName="fullText">
        <!-- <mat-icon matSuffix>search</mat-icon> -->
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'CLAIM_DETAILS.ASSIGNMENT_DATA.CLAIM_REFERENCE_NUMBER' | translate }}</mat-label>
        <input type="tel" matInput formControlName="claimReferenceNumber">
        <!-- <mat-icon matSuffix>search</mat-icon> -->
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "SHARED_LABELS.INSTRUCTION_DATE_RANGE" | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="instructionDate">
          <input matStartDate formControlName="instructionDateFrom">
          <input matEndDate formControlName="instructionDateTo">
        </mat-date-range-input>
        <!-- <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint> -->
        <mat-datepicker-toggle matIconSuffix [for]="instructionDate"></mat-datepicker-toggle>
        <mat-date-range-picker #instructionDate></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "SHARED_LABELS.DATE_OF_LOSS_RANGE" | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="dateOfLoss">
          <input matStartDate formControlName="dateOfLossFrom">
          <input matEndDate formControlName="dateOfLossTo">
        </mat-date-range-input>
        <!-- <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint> -->
        <mat-datepicker-toggle matIconSuffix [for]="dateOfLoss"></mat-datepicker-toggle>
        <mat-date-range-picker #dateOfLoss></mat-date-range-picker>
      </mat-form-field>

      <div style="height: 79px;">
        <ng-select appearance="outline" class="top100-panel-select" [items]="companies"
          [selectOnTab]="true" appendTo="body" bindLabel="businessName" bindValue="crmId"
          [placeholder]="'SHARED_LABELS.COMPANY' | translate" [virtualScroll]="true"
          [dropdownPosition]="'auto'" formControlName="companyCrmId">
        </ng-select>
      </div>

      <div style="height: 79px;">
        <ng-select bindLabel="damageName" bindValue="id" formControlName="damageType"
          [placeholder]="'SHARED_LABELS.DAMAGE_TYPE' | translate" [virtualScroll]="true"
          [dropdownPosition]="'auto'" appearance="outline" class="top100-panel-select"
          [items]="damageTypes" [selectOnTab]="true" appendTo="body">
        </ng-select>
      </div>

      <div style="height: 79px;">
        <ng-select bindLabel="name" bindValue="email" formControlName="assignedUserEmail"
          [placeholder]="'SHARED_LABELS.ASSIGNEE' | translate" [virtualScroll]="true"
          [dropdownPosition]="'auto'" appearance="outline" class="top100-panel-select"
          [items]="users" [selectOnTab]="true" appendTo="body">
        </ng-select>
      </div>

      <mat-form-field>
        <mat-label>{{ 'SHARED_LABELS.ACTOR' | translate }}</mat-label>
        <input type="tel" matInput formControlName="actor">
        <!-- <mat-icon matSuffix>search</mat-icon> -->
      </mat-form-field>

    </div>
  </div>

  <div class="drawer-footer done">
    <button mat-raised-button class="btn-square btn-yellow" style="width: 100%;" (click)="save()">
      {{ 'BUTTONS.APPLY_FILTER' | translate }}
    </button>
  </div>
</div>