import { ClaimsFilters, ClaimsOrderBy } from "src/app/models/enums/front-end";

export class SearchRequest {
    pageNumber: number = 1;
    pageSize: number = 10;
    orderBy: number  = ClaimsOrderBy.Urgent;
    orderType: number = 1;
    // fullText: string | null = 'signalr';
    fullText: string | null = null;
    claimsFilter: number = ClaimsFilters.Tutte
}

export class HistoryRequest {
    claimId: number;
    logType: string | null = null;
    freeText: string | null = null;
    pageSize: number = 100;
    pageNumber: number = 1;
    continuationToken: string | null = null;
}