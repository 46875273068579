import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteCreationStatus } from 'src/app/models/enums/data-base';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-claim-details-header',
  templateUrl: './claim-details-header.component.html',
  styleUrls: ['./claim-details-header.component.scss'],
})
export class ClaimDetailsHeaderComponent{
  @Input() urls: any;
  sharePointStatus = SiteCreationStatus;
  claimId: number | null = null;

  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) {
    let claimId = this.route.snapshot.paramMap.get('claimId')
    if (claimId) {
      this.claimId = +claimId;
    }
  }

  goToEasyPerizia() {
    // window.open(this.urls.easyPerizia, "_blank");
  }

  goToMeteorage() {
    window.open(environment.meteorageUrl + this.claimId, "_blank");
  }

  goToSharePoint() {
    window.open(this.urls.sharePoint, "_blank");
  }

  goToInbox() {
    this.router.navigateByUrl('inbox/' + window.localStorage.getItem('filter'))
  }
}
