import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import * as signalR from '@microsoft/signalr';
import { ClaimNotificationResponse } from './responses/claim-notification-response';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserService } from './user.service';
import { ClaimNotification } from '../models/enums/front-end';
import { validTaskTypeIds, validTaskTypeIdsNotYetDeveloped } from '../models/constants';

const keysToCheck = ['taskTypeId', 'taskInstanceId', 'assignedUser'];

@Injectable({
  providedIn: 'root',
})
export class ClaimNotificationService implements OnDestroy {
  public baseUrl: string = environment.businessLayerUrl;
  user = new BehaviorSubject<User>(null);
  private hubConnection!: signalR.HubConnection;
  public refreshNeeded = new BehaviorSubject<boolean>(false);
  public regexClaimDetailsUrl = /\/claim\/\d+.*$/;
  public regexInboxUrl = /\/inbox(\/all|\/[^\/]+)?/i;
  public insertUrl = /\/claims/i;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: MsalService,
    private userService: UserService,
  ) {
    window.addEventListener('beforeunload', () => this.ngOnDestroy());
  }

  ngOnDestroy(): void {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.hubConnection.stop();
      // console.log('Connection stopped');
    }
  }

  public startConnection = (): void => {
    let token: string;

    this.authService.acquireTokenSilent({ scopes: environment.apiConfig.scopes_businesslayer }).subscribe({
        next: (res) => {
        token = res.accessToken;
        this.hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(environment.apiConfig.uri_businesslayer + '/claimNotificationsHub', { accessTokenFactory: () => token })
          .withAutomaticReconnect([
            // the parameter indicate the time in milliseconds between retries
            0,      // immediate retry
            5000,   // 5s
            5000,   // 10s
            10000,  // 20s
            10000,  // 30s
            15000,  // 45s
            15000,  // 1m
            30000,  // 1m30s
            30000,  // 2m
            30000,  // 2m30s
            30000,  // 3m
            null    // stop reconnection attempt after 3m
          ])
          .build();

        this.hubConnection
          .start()
          .then(() => {
            this.userService.user.subscribe((user) => {
              if (user != null)
                this.user.next(new User({
                  userName: user.userName,
                  isSuperUser: user.isSuperUser
                }));
            })
          })
          .catch(err => console.log('Error while starting connection: ' + err))

        // Register Callback
        this.hubConnection.on(ClaimNotification.NewTaskAssignedToYou, this.claimChangedStatus.bind(this));
        this.hubConnection.on(ClaimNotification.ClaimChangedStatus, this.claimChangedStatus.bind(this));
      }, error: (err) => { console.error(err) }
    });
  }

  private checkDictionaryKeys(data: ClaimNotificationResponse, keys: string[]): boolean {
    for (let key of keys) {
      if (!data.values[key]) {
        console.error(`Missing key ${key} in values dictionary`);
        return false;
      }
    }
    return true;
  }

  public claimChangedStatus(data: ClaimNotificationResponse): void {
    // console.log(data)
    // console.log(this.user.getValue()?.userName)
    let inboxUrl = 'inbox/' + window.localStorage.getItem('filter')
    let url = inboxUrl;

    if (this.regexInboxUrl.test(this.router.url)) {
      this.router.navigateByUrl(inboxUrl, { replaceUrl: true });
      return;
    }
    let claimIdFromUrl = this.getClaimIdFromUrl();
    if (claimIdFromUrl == data.claimId) {

      let claimDetailsUrl = `claims/claim/${data.claimId}/task-type/${data.values['taskTypeId']}/task/${data.values['taskInstanceId']}`;
      let userName = this.user.getValue()?.userName;
      let isNonSeqReassign = this.router.url.includes('add/task-type/8')

      if (isNonSeqReassign || data.values['taskTypeId'] == '3') {
        return;
      }

      if (!this.checkDictionaryKeys(data, keysToCheck)) {
        if (validTaskTypeIdsNotYetDeveloped.includes(+data.values['taskTypeId'])) {
          this.router.navigateByUrl(inboxUrl, { replaceUrl: true });
          return;
        }
        else return;
      }
      else if (validTaskTypeIdsNotYetDeveloped.includes(+data.values['taskTypeId']) || !validTaskTypeIds.includes(+data.values['taskTypeId'])) {
        this.router.navigateByUrl(inboxUrl, { replaceUrl: true });
        return;
      }
      else {
        // data.values['assignedUser'] = 'casandra.cubreacov@aeaitalia.com'
        if (userName == data.values['assignedUser'] && validTaskTypeIds.includes(+data.values['taskTypeId']))
          url = claimDetailsUrl;
        this.router.navigateByUrl(url, { replaceUrl: true });
      }
    }
  }

  getClaimIdFromUrl(): Number {
    let ids = window.location.pathname.match(/claim\/(\d+)/);
    let id = ids ? +ids[1] : -1;
    return id
  }

  public ensureConnection = async (): Promise<void> => {
    if (this.hubConnection && this.hubConnection.state !== signalR.HubConnectionState.Connected) {
      this.startConnection();
    }
  }
}