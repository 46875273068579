import { Component, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  snackBarRef = inject(MatSnackBarRef);
  config: any;
  progress = 100;
  private currentIntervalId;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {
    this.config = this.snackBarRef.containerInstance.snackBarConfig
    this.snackBarRef.afterOpened().subscribe({
      next: (res) => {
        this.runProgressBar(this.config.duration);
      },
      error: (err) => { console.error(err) },
    })
  }

  runProgressBar(duration: number) {
    this.progress = 100;
    const step = 0.005;
    clearInterval(this.currentIntervalId);
    this.currentIntervalId = setInterval(() => {
      this.progress -= 100 * step;
      if (this.progress < 0) {
        clearInterval(this.currentIntervalId);
      }
    }, duration * step);
  }
}
