import { Injectable } from '@angular/core';
import en from '../../../assets/i18n/en-GB.json';
import it from '../../../assets/i18n/it-IT.json';
import { FieldsToTranslate } from './fields-to-translate';
import { Language } from 'src/app/models/enums/front-end';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  constructor() {}

  translate(id: number | string, key: FieldsToTranslate): string | null {
    let lang = localStorage.getItem('selectedLanguage');
    var fields: object;
    switch (lang) {
      case Language.Italian:
        fields = it.FIELDS_TO_TRANSLATE;
        break;
      case Language.English:
        fields = en.FIELDS_TO_TRANSLATE;
        break;

      default:
        fields = en.FIELDS_TO_TRANSLATE;
        break;
    }

    const selArray = fields[key];

    // se l'array esiste
    if (selArray) {
      // cerca l'elemento sulla base dell'id
      const element = selArray.find((item) => item.id === id || item.code === id);
      // restituisce l'elemento trovato o null se non viene trovato nulla
      return element ? element.value : null;
    }
    // restituisce null se l'array non esiste
    return null;
  }
}
