import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslationsService } from './common/translations.service';
import { DateFormatterPipe } from '../shared/pipes/date-formatter.pipe';
import { ClaimsFilters } from '../models/enums/front-end';
import { InsurancePractice, PageClaims, Task } from '../models/common-classes';
import { ClaimDetails } from '../models/claim-details';
import { UrlBuilderService } from './common/url-builder.service';
import { ApiRoutes } from './api-routes';
import { HttpService } from './common/http.service';
import moment from 'moment';
import { Actor } from '../models/post';

@Injectable({
  providedIn: 'root',
})
export class InboxService {
  public baseUrl: string = environment.businessLayerUrl;
  claims: Array<InsurancePractice> = [];
  drawerComponent = new BehaviorSubject<ClaimDetails | InsurancePractice | string>(null);
  filters = [
    { id: ClaimsFilters.Tutte, count: 0, name: "all", translateName: "INB_MAIN_MENU.ALL_TASKS" },
    { id: ClaimsFilters.Urgenti, count: 0, name: "urgent", translateName: "INB_MAIN_MENU.URGENT" },
    { id: ClaimsFilters.Favorites, count: 0, name: "favorites", translateName: "INB_MAIN_MENU.FAVOURITES" },
    { id: ClaimsFilters.Apertura, count: 0, name: "opening", translateName: "INB_MAIN_MENU.OPENING" },
    { id: ClaimsFilters.Accertamenti, count: 0, name: "investigations", translateName: "INB_MAIN_MENU.INVESTIGATIONS" },
    { id: ClaimsFilters.Riparazione, count: 0, name: "repair", translateName: "INB_MAIN_MENU.REPAIR" },
    { id: ClaimsFilters.Perizia, count: 0, name: "expertise", translateName: "INB_MAIN_MENU.EXPERTISE" },
    { id: ClaimsFilters.Riapertura, count: 0, name: "reopening", translateName: "INB_MAIN_MENU.REOPENING" },
    { id: ClaimsFilters.Chiusura, count: 0, name: "closing", translateName: "INB_MAIN_MENU.CLOSING" },
    { id: ClaimsFilters.NonAssegnate, count: 0, name: "unassigned", translateName: "INB_MAIN_MENU.UNASSIGNED" },
    { id: ClaimsFilters.Suspended, count: 0, name: "suspended", translateName: "INB_MAIN_MENU.SUSPENDED" },
    { id: ClaimsFilters.Cancelled, count: 0, name: "cancelled", translateName: "INB_MAIN_MENU.CANCELLED" },
  ]

  constructor(
    private http: HttpClient,
    private translationsService: TranslationsService,
    private dateFormatterPipe: DateFormatterPipe,
    private urlBuilderService: UrlBuilderService,
    private httpService: HttpService
  ) { }

  public getClaims(properties): Observable<PageClaims> {
    const headers = { 'content-type': 'application/json' };
    const url: string = '/api/v1/claim/search';
    return this.http
      .post(this.baseUrl + url, JSON.stringify(properties), { headers: headers })
      .pipe(map((data) => this.getClaimsDto(data)));
  }

  public getActorsByClaim(claimId): Observable<Actor[]> {

    let url = this.urlBuilderService
      .withUrl(ApiRoutes.inboxRoutes.getActors)
      .withRoutedValues(claimId)
      .build();
    return this.http.get(url).pipe(map((data) => this.getActorsDto(data)));
  }

  public getAdvancedSearchClaims(request): Observable<PageClaims> {
    const headers = { 'content-type': 'application/json' };
    request = this.setEmptyStringsToNull(request)
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.inboxRoutes.advancedSearch)
      .build();
    return this.http
      .post(url, request)
      // .post(this.baseUrl + url, JSON.stringify(properties), { headers: headers })
      .pipe(map((data) => this.getClaimsDto(data)));
  }

  setEmptyStringsToNull(obj: any) {
    if (moment.isMoment(obj))
      return obj.toDate()
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object') {
          if (Array.isArray(obj[key])) {
            obj[key].forEach((element: any) => {
              this.setEmptyStringsToNull(element);
            });
          } else {
            this.setEmptyStringsToNull(obj[key]);
          }
        } else if (typeof obj[key] === 'string' && obj[key].trim() === '' && this.isWritable(obj, key)) {
          obj[key] = null;
        }
      }
    }
    return obj
  }

  isWritable<T extends Object>(obj: T, key: keyof T) {
    const desc = Object.getOwnPropertyDescriptor(obj, key) || {}
    return Boolean(desc.writable)
  }

  getClaimsDto(data: any): PageClaims {
    let claims = [];
    if (data != null && data.values != null && data.values.length > 0) {
      data.values.forEach(
        el => claims.push(
          new InsurancePractice(el, this.createTaskList(el.tasks), this.dateFormatterPipe, this.translationsService)
        ))
    }
    return new PageClaims(data, claims);
  }

  getActorsDto(data: any): Actor[] {
    let actors = [];
    if (data != null && data.getClaimActorResponses != null && data.getClaimActorResponses.length > 0) {
      data.getClaimActorResponses.forEach(el => actors.push(new Actor(el)))
    }
    return actors;
  }

  createTaskList(tasks: any): Array<Task> {
    let taskLst: Array<Task> = [];
    tasks.forEach(el => taskLst.push(new Task(el, this.translationsService)))
    return taskLst;
  }
}
