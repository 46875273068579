import { concat } from "rxjs";
import { FieldsToTranslate } from "../services/common/fields-to-translate";
import { TranslationsService } from "../services/common/translations.service";
import { LastServiceTypes } from "./constants";
import { Actor, CrmAccount } from "./post";

export class Lists {
  companies: CrmAccount[] = [];
  // companies: Company[] = [];
  insuranceCompanies: CrmAccount[] = [];
  instructionTypes: InstructionType[] = [];
  damageTypes: DamageType[] = [];
  goods: GoodFamily[] = [];
  cities: City[] = [];

  constructor(data?: Lists) {
    if (data) {
      this.companies = data.companies
      this.insuranceCompanies = data.insuranceCompanies
      this.instructionTypes = data.instructionTypes
      this.damageTypes = data.damageTypes
      this.goods = data.goods
      this.cities = data.cities
    }
  }
}

export class InstructionType {
  id: number;
  name: string;
  code: string;
  constructor(data: InstructionType, translationService: TranslationsService) {
    this.id = data.id;
    this.name = translationService.translate(data.id, FieldsToTranslate.InstructionType);
    this.code = data.code;
  }
}


export class DamageType {
  id: number;
  damageName: string | null = null;
  damageCode: string | null = null;
  constructor(data: DamageType, translationService: TranslationsService) {
    this.id = data.id;
    this.damageName = translationService.translate(data.id, FieldsToTranslate.DamageType);
    this.damageCode = data?.damageCode;
  }
}

export class GoodFamily {
  id: number | null = null;
  quantity: number | null = null;
  description?: string | null = null;
  constructor(id: number, description: string) {
    this.id = id;
    this.description = description ?? null;
  }
}

export class City {
  cityId!: number;
  postalCode: string | null = null;
  city: string | null = null;
  province: string | null = null;
  country: string | null = null;
  displayName: string | null = null;

  constructor(data) {
    this.cityId = data.id;
    this.city = data.name ?? '';
    this.province = data.provinceCode ?? '';
    this.country = data.countryCode ?? '';
    this.postalCode = data.postalCode ?? '';
    this.displayName = Object.values(data).filter(x => { return (typeof x == 'string' && x) }).join(', ')
  }
}

export class ReducedCity {
  cityId: number[] = [];
  city: string | null = null;
  province: string | null = null;
  country: string | null = null;
  postalCode: string[] = [];

  constructor(data) {
    this.cityId = data.id;
    this.city = data.name ? data.name : '';
    this.province = data.provinceCode ? data.provinceCode : '';
    this.country = data.countryCode ? data.countryCode : '';
    this.postalCode = data.postalCode ? data.postalCode : '';
  }
}

export function convertLinks(text: string): string {
    const urlPattern = /(\b\w+\b):?\s+(https?:\/\/[^\s]+)/g;
    let result = text;
    result = result.replace(urlPattern, '<a class="history-link" href="$2" target="_blank">$1</a>');
    return result;
}

export class HistoryLog {
  type!: number; //      +
  title!: string; //      +
  content: string; //      +

  assignee?: string | null = null; // TaskUser, TaskNotSequential     +
  byUser?: string | null = null; // TaskUser, Assignment, TaskNotSequential     +
  code?: string | null = null; // TaskUser, Assignment, TaskNotSequential     +
  completedOn?: Date | null = null; // TaskUser, TaskNotSequential     +
  createdOn?: Date | null = null; // TaskUser, TaskNotSequential     +
  description?: string | null = null; // TaskUser, Assignment, TaskNotSequential
  executionType?: string | null = null; // TaskUser, TaskNotSequential     +
  id?: string | null = null; // TaskUser, Assignment, TaskNotSequential     +
  logCreatedOn?: Date | null = null; // TaskUser, TaskNotSequential     +
  previousAssignee?: string | null = null; // TaskUser, TaskNotSequential     +
  sequentialTask?: boolean = true; // TaskUser, TaskNotSequential     +
  status?: string | null = null; // TaskUser, TaskNotSequential == TaskUser == TaskSystem     +
  isReassignment?: boolean = false; // Assignment     +
  insertType?: string | null = null; // InsertType     +
  changes?: HistoryChanges[] | null = null; // Modify     +

  body?: string | null = null; // Email     +
  cc?: string | null = null; // Email     +
  ccn?: string | null = null; // Email     +
  receiver?: string | null = null; // Email     +
  sender?: string | null = null; // Email     +
  subject?: string | null = null; // Email     +

  actorClaimId?: string | null = null; // Appointment
  actorName?: string | null = null; // Appointment
  serviceType?: string | null = null; // Appointment
  userEmail?: string | null = null; // Appointment
  appointmentDateTime?: Date | null = null; // Appointment

  actorReferenceName?: string | null = null;
  actorReferenceEmail?: string | null = null;
  address?: string | null = null;
  inspectorEmail?: string | null = null;
  inspectorNote?: string | null = null;
  inspectorOverrideMotivation?: string | null = null;
  isPce?: boolean | null = false;

  constructor(data, translateService: TranslationsService) {
    this.type = data.type;
    this.title = data?.description || data?.subject || data?.changes?.descriptionField || data.type;
    this.logCreatedOn = data.logCreatedOn.replace(/‘/g, '').replace(/’/g, '');
    this.byUser = data.byUser;
    if (data.description) {
      if (data.typeId || data.code)
        this.description = translateService.translate(data.typeId || data.code, FieldsToTranslate.Task);
      else
        this.description = data.description;
    }
    this.content = data?.type;
    this.receiver = data?.receiver;
    this.cc = data.cc ? data?.cc.replace(/;/g, ', ') : null;
    this.ccn = data.ccn ? data?.ccn.replace(/;/g, ', ') : null;
    this.subject = data?.subject
    this.sender = data?.sender;
    this.assignee = data?.assignee;
    this.code = data?.code;
    this.completedOn = data?.completedOn ? data.completedOn.replace(/‘/g, '').replace(/’/g, '') : null;
    this.createdOn = data?.createdOn ? data?.createdOn.replace(/‘/g, '').replace(/’/g, '') : null;
    this.executionType = data?.executionType;
    this.id = data?.id;
    this.previousAssignee = data?.previousAssignee;
    this.sequentialTask = data?.sequentialTask;
    this.status = data?.status;
    this.isReassignment = data?.isReassignment;
    this.insertType = data?.insertType;
    this.changes = data?.changes;
    this.actorClaimId = data?.actorClaimId
    let actorName = data?.actorClaimFirstName + ' ' + data?.actorClaimLastName;
    this.actorName = actorName != 'undefined undefined' ? actorName : null;
    this.serviceType = data?.serviceType;
    this.userEmail = data?.userEmail;
    this.appointmentDateTime = data?.appointmentDateTime ? data?.appointmentDateTime.replace(/‘/g, '').replace(/’/g, '') : null;

    let actorReferenceName = data?.actorReferenceFirstName + ' ' + data?.actorReferenceLastName;
    this.actorReferenceName = actorReferenceName != 'undefined undefined' ? actorReferenceName : null;
    this.actorReferenceEmail = data?.actorReferenceEmail;
    this.inspectorEmail = data?.inspectorEmail;
    this.inspectorNote = data?.inspectorNote;
    this.inspectorOverrideMotivation = data?.inspectorOverrideMotivation;

    switch (data.type) {
      case 'InsertType': {
        let type = data?.insertType && data?.insertType == 'Manual' ? ' Manuale ' : ' Automatica ';
        this.title = 'Creazione' + type + 'Incarico';
        break;
      }
      case 'Sms': {
        this.content = 'Sms';
        this.title = 'SMS' + (data.receiver ? ' ' + data.receiver : '');
        this.body = convertLinks(data.body);
        break;
      }
      case 'ManualComunicationSms': {
        this.content = 'PhoneCall';
        this.title = 'Chiamata' + (data.receiver ? ' ' + data.receiver : '');
        this.body = convertLinks(data.body);
        break;
      }
      case 'Email':
      case 'ManualComunicationEmail': {
        this.content = 'Email';
        this.title = 'Email' + (data.receiver ? ' - ' + data.receiver : '');
        this.body = data.body.replace('a href', 'a class="history-link" href');
        this.body = convertLinks(data.body);
        break;
      }
      case 'TaskUser':
      case 'TaskSystem':
      case 'TaskNotSequential': {
        this.content = 'Task';
        let status;
        if (data.status)
          status = data.status == 'Completed' ? 'Completato - ' : 'Creato - ';
        this.title = status ? status + this.description : this.description;
        break;
      }
      case 'Modify': {
        if (data.changes.length == 1)
          this.title = 'Modifica' + (data.changes[0].descriptionField ? ' - ' + data.changes[0].descriptionField : '');
        else
          this.title = 'Modifiche';
        this.changes.forEach(el => {
          el .oldValue = el.oldValue.replace(', , , , ', '');
          el.newValue = el.newValue.replace(', , , , ', '');
        });
        break;
      }
      case 'Assignement': {
        this.title = data.previousAssignee ? 'Riassegnazione' : 'Assegnazione automatica';
        break;
      }
      case 'Inspection': {
        let pce = data.pce ? ' PCE' : '';
        this.title = 'Sopralluogo' + pce;
        this.address = data.address + ', ' + data.postalCode + ', ' + data.city + ', ' + data.province + ', ' + data.country;
        break;
      }
      case 'FirstContactPA1': {
        this.title = 'Primo Contatto PA1';
        this.status = data?.status == 'None' ? 'Nessuno' : data?.status == 'Requested' ? 'Richiesto' : data?.status == 'Accepted' ? 'Accettato' : 'Rifiutato';
        break;
      }
      case 'Appointment': {
        this.title = 'Appuntamento ' + data.serviceType + (this.actorName ? ' - ' + this.actorName : '');
        break;
      }
      default: {
        this.content = data?.type;
        break;
      }
    }
  }
}

export class HistoryChanges {
  fieldName?: string | null = null;
  descriptionField?: string | null = null;
  oldValue?: string | null = null;
  newValue?: string | null = null;
}

export class CityFilterKeys {
  city: string | null = null;
  province: string | null = null;
  country: string | null = null;
  postalCode: string | null = null;
}

export class Company {
  id: number;
  company: string | null;
  constructor(data: Company) {
    this.id = data.id;
    this.company = data?.company;
  }
}

export class InsuranceCompany {
  id: number;
  name: string | null;
  constructor(data: InsuranceCompany) {
    this.id = data.id;
    this.name = data?.name;
  }
}


export class InsuranceAgency {
  id: number;
  name: string | null;
  constructor(data: InsuranceAgency) {
    this.id = data.id;
    this.name = data?.name;
  }
}

export class CommonAddress {
  address: string | null = null;
  city: string | null = null;
  region: string | null = null;
  province: string | null = null;
  postalCode: string | null = null;
  country: string | null = null;
  cityId: string | null = null;
  constructor(data: CommonAddress) {
    this.address = data?.address;
    this.city = data?.city;
    this.region = data?.region;
    this.province = data?.province;
    this.postalCode = data?.postalCode;
    this.country = data?.country;
    this.cityId = data?.cityId;
  }
}

const serviceTypes = LastServiceTypes;

export class InsurancePractice {
  claimId: number;
  claimNumber: string;
  damageType: string;
  company: string;
  caseCode: string;
  platform: string;
  tasks: Array<Task> = [];
  siteCreationStatus: number;
  documentUploadedStatus: number;
  claimReferenceNumber: string;
  instructionDate: string;
  lossDate: string;
  process: string;
  insured: string;
  insuranceCompanyPortal: string;
  urgent: boolean;
  favorite: boolean;
  bizagiCaseName: string;
  lastService: string;
  status: string;

  constructor(data, tasks, dateFormatter, translationService) {
    this.claimId = data?.id;
    this.company = data?.companyName;
    this.caseCode = data?.caseCode;
    this.platform = data?.platform;
    this.claimNumber = data?.number;
    this.claimReferenceNumber = data?.claimReferenceNumber;
    this.instructionDate = data.instructionDate ? dateFormatter.transform(data.instructionDate, 'shortDate') : '';
    this.lossDate = data?.lossDate ? dateFormatter.transform(data.lossDate, 'shortDate') : '';
    this.siteCreationStatus = data?.siteCreationStatus;
    this.urgent = data?.urgent;
    this.favorite = data?.favorite;
    this.insuranceCompanyPortal = data?.insuranceCompanyPortalName;
    this.insured = data?.insured;
    this.tasks = tasks;
    this.damageType = data?.damageTypeId ? translationService.translate(data.damageTypeId, FieldsToTranslate.DamageType) : '';
    this.bizagiCaseName = data?.bizagiCaseName ?? '';
    let serviceType = data?.serviceType ? serviceTypes.find(el => el.id == data.serviceType).value : null
    this.lastService = serviceType && this.bizagiCaseName ? "BZ_" + serviceType + "_" + this.bizagiCaseName : ''
    this.status = data?.status ?? -1
  }
}

export class PageClaims {
  totalRecords: number;
  totalPages: number;
  totalFavorites: number;
  taskCategories: any;
  totalUrgent: number;
  claims: Array<InsurancePractice>;

  constructor(data, claims) {
    this.totalRecords = data?.totalRecords;
    this.totalPages = data?.totalPages;
    this.totalFavorites = data?.totalFavorites;
    this.taskCategories = data?.taskCategories;
    this.totalUrgent = data?.totalUrgentClaims,
      this.claims = claims;
  }
}

export class Task {
  id: number;
  taskTypeId: number;
  taskTypeCode: string;
  taskName: string | null;
  assignedUser: string | null;
  expireAt: Date;
  status: string | null;
  code: string | null;
  constructor(data, translationService) {
    if (data) {
      this.id = data.id;
      this.taskTypeId = data.taskTypeId;
      this.taskTypeCode = data?.taskTypeCode;
      this.taskName = data.taskTypeId ? translationService.translate(data.taskTypeId, FieldsToTranslate.Task) : '';
      this.assignedUser = data.assignedUser;
      this.expireAt = data.expireAt;
      let now = new Date().setHours(0, 0, 0, 0)
      let expire = new Date(this.expireAt).setHours(0, 0, 0, 0)
      this.status = now > expire ? 'red' : now - expire > 2 ? 'green' : 'yellow'
      this.code = data?.code
    }
  }
}

export class DialogTaskReassignmentData {
  public claim;
  public task: Task;

  constructor(claim, task: Task) {
    this.claim = claim;
    this.task = task;
  }
}

export class AvailableSlot {
  userStaffId: string | null = null;
  displayName: string | null = null;
  email: string | null = null;
  startAppointment: Date | null = null;
  endAppointment: Date | null = null;

  constructor(data?) {
    if (data) {
      this.userStaffId = data?.userStaffId
      this.displayName = data?.displayName
      this.email = data?.email
      this.startAppointment = data?.startAppointment
      this.endAppointment = data?.endAppointment
    }
  }
}

export class Appointment {
  nameAppointment: string | null = null;
  actorFullName: string | null = null;
  staffEmailAddress: string | null = null;
  startAppointment: Date | null = null;
  endAppointment: Date | null = null;
  address: string | null = null;
  note: string | null = null;

  constructor(data?: Appointment) {
    if (data) {
      this.nameAppointment = data?.nameAppointment
      this.actorFullName = data?.actorFullName
      this.staffEmailAddress = data?.staffEmailAddress
      this.startAppointment = data?.startAppointment
      this.endAppointment = data?.endAppointment
      this.address = data?.address
      this.note = data?.note
    }
  }
}

export class ActorDisplayName {
  displayName: string | null = null;

  constructor(data?: Actor) {
    if (data) {
      let name = []
      if (!data.isLegalPerson) {
        if (data.name)
          name.push(data.name)
        if (data.lastName)
          name.push(data.lastName)
      }
      else
        name.push(data.businessName)
      if (name.length == 0 && data.email)
        this.displayName = data.email
      else this.displayName = name.join(' ')
    }
  }
}

export class DisplayAddress {
  address: CommonAddress | null = null;
  addressOfActorId: number | null = null;
  displayName: string | null = null;
  stringAddress: string | null = null;
}
