<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  {{message}}
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="abort()">
		{{ 'BUTTONS.CANCEL' | translate}}
  </button>
  <button mat-raised-button [style.color]="data.btnColor" (click)="confirm()">
		{{ 'BUTTONS.CONFIRM' | translate}}
  </button>
</div>