// da rivedere i dati centrali se corispondono ai oggetti

import { FieldsToTranslate } from "../services/common/fields-to-translate";
import { TranslationsService } from "../services/common/translations.service";
import { ActorDisplayName, City } from "./common-classes";
import { SessionStorageKeys } from "./enums/front-end";

function cleanObject(obj: any): any {
  if (obj && typeof obj === 'object') {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        obj[key] = cleanObject(obj[key]);
      }
      if (obj[key] === null) {
        delete obj[key];
      }
    });
    if (Object.keys(obj).length === 0) {
      return null;
    }
  }
  return obj;
}

export class PaymentTypePreferred {
    code: string | null = null;
    description: string | null = null;
    constructor(data?: PaymentTypePreferred) {
        if (data) {
            this.code = data?.code
            this.description = data?.description
        }
    }
}

export class BirthData {
    dateOfBirth: Date | null = null;
    cityOfBirth: string | null = null;
    provinceOfBirth: string | null = null;
    countryOfBirth: string | null = null;
    constructor(data?: BirthData) {
        if (data) {
            this.dateOfBirth = data?.dateOfBirth
            this.cityOfBirth = data?.cityOfBirth
            this.provinceOfBirth = data?.provinceOfBirth
            this.countryOfBirth = data?.countryOfBirth
        }
    }
}

export class Actor {
    id: number | null = null;
    code: string | null = null;
    crmId: string | null = null;
    actorTypeIds: number[] = [];
    actorTypeNames: string[] = [];
    actorTypeString: string = '';
    isLegalPerson: boolean = false;
    name: string | null = null;
    lastName: string | null = null;
    businessName: string | null = null;
    fiscalCode: string | null = null;
    vatNumber: string | null = null;
    iban: string | null = null;
    email: string = null;
    mobilePhoneNumber: string | null = null;
    phoneNumber: string | null = null;
    phoneNumber2: string | null = null;
    birthData: BirthData = new BirthData();
    address?: CommonAddress | null = null;
    notes: string | null = null;
    displayName: string | null = null;

    constructor(data?: Actor) {
        if (data) {
            let translateService = new TranslationsService()
                this.id = data?.id;
                this.code = data?.code;
                this.crmId = data?.crmId
                this.actorTypeIds = data?.actorTypeIds ?? [];
                data.actorTypeIds.forEach(type => this.actorTypeNames.push(translateService.translate(type, FieldsToTranslate.ActorTypeIds)))
                this.actorTypeString = this.actorTypeNames.join(', ')
                this.displayName = new ActorDisplayName(data).displayName
                this.address = new CityToAddress(data?.address);
                this.birthData = data?.birthData;
                this.email = data?.email;
                this.iban = data?.iban;
                this.isLegalPerson = data?.isLegalPerson ?? false;
                this.businessName = data?.businessName;
                this.fiscalCode = data?.fiscalCode;
                this.vatNumber = data?.vatNumber;
                this.phoneNumber = data?.phoneNumber;
                this.mobilePhoneNumber = data?.mobilePhoneNumber;
                this.phoneNumber2 = data?.phoneNumber2;
                this.lastName = data?.lastName;
                this.name = data?.name;
                this.notes = data?.notes;
        }
    }
}

export class CityToAddress {
    address: string | null = null;
    city: string | null = null;
    cityId: number | null = null;
    province: string | null = null;
    postalCode: string | null = null;
    country: string | null = null;
    constructor(data?) {
        if (data) {
            let cities = JSON.parse(sessionStorage.getItem(SessionStorageKeys.Cities));
            const filterValues = data.city?.split(/[ ,-]+/);
            const filterValuesLowerCase = filterValues?.map(val => val.toLowerCase());
            if (data?.postalCode) filterValuesLowerCase?.push(data.postalCode)
            let city = cities.find(city =>
              filterValuesLowerCase?.every(filterValue =>
                city.displayName.toLowerCase().includes(filterValue)
              )
            );
            this.address = data?.address;
            this.city = city?.city;
            this.province = city?.province;
            this.postalCode = city?.postalCode;
            this.country = city?.country;
            this.cityId = data?.cityId;
        }
    }

}
export class CommonAddress {
    address: string | null = null;
    city: string | null = null;
    cityId: number | null = null;
    province: string | null = null;
    postalCode: string | null = null;
    country: string | null = null;
    constructor(data?: CommonAddress) {
        if (data) {
            this.address = data?.address;
            this.city = data?.city;
            this.province = data?.province;
            this.postalCode = data?.postalCode;
            this.country = data?.country;
            this.cityId = data?.cityId;
        }
    }
}

export class FilterAddress {
    address: string = '';
    city: string | number | City = '';
    cityId: number | null;
    province: string = '';
    postalCode: string = '';
    country: string = '';
    constructor(data?: CommonAddress) {
        if (data) {
            this.address = data.address ?? '';
            this.city = data.city ?? '';
            this.province = data.province ?? '';
            this.postalCode = data.postalCode ?? '';
            this.country = data.country ?? '';
            this.cityId = data.cityId;
        }
    }
}

export class MissingAgency {
    code: string | null = null;
    name: string | null = null
    address: string | null = null;
    city: number | null = null;
    phoneNumber: string | null = null;
    email: string | null = null;

    constructor(data: MissingAgency) {
        if (data) {
            this.name = data?.name;
            this.code = data?.code;
            this.address = data?.address;
            let postalCode;
            if (data.address)
                postalCode = data.address.match(/\b\d{5}\b/)
            if (postalCode) {
                postalCode = postalCode[0]
                let cities = JSON.parse(sessionStorage.getItem(SessionStorageKeys.Cities))
                let city = cities?.find(el => el.postalCode == postalCode)
                if (city)
                    this.city = city.cityId;
            }
            this.phoneNumber = data?.phoneNumber;
            this.email = data?.email;
        }
    }
}

export class Cld {
    cldName: string | null = null;
    cldCode: string | null = null;
    claimsAdjusters?: Liquidator | null = null;
    cldPhoneNumber: string | null = null;

    constructor(data?) {
        if (data) {
            this.cldName = data?.cldName;
            this.cldCode = data?.cldCode;
            if (data.claimsAdjusters)
                this.claimsAdjusters = cleanObject(data?.claimsAdjusters) ? new Liquidator(data.claimsAdjusters) : null;
            this.cldPhoneNumber = data?.cldPhoneNumber;
        }
    }
}

export class Liquidator {
    name: string | null = null;
    email: string = null;
    phoneNumber: string | null = null;
    code: string | null = null;
    fax: string | null = null;
    address: string | null = null;
    city: number | null = null;

    constructor(data?) {
        if (data) {
            this.name = data?.name;
            this.email = data?.email;
            this.phoneNumber = data?.phoneNumber;
            this.code = data?.code;
            this.fax = data?.fax;
            this.address = data?.address;
            let postalCode;
            if (data.address)
                postalCode = data.address.match(/\b\d{5}\b/)
            if (postalCode) {
                postalCode = postalCode[0]
                let cities = JSON.parse(sessionStorage.getItem(SessionStorageKeys.Cities))
                let city = cities?.find(el => el.postalCode == postalCode)
                if (city)
                    this.city = city.cityId;
            }
        }
    }
}

export class Agency {
    id: string | null = null;
    name: string | null = null
    constructor(data: Agency) {
        this.id = data?.id;
        this.name = data?.name;
    }
}

export class Good {
    id: number = null
    quantity: number = null

    constructor(data?: Good) {
        if (data) {
            this.id = data?.id
            this.quantity = data?.quantity
        }
    }
}

export class Note {
    id: number | null = null
    important: boolean | null = false
    text: string | null = null
    date: Date | null = null
    user: string | null = null
    taskTypeId: number | null = null
    taskTypeName?: string | null = null

    constructor(data?: Note) {
        if (data) {
            this.id = data?.id
            this.important = data?.important
            this.text = data?.text
            this.date = data?.date
            this.user = data?.user
            this.taskTypeId = data?.taskTypeId
        }
    }
}

export class Policy {
    policyNumber: string | null = null;
    coInsurance: boolean = false;
    coInsuranceAmount: number | null = null;
    contractorTypeId: number | null = null; // ?x
    dateFrom: Date | null = null;
    dateTo: Date | null = null;
    obligations: boolean = false;
    obligationDueDate: Date | null = null;
    obligationInstitute: string | null = null;
    administrativeRegularity: boolean | null = false;
    premiumPaymentDate: Date | null = null;

    constructor(data?: Policy) {
        if (data) {
            this.policyNumber = data?.policyNumber
            this.coInsurance = data?.coInsurance ?? false
            this.coInsuranceAmount = data?.coInsuranceAmount
            this.contractorTypeId = data?.contractorTypeId
            this.dateFrom = data?.dateFrom
            this.dateTo = data?.dateTo
            this.obligations = data?.obligations ?? false
            this.obligationDueDate = data?.obligationDueDate
            this.obligationInstitute = data?.obligationInstitute
            this.administrativeRegularity = data?.administrativeRegularity
            this.premiumPaymentDate = data?.premiumPaymentDate
        }
    }
}

export class CGA {
    cgaId: number | null = null;
    name: string | null = null;
    constructor(data?) {
        if (data) {
            this.cgaId = data.cgaId;
            this.name = data.name;
        }
    }
}

export class PreviousClaim {
    claimId: number | null = null
    claimNumber: string | null = null
    claimDate: Date | null = null
    coverage: string | null = null
    policyNumber: string | null = null
    liquidatedValue: number | null = null

    constructor(data?: PreviousClaim) {
        if (data) {
            this.claimId = data?.claimId
            this.claimNumber = data?.claimNumber
            this.claimDate = data?.claimDate
            this.coverage = data?.coverage
            this.policyNumber = data?.policyNumber
            this.liquidatedValue = data?.liquidatedValue
        }
    }
}

export class RelatedClaim {
    id: number;
    number: string;
    constructor(id: number, number: string) {
        this.id = id;
        this.number = number;
    }
}

export class Reserve {
    reserveChangeComunicated: boolean | null = false
    reserveChangeMotivation: string | null = null
    amount: number | null = null
    reserveInsertionDate: Date | null = null
    user: string | null = null
    taskTypeId: number | null = null
    taskTypeName?: string | null = null

    constructor(data?: Reserve) {
        if (data) {
            this.reserveChangeComunicated = data?.reserveChangeComunicated
            this.reserveChangeMotivation = data?.reserveChangeMotivation
            this.amount = data?.amount
            this.reserveInsertionDate = data?.reserveInsertionDate
            this.user = data?.user
            this.taskTypeId = data?.taskTypeId
        }
    }
}

export class CrmAccount {
    code: string | null = null;
    crmId: string | null = null;
    name: string | null = null;
    lastName: string | null = null;
    businessName: string | null = null;
    fiscalCode: string | null = null;
    vatNumber: string | null = null;
    email: string = null;
    phoneNumber: string | null = null;
    phoneNumber2: string | null = null;
    address: CommonAddress | null = null;
    displayName: string | null = null;

    constructor(data?) {
        if (data) {
            this.code = data?.code;
            this.crmId = data?.id;
            this.name = data?.firstName;
            this.lastName = data?.lastName;
            let displayName = data.businessName ? data.businessName.replace(/,/g, '') : null;
            displayName = displayName ? displayName.trim() : null
            this.displayName = displayName ? displayName : data.mail
            this.businessName = displayName;
            this.fiscalCode = data?.fiscalCode;
            this.vatNumber = data?.vatCode;
            this.email = data?.mail;
            this.phoneNumber = data?.phoneNumber;
            this.phoneNumber2 = data?.additionalNumber;
            this.address = new CommonAddress();
            this.address.address = data?.address
            let city;
            if (data.zipCode) {
                let cities = JSON.parse(sessionStorage.getItem(SessionStorageKeys.Cities))
                city = cities?.find(el => el.postalCode == data.zipCode)
            }
            if (city) {
                this.address.city = city.displayName;
                this.address.postalCode = city.postalCode;
                this.address.province = city.province;
                this.address.country = city.country;
                this.address.cityId = city.cityId;
            }
            else {
                this.address.city = data?.city;
                this.address.postalCode = data?.zipCode;
                this.address.province = data?.province;
                this.address.country = data?.country;
            }
        }
    }
}

export class VisioAppointment {
    date: Date | null = null
    actor: Actor | null = null

    constructor(data?: VisioAppointment) {
        if (data) {
            this.date = data?.date
            this.actor = data?.actor
        }
    }
}
