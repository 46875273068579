import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'currencyFormatter',
})
export class CurrencyFormatterPipe implements PipeTransform {
  transform(value: number): any {
    let locale = localStorage.getItem('selectedLanguage');
    if (value)
      return new Intl.NumberFormat(locale).format(Number(value));
    return null
  }
}
