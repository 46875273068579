import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Location } from '@angular/common';

import { FormGroup } from '@angular/forms';
import { InsurancePractice } from 'src/app/models/common-classes';
import { ClaimDetails } from 'src/app/models/claim-details';
import { HistoryRequest } from 'src/app/services/requests/search-request';
import { Actor } from 'src/app/models/post';
import { ActorType } from 'src/app/models/enums/data-base';
import { InboxService } from 'src/app/services/inbox.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ClaimsSharedService } from 'src/app/services/claims-shared.service';
import { SharedEntitiesService } from 'src/app/services/common/shared-entities.service';
import { SharedEntitiesKeys } from 'src/app/models/enums/front-end';
import { EmailForm, SmsForm } from 'src/app/models/forms/forms';
import { Subscription } from 'rxjs';


@Component({
  selector: 'historic-logbook',
  templateUrl: './historic-logbook.component.html',
  styleUrls: ['./historic-logbook.component.scss'],
})
export class HistoricLogbookComponent implements OnInit, OnDestroy {
  @Output() close$: EventEmitter<null> = new EventEmitter<null>();
  @Input() claim: InsurancePractice | ClaimDetails;

  claimId: number;
  claimDetails: ClaimDetails;
  insurancePractice: InsurancePractice;
  historyLogs: any[] | null;
  isInDetail: boolean = false;
  insured: string | null = null;
  request = new HistoryRequest()
  viewCommunication = false;
  receiverChoice = false;
  form: FormGroup;
  communicationType: string | null = null;
  actors: Actor[] | null = null;
  filteredActors: Actor[] | null = null;
  actor: Actor | number | null = null;
  actorType = ActorType;

  drawerComponent$: Subscription;

  constructor(
    private location: Location,
    private inboxService: InboxService,
    private claimService: ClaimService,
    private _claimSharedService: ClaimsSharedService,
    private _sharedEntitiesService: SharedEntitiesService
  ) {}

  ngOnInit(): void {
    this._sharedEntitiesService.getSharedEntity(SharedEntitiesKeys.Cities);
    this.drawerComponent$ = this._claimSharedService.drawerComponent.subscribe(claim => {
      if (claim && Object.keys(claim).includes('claimId')) {
        this.claim = claim as InsurancePractice | ClaimDetails;
        if (this.claim && this.claim.claimId) {
          if (Object.keys(this.claim).includes('relatedClaims')) {
            this.claimDetails = this.claim as ClaimDetails
            let insured = this.claimDetails.actors.find(el => el.actorTypeIds.includes(ActorType.Assicurato))
            this.insured = insured ? insured.displayName : null
            this.isInDetail = true;
          }
          else {
            this.isInDetail = false;
            this.insurancePractice = this.claim as InsurancePractice;
            this.insured = this.insurancePractice.insured
          }
          this.claimId = +this.claim.claimId
          this.viewCommunication = false;
          this.actors = null;
          this.getHistory()
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.drawerComponent$)
      this.drawerComponent$.unsubscribe();
  }

  getHistory() {
    this.claimService.getHistory(this.claimId, this.request).subscribe({
      next: (res: any) => {
        this.historyLogs = res.values;
      },
      error: (err) => { console.error(err) },
    });
  }

  searchInHistory(searchValue: string) {
    this.request.freeText = searchValue ? searchValue.toLowerCase() : null
    this.getHistory();
  }

  toggleCommunication() {
    this.form = null;
    this.viewCommunication = !this.viewCommunication;
  }

  initForm(value) {
    this.form = null;
    this.actor = null;
    this.communicationType = value;
    if (this.isInDetail) {
      this.actors = this.claimDetails.actors
      this.setActors()
    }
    else if (!this.actors) {
      this.inboxService.getActorsByClaim(this.claimId).subscribe({
        next: (res) => { this.actors = res; this.setActors() },
        error: (err) => { console.error(err) },
      })
    }
    else
      this.setActors();
  }

  setActors() {
    if (this.communicationType == 'email') {
      this.filteredActors = this.actors.filter(el => el.email)
      this.form = new FormGroup(new EmailForm())
      this.form.removeControl('listForDocumentsRequestIds')
    }
    else {
      this.filteredActors = this.actors.filter(el => el.mobilePhoneNumber)
      this.form = new FormGroup(new SmsForm)
    }
    if (this.filteredActors.length == 0)
      this.actor = -1;
  }

  setForm(value) {
    if (value != -1)
      this.form.get('receiver').setValue(value)
    else
      this.form.get('receiver').setValue(null)
    this.actor = value;
  }

  saveCommunication() {
    this.claimService.sendCommunication(this.claimId, this.communicationType, this.form.getRawValue()).subscribe({
      next: (res: any) => {
        this.form = null;
        this.actor = null;
        this.viewCommunication = !this.viewCommunication
        setTimeout(() => this.getHistory(), 2000)
      },
      error: (err) => { console.error(err) },
    });
  }

  closeHistoryDrawer() {
    this.actors = null;
    this.isInDetail ? this.location.back() : this.close$.emit();
  }
}
