<div [ngSwitch]="headerType">
  <app-header *ngSwitchCase="HeaderType.Inbox"></app-header>
  <app-claim-details-header
    [urls]="urls"
    *ngSwitchCase="HeaderType.ClaimDetails"
  ></app-claim-details-header>
  <app-insert-claim-header
    *ngSwitchCase="HeaderType.InsertClaim"
  ></app-insert-claim-header>
  <users-settings-header *ngSwitchCase="HeaderType.UsersSettings"></users-settings-header>
</div>
