import { ValidatorFn, Validators } from '@angular/forms';


export const dateWeekendFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  };

export interface IConfig {
  // TODO to review once added validators to all app formControls
  formControlName?: any;
  type?: any;
  hidden?: boolean;
  rootClass?: string; // HELP COMMENT Class to be added to host element
  labelClass?: string; // HELP COMMENT Class to be added to label div
  elementClass?: string; // HELP COMMENT Class to be added to element div
  label?: string;
  position?: number;
  placeholder?: string;
  optionList?: any[];
  disabled: boolean;
  readonly?: boolean;
  cols?: number;
  rows?: number;
  value?: any;
  checked?: boolean;
  minDate?: Date;
  maxDate?: Date;
  pipeName?: string;
  data?: any;
  isMultiSelect?: boolean;
  isMultiFile?: boolean;
  isGroup?: boolean;
  acceptTypeFile?: string;
  validationMessage?: { [key: string]: any };
  validation?: ValidatorFn[];
  validators?: { [key: string] : ValidatorFn };
}

export const formConfig = {
  email: {
    formControlName: 'email',
    label: 'SHARED_LABELS.EMAIL',
    hidden: false,
    disabled: false,
    validationMessage: { all: 'SHARED_LABELS.EMAIL_ERROR' },
    validation: [Validators.email] as ValidatorFn[],
  } as IConfig,
  phone: {
    formControlName: 'phoneNumber',
    label: 'SHARED_LABELS.PHONE',
    hidden: false,
    disabled: false,
    validationMessage: { all: 'SHARED_LABELS.PHONE_ERROR' },
    validation: [
      Validators.pattern(
        /^0\d{1,4}[-.\s]?\d{4,11}$|^(00|\+)\d{1,3}[-.\s]?\d{1,4}[-.\s]?\d{4,14}$|^(\+?\(?39\)?|0)?[0-9]{9,11}$/
      ),
    ] as ValidatorFn[], // regex for European telephone numbers
  } as IConfig,
  mobilePhoneNumber: {
    formControlName: 'mobilePhoneNumber',
    label: 'SHARED_LABELS.MOBILE_NUMBER',
    hidden: false,
    disabled: false,
    validationMessage: { all: 'SHARED_LABELS.PHONE_ERROR' },
    validators: { field: 
      Validators.pattern(
        /^(\+?\(?39\)?|0)?[0-9]{9,11}$/
      )}, // TODO to confirm pattern
    validation: [] as ValidatorFn[]
  } as IConfig,
  phoneNumber2: {
    formControlName: 'phoneNumber2',
    label: 'SHARED_LABELS.ADDITIONAL_NUMBER',
    hidden: false,
    disabled: false,
    validationMessage: { all: 'SHARED_LABELS.PHONE_ERROR' },
    validation: [
      Validators.pattern(
        /^(\+?\(?39\)?|0)?[0-9]{9,11}$/
      ),
    ] as ValidatorFn[], // TODO to confirm pattern
  } as IConfig,
  fiscalCode: {
    formControlName: 'fiscalCode',
    label: 'SHARED_LABELS.FISCAL_CODE',
    hidden: false,
    disabled: false,
    validationMessage: { all: 'SHARED_LABELS.FISCAL_CODE_ERROR' },
    validation: [
      Validators.pattern(/^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i),
    ] as ValidatorFn[],
  } as IConfig,
};
