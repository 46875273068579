<mat-drawer-container class="inbox-drawer-container" [hasBackdrop]="false">
  <mat-drawer #drawer class="inbox-drawer-content" [mode]="!isHistoryDrawer ? 'side' : 'over'" position="end" (openedChange)="onToggleDrawer($event)">
    <inbox-drawer (close$)="drawer.close(); this.isHistoryDrawer = false" (searchRequest$)="getInboxClaims($event)"></inbox-drawer>
  </mat-drawer>
  <mat-drawer-container>
    <app-dynamic-header [headerType]="headerType"></app-dynamic-header>
    <div class="content" style="background-color: #2c3a3d;">
      <!--START: Side Navigation menu-->
      <div class="side-nav" *ngIf="viewFilters">
        <a [routerLink]="[filter.name]" routerLinkActive="link-active" *ngFor="let filter of filters">
          <span>{{ filter.translateName | translate }}</span>
          <span class="count">{{ filter.count }}</span>
        </a>
        <span class="divider" *ngIf="false"></span>
        <a *ngIf="false">
          <span>{{ "INB_MAIN_MENU.YOUR_VIEW" | translate }}</span>
          <span><mat-icon matSuffix>more_horiz</mat-icon></span>
        </a>
      </div>
      <!--END: Side Navigation menu-->

      <!--START: Page Content-->
      <div class="box">

        <div class="header">

          <div class="search">
            <input #searchInput type="text" matInput
              placeholder="{{ 'INB_SEARCH_PANEL.SEARCH_PLACEHOLDER' | translate }}"
              (keyup.enter)="fullTextSearch(searchInput.value)" />
             <mat-icon *ngIf="searchInput.value && searchRequest.fullText" class="full-text-search-icon"
              (click)="searchInput.value = ''; fullTextSearch('')" matSuffix>close</mat-icon>
            <mat-icon *ngIf="!searchRequest.fullText" class="full-text-search-icon" matSuffix
              (click)="fullTextSearch(searchInput.value)">search</mat-icon>
          </div>

          <button mat-button class="btn-square white" (click)="openAdvancedSearch()">
            <a>{{ "INB_SEARCH_PANEL.ADVANCED_SEARCH" | translate }}</a>
            <mat-icon iconPositionEnd matSuffix>filter_list</mat-icon>
          </button>

          <button mat-button class="btn-square btn-dark-gray white" *ngIf="false">
            {{ "INB_SEARCH_PANEL.EDIT_VIEW" | translate }}
            <mat-icon iconPositionEnd matSuffix>border_color</mat-icon>
          </button>

        </div>

        <div class="table-container example-container mat-elevation-z8">
          <table mat-table [dataSource]="claims" class="mat-table">
            
            <ng-container matColumnDef="pratica">
              <th mat-header-cell *matHeaderCellDef style="position: relative">
                <div style="display: flex; align-items: center">
                  {{ "INB_TABLE.COLUMNS.PRACTICE" | translate }} &nbsp;
                  <mat-icon (click)="openSortingField = !openSortingField"
                    style="cursor: pointer;">
                    <span *ngIf="!openSortingField">expand_more</span>
                    <span *ngIf="openSortingField">keyboard_arrow_up</span>
                  </mat-icon>
                </div>
                <div *ngIf="openSortingField" [class.mat-elevation-z4]="true" class="filter-dialog">
                  <span (click)="sort('name')">Nome
                    <mat-icon *ngIf="nameAsc" svgIcon="sort_by_alpha"></mat-icon>
                    <mat-icon *ngIf="!nameAsc" svgIcon="sort_by_zeta"></mat-icon>
                  </span>
                  <span (click)="sort('urgency')">Urgenza
                    <mat-icon *ngIf="!urgencyAsc" svgIcon="green_light"></mat-icon>
                    <mat-icon *ngIf="urgencyAsc" svgIcon="red_light"></mat-icon>
                  </span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="row">
                  <mat-icon
                    [svgIcon]="element.siteCreationStatus == siteCreationStatus.Created ? 'sharePointEnabled' : 'sharePointDisabled'"></mat-icon>
                  <div>
                    <span class="dots green light">
                      <span></span><span></span><span></span>
                    </span>
                    <p class="claim-number">{{ element.claimNumber }}</p>
                    <!-- <p class="claim-status">
                        1.4 {{ "INB_TABLE.DATA.NEW_ASSIGNMENT" | translate }}
                      </p> -->
                  </div>
                </div>
                <div class="buttons">
                  <button mat-button class="btn-round btn-yellow" (click)="openTasks(element)">
                    {{ "BUTTONS.OPEN" | translate }}
                    <mat-icon iconPositionEnd>open_in_new</mat-icon>
                  </button>
                  <button mat-button class="btn-round btn-yellow" (click)="openClaimHistory(element)">
                    {{ "BUTTONS.HISTORY" | translate }}
                    <mat-icon iconPositionEnd>calendar_today</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="task">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-cell">
                  <mat-checkbox *ngIf="user && user.isSuperUser && allTasks.length > 0"
                                (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                  {{ "INB_TABLE.COLUMNS.TASK" | translate }}
                  <button mat-raised-button class="btn-square btn-yellow" (click)="onAssignedUserClick()" style="margin-left: 16px;"
                          *ngIf="user && user.isSuperUser && allTasks.length > 0" [disabled]="selection.selected.length == 0">
                    <a>{{ "BUTTONS.REASSIGN" | translate }}</a>
                  </button>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="task-item" *ngIf="element?.status == claimStatus.Cancelled; else taskTmpl">
                  <div class="task-name">
                    {{ "SHARED_LABELS.TASK_CANCELLED" | translate }}
                  </div>
                </div>
                <div class="task-item" *ngIf="element?.status == claimStatus.Suspended && element.tasks.length == 0">
                  <div class="task-name">
                    {{ "SHARED_LABELS.TASK_SUSPENDED" | translate }}
                  </div>
                </div>
                <ng-template #taskTmpl>
                  <div *ngFor="let task of element.tasks">
                    <div class="task-item" *ngIf="element.status != 2 && element.platform != 'BZ'">
                      <div class="task-light" [ngClass]="user && user.isSuperUser && isAssignableTask(element.statuts, element.platform, task) ? 'link' : ''"
                            *ngIf="isAssignableTask(element.statuts, element.platform, task)">
                        <mat-checkbox *ngIf="user && user.isSuperUser"
                                      (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(task) : null"
                                      [checked]="selection.isSelected(task)"
                                      [aria-label]="checkboxLabel(task)">
                        </mat-checkbox>
                        <mat-icon [ngClass]="task.status"
                                  (click)="onAssignedUserClick(task, element.claimId, element.claimNumber)">
                          person
                        </mat-icon>
                      </div>
                      <div class="column">
                        <div class="task-name" [ngClass]="element.status != claimStatus.Suspended ? 'link underline' : ''"
                          *ngIf="task.taskName && validTaskIds.includes(task.taskTypeId)"
                          (click)="claimDetails(element.claimId, false, task)">{{ task.taskName }}
                        </div>
                        <div class="task-name"
                          *ngIf="task.taskTypeId == -1 && element?.status != claimStatus.Inconsistent">
                          {{ "SHARED_LABELS.SYSTEM_TASK" | translate }}
                        </div>
                        <div class="task-name" *ngIf="!validTaskIds.includes(task.taskTypeId)">
                          {{ task.taskName }}
                        </div>
                        <div class="task-assigned-user"
                          *ngIf="task?.assignedUser && user && user.isSuperUser && task.taskName && task.taskTypeId != -1">
                          ({{ task.assignedUser }})
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div class="task-item" *ngIf="element?.status == claimStatus.Inconsistent">
                    <div class="task-name">
                      {{ "SHARED_LABELS.TASK_ERROR" | translate }}
                    </div>
                  </div>
                  <div class="task-item" *ngIf="element.platform == 'BZ'">
                    <div class="task-name">
                      <strong *ngIf="element.lastService">{{ element.lastService }}</strong>
                      <button mat-icon-button *ngIf="element.lastService" [cdkCopyToClipboard]="element.lastService"
                        style="height: initial !important;">
                        <mat-icon>content_copy</mat-icon>
                      </button>
                      <span *ngIf="!element.lastService">{{ "SHARED_LABELS.BZ_TASK" | translate }}</span>
                    </div>
                  </div>
                </ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="assicurato">
              <th mat-header-cell *matHeaderCellDef>
                {{ "INB_TABLE.COLUMNS.INSURED" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.insured }}</td>
            </ng-container>
            <ng-container matColumnDef="dataInizio">
              <th mat-header-cell *matHeaderCellDef>
                {{ "CLAIM_DETAILS.ASSIGNMENT_DATA.INSTRUCTION_DATE" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.instructionDate }}</td>
            </ng-container>
            <ng-container matColumnDef="numSinistro">
              <th mat-header-cell *matHeaderCellDef>
                {{ "INB_TABLE.COLUMNS.N_SINISTRO" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.claimReferenceNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="portaleProvenienza">
              <th mat-header-cell *matHeaderCellDef>
                {{ "INB_TABLE.COLUMNS.SOURCE_PORTAL" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.insuranceCompanyPortal }}
              </td>
            </ng-container>
            <ng-container matColumnDef="tipoDanno">
              <th mat-header-cell *matHeaderCellDef>
                {{ "INB_TABLE.COLUMNS.DAMAGE_TYPE" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.damageType }}</td>
            </ng-container>
            <ng-container matColumnDef="azienda">
              <th mat-header-cell *matHeaderCellDef>
                {{ "INB_TABLE.COLUMNS.AGENCY" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.company }}</td>
            </ng-container>
            <ng-container matColumnDef="favorites">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" style="text-align: center;">
                <mat-icon class="favourites-icon favorite" [ngClass]="element.favorite ? 'active' : 'inactive'"
                  (click)="toggleFavorite(element.claimId, element.favorite)"
                  [matTooltip]="'MESSAGES.FAVORITE_INFO' | translate">star</mat-icon>
                <mat-icon class="favourites-icon error" [ngClass]="element.urgent ? 'active' : 'inactive'"
                  (click)="toggleUrgent(element.claimId, element.urgent)"
                  [matTooltip]="'MESSAGES.URGENT_INFO' | translate">error</mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr *matNoDataRow>
              <td class="nodata-cell" [attr.colspan]="displayedColumns.length">
                <mat-icon>warning</mat-icon> 
                {{ 'SHARED_LABELS.EMPTY_TABLE' | translate}}
              </td>
            </tr>
          </table>
        </div>
        <mat-toolbar class="paginator-toolbar">
          <mat-toolbar-row class="paginator-toolbar-row">
            <mat-paginator #pag class="paginator" [pageSize]="searchRequest.pageSize" showFirstLastButtons
              [length]="totalItems" [pageSizeOptions]="tableSizes" [pageIndex]="searchRequest.pageNumber - 1"
              (page)="onChangePage($event)"></mat-paginator>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </div>
  </mat-drawer-container>
</mat-drawer-container>