import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';

import { InboxService } from '../services/inbox.service';
import { ClaimService } from '../services/claim.service';
import { TranslateService } from '@ngx-translate/core';
import { ClaimsSharedService } from '../services/claims-shared.service';
import { SharedEntitiesService } from '../services/common/shared-entities.service';
import { UserService } from '../services/user.service';

import { DialogTaskReassignmentComponent } from '../dialog-task-reassignment/dialog-task-reassignment.component';
import { DialogTasksComponent } from '../dialog-tasks/dialog-tasks.component';

import { SearchRequest } from '../services/requests/search-request';
import { InsurancePractice, PageClaims, Task } from '../models/common-classes';
import { Observable, Subscription } from 'rxjs';
import {
  ClaimStatus,
  ClaimsFilters,
  ClaimsOrderBy,
  HeaderType,
  SharedEntitiesKeys,
} from '../models/enums/front-end';
import { SiteCreationStatus, TaskTypeId } from '../models/enums/data-base';
import { User } from '../models/user';

import { validTaskTypeIds } from '../models/constants';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss', '../../assets/css/common.css'],
})
export class InboxComponent implements OnDestroy, OnInit {
  validTaskIds = validTaskTypeIds;
  selTaskInstance: number = -1;
  pageCount: number = 0;
  totalItems: number = 0;
  tableHeight: number = 0;
  openSortingField: boolean = false;
  nameAsc: boolean = false;
  urgencyAsc: boolean = false;
  tableSizes: Array<number> = [10, 20, 50, 100];
  tableHeaders: Array<string> = [
    'Pratica',
    'Task',
    'Assicurato',
    'DataInizio',
    'NumSinistro',
    'PortaleProvenienza',
    'TipoDanno',
    'Azienda',
    'Favorites',
  ];
  displayedColumns: string[] = [
    'pratica',
    'task',
    'assicurato',
    'dataInizio',
    'numSinistro',
    'portaleProvenienza',
    'tipoDanno',
    'azienda',
    'favorites',
  ];
  result: any;
  claims: any[] = [];
  allTasks: Task[] = [];
  filters: any;
  searchRequest = new SearchRequest();
  openCustomFilters = false;
  userFilteredOptions: Observable<string[]>;
  navigation$: Subscription;
  user$: Subscription;
  user: User | null = null;
  headerType: HeaderType = HeaderType.Inbox;
  siteCreationStatus = SiteCreationStatus;
  claimStatus = ClaimStatus;
  viewFilters: boolean = true;
  isHistoryDrawer: boolean = false;

  @ViewChild('usersDialogTemplate') usersDialogTemplate: TemplateRef<any>;
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private inboxService: InboxService,
    private claimService: ClaimService,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog,
    private _translateService: TranslateService,
    private _claimSharedService: ClaimsSharedService,
    private _sharedEntitiesService: SharedEntitiesService
  ) {
    this.user$ = this.userService.user.subscribe((res: User) => {
      this.user = res;
    });
    this.filters = this.inboxService.filters;
    this.getPreferredParams();
    this.navigation$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.includes('inbox')) {
        let filter = this.filters.find(
          (x) => x.name === event.url.split('/')[2]
        );
        if (filter) {
          this.searchRequest.claimsFilter = filter.id;
          localStorage.setItem('filter', filter.name);
        }
        this.allTasks = [];
        this.selection.clear();
        this.searchRequest.orderBy = ClaimsOrderBy.Urgent;
        this.searchRequest.orderType = 1;
        this.nameAsc = true;
        this.urgencyAsc = false;
        this.searchRequest.pageNumber = 1;
        this.getInboxClaims(this.searchRequest);
      }
    });
    this._claimSharedService.drawerComponent.subscribe((res) => {
      if (res && res == 'advancedSearch') this.viewFilters = false;
      else this.viewFilters = true;
    });
  }

  ngOnInit(): void {
    this._translateService.get('SHARED_LABELS.LINES_PER_PAGE').subscribe((res) => {
      this.paginator._intl.itemsPerPageLabel = res;
    });
    this._sharedEntitiesService.getSharedEntity(SharedEntitiesKeys.Cities);
  }

  ngOnDestroy(): void {
    if (this.navigation$) this.navigation$.unsubscribe();
    if (this.user$) this.user$.unsubscribe();
  }

  getPreferredParams() {
    if (localStorage.getItem('filter')) {
      let filterName = localStorage.getItem('filter');
      this.searchRequest.claimsFilter = this.filters.find(
        (x) => x.name === filterName
      ).id;
    } else {
      localStorage.setItem('filter', 'all');
      this.searchRequest.claimsFilter = ClaimsFilters.Tutte;
    }
    if (localStorage.getItem('pageSize'))
      this.searchRequest.pageSize = +localStorage.getItem('pageSize');
  }

  viewSortingDialog() {
    this.openSortingField = !this.openSortingField;
  }

  sort(parameter) {
    this.openSortingField = !this.openSortingField;
    if (parameter == 'name') {
      this.nameAsc = !this.nameAsc;
      this.urgencyAsc = false;
      this.searchRequest.orderBy = ClaimsOrderBy.Name;
      this.searchRequest.orderType = this.nameAsc ? 1 : 0;
    } else {
      this.urgencyAsc = !this.urgencyAsc;
      this.nameAsc = true;
      this.searchRequest.orderBy = ClaimsOrderBy.Urgent;
      this.searchRequest.orderType = this.urgencyAsc ? 0 : 1;
    }
    this.getInboxClaims(this.searchRequest);
  }

  onChangePage(event: PageEvent) {
    if (event.pageSize !== this.searchRequest.pageSize) {
      this.searchRequest.pageSize = event.pageSize;
      localStorage.setItem('pageSize', this.searchRequest.pageSize.toString());
      this.searchRequest.pageNumber = 1;
    } else {
      this.searchRequest.pageNumber = event.pageIndex + 1;
    }
    this.getInboxClaims(this.searchRequest);
  }

  fullTextSearch(inputValue: string) {
    this.searchRequest.fullText = inputValue ? inputValue : null;
    this.searchRequest.pageNumber = 1;
    this.getInboxClaims(this.searchRequest);
  }

  lastSearchRequest: any = null;

  getInboxClaims(pageSettings): void {
    this.allTasks = [];
    this.selection.clear();
    this.searchRequest = pageSettings;
    if (!Object.keys(pageSettings).includes('assignedUserEmail')) {
      this.lastSearchRequest = pageSettings;
    }
    const obs: Observable<PageClaims> = Object.keys(pageSettings).includes('assignedUserEmail')
      ? this.inboxService.getAdvancedSearchClaims(this.searchRequest)
      : this.inboxService.getClaims(this.searchRequest);
    obs.subscribe({
      next: (list: any) => {
        this.result = list;
        if (this.result != null) {
          if (this.drawer?.opened &&
            !Object.keys(pageSettings).includes('assignedUserEmail')) {
            if (this.result.claims.length == 1)
              this.openClaimHistory(this.result.claims[0]);
            else this.drawer.close();
          }
          this.pageCount =
            this.result.totalPages == 0 ? 1 : this.result.totalPages;
          this.totalItems = this.result.totalRecords;
          this.claims = [...this.result.claims];
          this.claims.forEach(claim => claim.tasks.forEach(task => {
            if (this.isAssignableTask(claim.status, claim.platform, task))
              this.allTasks.push(task)
          }));
          document.getElementsByClassName('table-container')[0]?.scroll(0, 0);
          this.getFiltersCount(this.result.taskCategories);
        }
      },
      error: (err) => {
        this.result = err;
        console.error(err);
      },
    });
  }

  isAssignableTask(status, platform, task) {
    TaskTypeId
    return status != ClaimStatus.Closed
      && status != ClaimStatus.Cancelled
      && status != ClaimStatus.Inconsistent
      && platform != 'BZ'
      && task.id != 0
      && task.taskTypeId != -1
      && task.taskTypeId != TaskTypeId.CancelledClaim
      && task.taskTypeId != TaskTypeId.SuspendedClaim;
  }

  getFiltersCount(categories) {
    if (categories)
      Object.entries(categories).forEach((element) => {
        this.filters.find((x) => x.id === +element[0]).count = element[1];
      });
  }

  claimDetails(claimId: number, newTab: boolean, task?: Task) {
    let url = this.router.serializeUrl(
      this.router.createUrlTree(['/claims/claim', claimId])
    );
    if (task && validTaskTypeIds.includes(task.taskTypeId))
      url += '/task-type/' +
        task.taskTypeId.toString() +
        '/task/' +
        task.id.toString();
    window.open(url, '_blank');
    // newTab ? window.open(url, '_blank') : this.router.navigate([url]);
  }

  openTasks(claim: InsurancePractice) {
    if (claim.status == ClaimStatus.Cancelled || claim.status == ClaimStatus.Suspended) {
      this.claimDetails(claim.claimId, true);
      return;
    }
    let tasks = []
    tasks = claim.platform == 'BZ' || claim.status == ClaimStatus.Closed ? [] : claim.tasks.filter(task => task.taskName && this.validTaskIds.includes(task.taskTypeId))
    if (!this.user.isSuperUser)
      tasks = tasks.filter(el => el.assignedUserEmail == this.user.userName)
    if (tasks.length > 1) {
      const tasksDialog = this.dialog.open(DialogTasksComponent, {
        panelClass: 'tasks',
        data: { tasks: tasks, claimId: claim.claimId },
      });
    } else if (tasks.length == 1)
      this.claimDetails(claim.claimId, true, tasks[0]);
    else this.claimDetails(claim.claimId, true);
  }

  selection = new SelectionModel<Task>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.allTasks.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.allTasks);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Task): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  onAssignedUserClick(task?: Task, claimId?: number, number?: string) {
    let data;
    data = task ? { task: task, claim: { id: claimId, number: number } } : this.selection.selected;
    if (this.user && this.user.isSuperUser) {
      const dialogRef = this.dialog.open(DialogTaskReassignmentComponent, {
        panelClass: 'custom-container',
        data: data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.event == 'inbox') {
          this.claims = [];
          this.getInboxClaims(this.searchRequest);
        }
      });
    }
  }

  openClaimHistory(claim) {
    this.isHistoryDrawer = true;
    this._claimSharedService.drawerComponent.next(claim);
    this.drawer.open();
  }

  openAdvancedSearch() {
    this._claimSharedService.drawerComponent.next('advancedSearch');
    this.drawer.open();
  }

  onToggleDrawer(event?) {
    if (!event) {
      let refresh =
        this._claimSharedService.drawerComponent.value == 'advancedSearch' &&
        this.lastSearchRequest != this.searchRequest;
      this._claimSharedService.drawerComponent.next(null);
      this.viewFilters = true;
      if (refresh) this.getInboxClaims(this.lastSearchRequest);
    }
  }

  toggleFavorite(id, favorite) {
    this.claimService.updateFavorite(id, favorite).subscribe({
      next: (res) => {
        this.getInboxClaims(this.searchRequest);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  toggleUrgent(id, urgent) {
    this.claimService.updateUrgent(id, { urgent: !urgent }).subscribe({
      next: (res) => {
        this.getInboxClaims(this.searchRequest);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
