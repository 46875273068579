import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogTaskReassignmentData } from '../models/common-classes';
import { TasksService } from '../services/tasks.service';
import { TaskUser } from '../models/user';
import { UserService } from '../services/user.service';
import { UserNotificationService } from '../services/user-notification.service';

@Component({
  selector: 'app-dialog-task-reassignment',
  templateUrl: './dialog-task-reassignment.component.html',
  styleUrls: ['./dialog-task-reassignment.component.scss']
})
export class DialogTaskReassignmentComponent implements OnInit {
  result: any;
  users: TaskUser[] | null = null;
  usersFiltered: TaskUser[] = [];
  userFilteredOptions: Observable<string[]>;
  assignedUser: string | null = null;
  taskId: number | null = null;
  isNonSeqReassign: boolean = false;
  tasks: number[] = [];

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogTaskReassignmentComponent>,
    public userService: UserService,
    private tasksService: TasksService,
    private _userNotificationService: UserNotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
  ) {
    if (dialogData.task) {
      this.assignedUser = dialogData.task.assignedUser
      this.taskId = dialogData.task.id
    }
    else {
      dialogData.forEach(el => this.tasks.push(el.id))
    }
  }

  ngOnInit(): void {
    this.isNonSeqReassign = this.router.url.includes('add/task-type/8')
    this.loadUsers();
  }

  loadUsers() {
    if (this.isNonSeqReassign || !this.taskId)
      this.tasksService.getAllUsersForReassignment().subscribe({
        next: (res) => {
          this.users = null;
          if (res) this.users = res;
          if (res && this.assignedUser) this.users = this.users.filter(user => user.email !== this.assignedUser);
          this.usersFiltered = this.users;
        },
        error: (err) => { this.result = err; console.error(err) }
      })
    else
      this.tasksService.getTaskAssignableUsers(this.taskId).subscribe({
        next: (res) => {
          this.users = null;
          if (res) this.users = res.filter(user => user.email !== this.assignedUser);
          this.usersFiltered = this.users;
        },
        error: (err) => { this.result = err; console.error(err) }
      })
  }

  reassignTaskInstance(email: string) {
    if (!this.taskId) {
      this.userService.multipleTaskReassignment({ user: email, taskInstanceIds: this.tasks }).subscribe({
        next: (res) => {
          this._userNotificationService.info('Tasks re-assigned to ' + email);
          this.dialogRef.close({ event: 'inbox' });
        },
        error: (err) => { this.result = err; console.error(err) }
      });
    }
    else {
      this.userService.taskReassignment({ user: email }, this.dialogData['task'].id).subscribe({
        next: (res) => {
          this._userNotificationService.info('Task re-assigned to ' + email);
          this.dialogRef.close({ event: 'inbox' });
        },
        error: (err) => { this.result = err; console.error(err) }
      });
    }
  }

  filterAssignableUsers(target: any) {
    let value = target.value.toLowerCase()
    if (value != '' && value != null) {
      this.usersFiltered = this.users.filter(el => {
        return el.email.toLowerCase().includes(value) || el.name.toLowerCase().includes(value)
      });
      this.usersFiltered = [...this.usersFiltered];
    } else {
      this.usersFiltered = [...this.users];
    }
  }

  claimDetails() {
    this.dialogRef.close();
    this.router.navigate(['/claim', this.dialogData.claim.id, 'task-type', this.dialogData.task.taskTypeId, 'task', this.dialogData.task.id]);
  }
}
