import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InboxComponent } from './inbox/inbox.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { PostLoginComponent } from './post-login/post-login.component';

const routes: Routes = [
  { path: '', redirectTo: '/inbox', pathMatch: 'full' },
  { path: 'post-login', component: PostLoginComponent },
  {
    path: 'inbox', component: InboxComponent,
    canActivate: [MsalGuard],
    children: [
      { path: '', redirectTo: 'all', pathMatch: 'full' },
      { path: ':filter', component: InboxComponent }
    ]
  },
  {
    path: 'claims',
    loadChildren: () => import('./claims/claims.module').then(module => module.ClaimsModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'users-settings',
    loadChildren: () => import('./users-settings/users-settings.module').then(module => module.UsersSettingsModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'dmn-engine',
    loadChildren: () => import('./dmn-engine/dmn-engine.module').then(module => module.DmnEngineModule),
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
