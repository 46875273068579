import { TranslationsService } from 'src/app/services/common/translations.service';
import { FieldsToTranslate } from 'src/app/services/common/fields-to-translate';
import { Actor, CommonAddress, Note, Policy, RelatedClaim, MissingAgency, Reserve, Cld } from 'src/app/models/post';
import { GoodFamily } from './common-classes';
import { LastServiceTypes } from './constants';
import { ActorType } from './enums/data-base';

const serviceTypes = LastServiceTypes;

function cleanObject(obj: any): any {
  if (obj && typeof obj === 'object') {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        obj[key] = cleanObject(obj[key]);
      }
      if (obj[key] === null) {
        delete obj[key];
      }
    });
    if (Object.keys(obj).length === 0) {
      return null;
    }
  }
  return obj;
}

export class ClaimDetails {
  claimId: number | null = null;
  claimNumber: string | null = null;
  caseCode: string | null = null;
  claimReferenceNumber: string | null = null;
  companyCrmId: string | null = null;
  companyName: string | null = null;
  insuranceCompanyCrmId: string | null = null;
  insuranceCompanyName: string | null = null;
  insuranceCompanyPortalCrmId: string | null = null;
  insuranceCompanyPortalName: string | null = null;
  instructionTypeId: number | null = null;
  instructionTypeName: string | null = null;
  damageTypeId: number | null = null;
  damageTypeName: string | null = null;
  instructionDate: Date | null = null;
  dateOfLoss: Date | null = null;
  notificationDate: Date | null = null;
  notificationText: string | null = null;
  proceduralIndications: string | null = null;
  quickSettlement: boolean = false;
  portal: string | null = null;
  requestedAmount: number | null = null;
  reserveAmount: number | null = null;
  lossLocation: CommonAddress = new CommonAddress();
  riskLocation: CommonAddress = new CommonAddress();
  emptyRiskLocation: boolean = true;
  previousClaims: RelatedClaim[] = [];
  relatedClaims: RelatedClaim[] = [];
  policy: Policy = new Policy();
  cgaId: number | null = null;
  cgaName: string | null = null;
  agency: MissingAgency | null = null;
  cld: Cld | null = null;
  actors: Actor[] = [];
  cesiId: number | null = null;
  cesiName: string | null = null;
  dayOpened: number | null = null;
  urgent: boolean = false;
  favorite: boolean = false;
  sharePointUrl: string | null = null;
  siteCreationStatus: number | null = null;
  documentUploadedStatus: number | null = null;
  goodFamilies: GoodFamily[] = [];
  notes: Note[] = [];
  reserves: Reserve[] = [];
  sameRiskLocation: boolean = false;
  platform: string | null = null;
  reserve: Reserve | null = null;
  bizagiCaseName: string | null = null;
  lastService: string | null = null;
  insuredName: string | null = null;
  agencyName: string | null = null;

  riskId: string | null = null;
  insuranceCompanyPriority: string | null = null;
  paymentTypePreferredCode: string | null = null;
  insuranceCompanyServiceName: string | null = null;
  isSelfCare: boolean = false;
  canBeRepaired: boolean = false;

  isReopened: boolean = false;
  isContested: boolean = false;
  hasSameClaimNumber: RelatedClaim[] = []
  hasSameEvent: RelatedClaim[] = [];

  status: string | null = null;

  constructor(data, private translationsService: TranslationsService) {
    this.claimId = data?.id;
    this.claimNumber = data?.claimNumber;
    this.caseCode = data?.caseCode;
    this.claimReferenceNumber = data?.claimReferenceNumber;
    this.companyCrmId = data?.companyCrmId;
    this.companyName = data?.companyName;
    this.insuranceCompanyCrmId = data?.insuranceCompanyCrmId;
    this.insuranceCompanyName = data?.insuranceCompanyName;
    this.insuranceCompanyPortalName = data?.insuranceCompanyPortalName;
    this.insuranceCompanyPortalCrmId = data?.insuranceCompanyPortalCrmId;
    this.instructionTypeId = data?.instructionTypeId;
    this.instructionTypeName = this.translationsService.translate(data?.instructionTypeId, FieldsToTranslate.InstructionType);
    this.damageTypeId = data?.damageTypeId;
    this.damageTypeName = this.translationsService.translate(data?.damageTypeId, FieldsToTranslate.DamageType);
    this.instructionDate = data?.instructionDate;
    this.dateOfLoss = data?.dateOfLoss;
    this.notificationDate = data?.notificationDate;
    this.notificationText = data?.notificationText;
    this.proceduralIndications = data?.proceduralIndications;
    this.quickSettlement = data?.quickSettlement;
    this.portal = data?.portal;
    this.requestedAmount = data?.requestedAmount;
    this.reserveAmount = data?.reserveAmount;
    this.lossLocation = data?.lossLocation;
    this.riskLocation = data?.riskLocation;
    this.sameRiskLocation = data?.sameRiskLocation;
    if (this.lossLocation.address == this.riskLocation.address && this.lossLocation.cityId == this.riskLocation.cityId)
      this.sameRiskLocation = true;
    if (this.riskLocation && Object.values(this.riskLocation).every(x => x == null || x == ''))
      this.emptyRiskLocation = true;
    else this.emptyRiskLocation = false;
    this.previousClaims = data?.previousClaims ?? [];
    this.relatedClaims = data?.relatedClaims ?? [];
    this.policy = new Policy(data?.policy);
    this.cgaId = data?.cgaId;
    this.cgaName = data?.cgaName;
    if (data.agency)
      this.agency = cleanObject(data.agency) ? new MissingAgency(data?.agency) : null;
    if (data.cld)
      this.cld = cleanObject(data.cld) ? new Cld(data.cld) : null;
    data?.actors.forEach(el => this.actors.push(new Actor(el)))
    this.cesiId = data?.cesiId;
    this.cesiName = this.translationsService.translate(data?.cesiId, FieldsToTranslate.Cesi);
    this.dayOpened = data?.dayOpened;
    this.urgent = data?.urgent;
    this.favorite = data?.favorite;
    this.sharePointUrl = data?.sharePointUrl;
    this.siteCreationStatus = data?.siteCreationStatus;
    this.documentUploadedStatus = data?.documentUploadedStatus;
    this.goodFamilies = data?.goodFamilies ?? [];
    this.goodFamilies.forEach(good => good.description = this.translationsService.translate(good.id, FieldsToTranslate.GoodFamilies))
    this.notes = data?.notes ?? [];
    this.notes.forEach(el => el.taskTypeName = this.translationsService.translate(el.taskTypeId, FieldsToTranslate.Task))
    this.reserves = data?.reserves ?? []
    this.reserves.forEach(el => el.taskTypeName = this.translationsService.translate(el.taskTypeId, FieldsToTranslate.Task))
    this.platform = data?.platform ?? ""
    this.bizagiCaseName = data?.bizagiCaseName ?? '';
    let serviceType = data?.serviceType ? serviceTypes.find(el => el.id == data.serviceType).value : null
    this.lastService = serviceType && this.bizagiCaseName ? "BZ_" + serviceType + "_" + this.bizagiCaseName : ''
    this.insuredName = this.actors.find(el => el.actorTypeIds.includes(ActorType.Assicurato))?.displayName ?? '--'
    this.agencyName = this.actors.find(el => el.actorTypeIds.includes(ActorType.Agenzia))?.displayName ?? '--'

    this.insuranceCompanyPriority = data?.insuranceCompanyPriority;
    this.paymentTypePreferredCode = data?.paymentTypePreferredCode;
    this.insuranceCompanyServiceName = data?.insuranceCompanyServiceName;
    this.canBeRepaired = data?.canBeRepaired ?? false;
    this.isReopened = data?.isReopened ?? false;
    this.isContested = data?.isContested ?? false;
    this.isSelfCare = data?.isSelfCare ?? false;
    this.hasSameClaimNumber = data?.hasSameClaimNumber ?? [];
    this.hasSameEvent = data?.hasSameEvent ?? [];
    this.riskId = data?.riskId;
    this.status = data?.status;
  }
}
