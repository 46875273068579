<div class="drawer-container light" *ngIf="viewCommunication">
  <div class="drawer-header" style="max-width: 400px;">
    <div class="drawer-subtitle">Communicazione</div>
    <!-- <div class="drawer-subtitle">{{ subtitle | translate }}</div> -->
    <div class="drawer-title">{{ claim?.claimNumber ?? '' }}</div>
  </div>

  <div class="drawer-content">

    <div class="drawer-section">
        <div class="radio-group-h">
            <mat-label>{{ "SHARED_LABELS.COMMUNICATION_TYPE" | translate }}</mat-label>
            <mat-radio-group (change)="initForm($event.value)">
                <mat-radio-button value="email">
                    {{ "SHARED_LABELS.EMAIL" | translate }}</mat-radio-button>
                <mat-radio-button value="call">
                    {{ "SHARED_LABELS.PHONE_CALL" | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>

        <div *ngIf="actors">
          <mat-form-field>
            <mat-label>{{ (communicationType == 'email' ? "SHARED_LABELS.RECIPIENT" : "SHARED_LABELS.ACTOR") | translate }}</mat-label>
            <mat-select (selectionChange)="setForm($event.value)" [ngModel]="actor">
              <mat-option *ngFor="let actor of filteredActors; let i = index" [value]="communicationType == 'call' ? actor.mobilePhoneNumber : actor.email">
                <strong>{{ actor.displayName }}</strong> - {{ actor.actorTypeString }}</mat-option>
              <mat-option [value]="-1">{{"SHARED_LABELS.OTHER" | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="actor && form && communicationType && communicationType == 'email'"
            [formGroup]="form">
            <mat-form-field>
                <mat-label>{{"SHARED_LABELS.RECIPIENT" | translate}}</mat-label>
                <input formControlName="receiver" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"SHARED_LABELS.CC" | translate}}</mat-label>
                <input matInput formControlName="cc">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"SHARED_LABELS.CCN" | translate}}</mat-label>
                <input formControlName="ccn" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"SHARED_LABELS.SUBJECT" | translate}}</mat-label>
                <input formControlName="subject" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"SHARED_LABELS.TEXT" | translate}}</mat-label>
                <textarea matInput formControlName="body" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3" cdkAutosizeMaxRows="20"></textarea>
            </mat-form-field>
        </div>

        <div *ngIf="actor && form && communicationType && communicationType == 'call'"
            [formGroup]="form">
            <mat-form-field>
                <mat-label>{{"SHARED_LABELS.PHONE" | translate}}</mat-label>
                <input formControlName="receiver" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"SHARED_LABELS.CONVERSATION_SUMMARY" | translate}}</mat-label>
                <textarea matInput formControlName="body" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3" cdkAutosizeMaxRows="20"></textarea>
            </mat-form-field>
        </div>

    </div>
  </div>
  <div class="drawer-footer">
    <button mat-raised-button class="btn-square btn-white" (click)="toggleCommunication()">
      {{ "BUTTONS.CANCEL" | translate }}
    </button>
    <button mat-raised-button class="btn-square btn-yellow" (click)="saveCommunication()" [disabled]="form && form.invalid">
      {{ "BUTTONS.SAVE" | translate }}
    </button>
  </div>

</div>
<div class="drawer-container dark" *ngIf="claim && !viewCommunication">
  <div class="drawer-header" style="max-width: 400px;">
    <div class="history-title-header" style="max-width: 400px;">
      <div>
        <div class="history-title" style="max-width: 250px; word-break: break-all; line-height: normal;">{{
          claim?.claimNumber ?? '' }}</div>
        <div class="history-subtitle">{{ insured ?? ''}}</div>
      </div>
      <div (click)="closeHistoryDrawer()" class="btn-close">
        <span>Chiudi</span>
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="searchbar">
      <input #searchHistoryInput type="text" matInput placeholder="Cerca"
        (keyup.enter)="searchInHistory(searchHistoryInput.value)" />
      <mat-icon class="full-text-search-icon" matSuffix
        (click)="searchInHistory(searchHistoryInput.value)">search</mat-icon>
    </div>
  </div>

  <div class="drawer-content">
    <mat-accordion multi="true" *ngIf="historyLogs && historyLogs.length > 0" class="drawer-section">
      <mat-expansion-panel class="history-panel" [expanded]="false" [ngSwitch]="log.content"
        *ngFor="let log of historyLogs, let i = index">
        <mat-expansion-panel-header class="history-panel-header">
          <mat-panel-title style="color: white; padding-left: 30px;">
            <div class="header-icon">
              <mat-icon *ngSwitchCase="'Email'" style="color: #007491;">mail</mat-icon>
              <mat-icon *ngSwitchCase="'Sms'" style="color: #007491;">sms</mat-icon>
              <mat-icon *ngSwitchCase="'PhoneCall'" style="color: #007491;">phone</mat-icon>
              <mat-icon *ngSwitchCase="'Task'"
                [ngClass]="log.status == 'Completed' ? 'task-completed' : 'task-created'">{{ log.status == 'Completed' ?
                'event_available'
                : 'event_note' }}</mat-icon>
              <mat-icon *ngSwitchCase="'Modify'" style="color: #ECCA54;">build</mat-icon>
              <mat-icon *ngSwitchCase="'InsertType'" style="color: black;">add_circle</mat-icon>
              <mat-icon *ngSwitchCase="'Assignement'" style="color: #BF4646;">assignment_ind</mat-icon>
              <mat-icon *ngSwitchCase="'Appointment'" style="color: #ECCA54;">do_not_disturb_on</mat-icon>
              <mat-icon *ngSwitchCase="'FirstContactPA1'" style="color: #ECCA54;">perm_contact_calendar</mat-icon>
              <mat-icon *ngSwitchCase="'Inspection'" style="color: #ECCA54;">settings_applications</mat-icon>
            </div>
            {{ log.title }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="history-content" [style.border-color]="i === historyLogs.length - 1 ? 'transparent' : ''">
          <p *ngIf="(log.byUser || log.logCreatedOn) && log.content != 'Task'" class="content-header">
            <span class="label" *ngIf="log.byUser || log.logCreatedOn">{{"HISTORY.LOG_COMPLETED" |
              translate}}</span>
            <span class="label" *ngIf="log.byUser">{{"HISTORY.BY_USER" | translate}}</span>
            <span *ngIf="log.byUser">{{ log.byUser }}</span>
            <span class="label" *ngIf="log.logCreatedOn">{{ "HISTORY.LOG_DATE" | translate }}</span>
            <span *ngIf="log.logCreatedOn">{{ log.logCreatedOn | date:'dd/MM/yyyy'}} </span>
            <span class="label" *ngIf="log.logCreatedOn">{{ "HISTORY.LOG_HOUR" | translate }}</span>
            <span *ngIf="log.logCreatedOn">{{ log.logCreatedOn | date:'H:mm':'it-IT'}}</span>
          </p>

          <div *ngSwitchCase="'Email'">
            <p><span class="label">{{ "SHARED_LABELS.RECIPIENT" | translate | lowercase }} </span>{{ log.receiver }}</p>
            <p *ngIf="log.sender"><span class="label">{{ "SHARED_LABELS.SENDER" | translate | lowercase }} </span>{{ log.sender }}</p>
            <p *ngIf="log.cc"><span class="label">{{ "SHARED_LABELS.CC" | translate | lowercase }} </span>{{ log.cc }}
            </p>
            <p *ngIf="log.ccn"><span class="label">{{ "SHARED_LABELS.CCN" | translate | lowercase }} </span>{{ log.ccn
              }}</p>
            <p><span class="label">{{ "SHARED_LABELS.SUBJECT" | translate | lowercase }}</span><br>{{ log.subject }}</p>
            <p><span class="label">{{ "SHARED_LABELS.TEXT" | translate | lowercase }}</span><br><span
                [innerHTML]="log.body"></span></p>
          </div>

          <div *ngSwitchCase="'Sms'">
            <p><span class="label">{{ "SHARED_LABELS.RECIPIENT" | translate | lowercase }}</span><br>{{ log.receiver }}
            </p>
            <p><span class="label">{{ "SHARED_LABELS.TEXT" | translate | lowercase }}</span><br><span
                [innerHTML]="log.body"></span></p>
          </div>

          <div *ngSwitchCase="'PhoneCall'">
            <p><span class="label">{{ "SHARED_LABELS.ACTOR" | translate | lowercase }}</span><br>{{ log.receiver }}
            </p>
            <p><span class="label">{{ "SHARED_LABELS.CONVERSATION_SUMMARY" | translate | lowercase }}</span><br><span
                [innerHTML]="log.body"></span></p>
          </div>

          <div *ngSwitchCase="'Task'">
            <p><span class="label">{{ "HISTORY.TASK" | translate | lowercase }} </span>
              {{ log.description ? log.description + ' ' : ''}}
              <span class="label">{{ "HISTORY.WAS" | translate | lowercase }} </span>
              {{ (log.status == 'Created' ? "HISTORY.CREATED" : "HISTORY.COMPLETED") | translate }}
              <span class="label" *ngIf="log.assignee">{{"HISTORY.BY_USER" | translate}}</span>
              <span *ngIf="log.assignee">{{ log.assignee }} </span>
              <span *ngIf="log.status == 'Created'">
                <span class="label" *ngIf="log.createdOn">{{ "HISTORY.LOG_DATE" | translate }}</span>
                <span *ngIf="log.createdOn">{{ log.createdOn | date:'dd/MM/yyyy'}} </span>
                <span class="label" *ngIf="log.createdOn">{{ "HISTORY.LOG_HOUR" | translate }}</span>
                <span *ngIf="log.createdOn">{{ log.createdOn | date:'H:mm':'it-IT'}}</span>
              </span>
              <span *ngIf="log.status == 'Completed'">
                <span class="label" *ngIf="log.completedOn">{{ "HISTORY.LOG_DATE" | translate }}</span>
                <span *ngIf="log.completedOn">{{ log.completedOn | date:'dd/MM/yyyy'}} </span>
                <span class="label" *ngIf="log.completedOn">{{ "HISTORY.LOG_HOUR" | translate }}</span>
                <span *ngIf="log.completedOn">{{ log.completedOn | date:'H:mm':'it-IT'}}</span>
              </span>
            </p>
          </div>

          <div *ngSwitchCase="'Modify'">
            <p *ngFor="let change of log.changes">
              <span class="label" *ngIf="change.descriptionField">{{ "HISTORY.FIELD" | translate | lowercase }}</span>{{
              change.descriptionField }}
              <span class="label">{{ "HISTORY.CHANGED" | translate | lowercase }}</span>
              <span class="label" *ngIf="change.oldValue">{{ "HISTORY.OLD_VALUE" | translate | lowercase }}</span>{{
              change.oldValue }}
              <span class="label" *ngIf="change.newValue">{{ "HISTORY.NEW_VALUE" | translate | lowercase }}</span>{{
              change.newValue }}
            </p>
          </div>

          <div *ngSwitchCase="'InsertType'">
            <p *ngIf="log.body"><span class="label">{{ "SHARED_LABELS.TEXT" | translate | lowercase }} </span>{{
              log.body }}</p>
            <p *ngIf="log.insertType"><span class="label">{{ "HISTORY.INSERT_TYPE" | translate | lowercase }} </span>
              {{ (log.insertType == 'Manual' ? 'HISTORY.MANUAL' : 'HISTORY.AUTOMATIC') | translate }}
            </p>
          </div>

          <div *ngSwitchCase="'Assignement'">
            <p *ngIf="log.previousAssignee"><span class="label">{{ "HISTORY.PREVIOUS_ASSIGNEE" | translate | lowercase
                }} </span>{{ log.previousAssignee }}</p>
            <p *ngIf="log.assignee"><span class="label">{{ "HISTORY.ASSIGNEE" | translate | lowercase }} </span>{{
              log.assignee }}</p>
          </div>

          <div *ngSwitchCase="'Appointment'">
            <p>
              <span class="label">{{"HISTORY.APPOINTMENT" | translate}}</span>
              <span *ngIf="log.userEmail">{{ log.userEmail }} </span>
              <span class="label" *ngIf="log.actorName && log.userEmail">{{"HISTORY.AND" | translate}}</span>
              <span *ngIf="log.actorName">{{ log.actorName }} </span>
              <span class="label" *ngIf="log.appointmentDateTime">{{ "HISTORY.LOG_DAY" | translate }}</span>
              <span *ngIf="log.appointmentDateTime">{{ log.appointmentDateTime | date:'dd/MM/yyyy'}} </span>
              <span class="label" *ngIf="log.appointmentDateTime">{{ "HISTORY.LOG_HOUR" | translate }}</span>
              <span *ngIf="log.appointmentDateTime">{{ log.appointmentDateTime | date:'H:mm':'it-IT'}}</span>
            </p>
          </div>

          <div *ngSwitchCase="'FirstContactPA1'">
            <p *ngIf="log.status"><span class="label">{{ "HISTORY.STATUS" | translate | lowercase }}</span>{{ log.status
              }}</p>
          </div>

          <div *ngSwitchCase="'Inspection'">
            <p *ngIf="log.actorReferenceName"><span class="label">{{ "SHARED_LABELS.ACTOR" | translate | lowercase }}
              </span>{{ log.actorReferenceName }} - {{ log.actorReferenceEmail }}</p>
            <p *ngIf="log.address"><span class="label">{{ "SHARED_LABELS.ADDRESS" | translate | lowercase }} </span>{{
              log.address }}</p>
            <p *ngIf="log.inspectorEmail"><span class="label">{{ "SHARED_LABELS.INSPECTOR" | translate | lowercase }}
              </span>{{ log.inspectorEmail }}</p>
            <p *ngIf="log.inspectorNote"><span class="label">{{ "SHARED_LABELS.INSPECTION_NOTE" | translate | lowercase
                }} </span>{{ log.inspectorNote }}</p>
            <p *ngIf="log.inspectorOverrideMotivation"><span class="label">{{ "SHARED_LABELS.MOTIVATION" | translate |
                lowercase }} </span>{{ log.inspectorOverrideMotivation }}</p>
          </div>

        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="historyLogs && historyLogs.length == 0" class="empty">
      <p>{{ "HISTORY.NO_LOGS" | translate }}</p>
    </div>
  </div>

  <div class="drawer-footer done">
    <button mat-raised-button class="btn-square btn-yellow" (click)="toggleCommunication()" style="width: 100%;">
      Aggiungi comunicazione
    </button>
  </div>
</div>