import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderType } from '../../../models/enums/front-end';

@Component({
  selector: 'app-dynamic-header',
  templateUrl: './dynamic-header.component.html',
  styleUrls: ['./dynamic-header.component.scss']
})
export class DynamicHeaderComponent {
  isInbox: boolean;
  HeaderType = HeaderType;
  @Input() headerType: HeaderType;
  @Input() urls;

  constructor(private router: Router) {}

  ngOnInit() {
    this.isInbox = this.router.url.includes('inbox');
  }
}
