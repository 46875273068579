export enum ClaimDataLists {
  Company = 0,
  DamageType = 1,
  InstructionType = 2,
  InsuranceCompany = 3,
  Goods = 4,
  Cities = 5,
}

export enum ClaimAnchors {
  AssignmentData = 1,
  ClaimData = 2,
  Notes = 3,
  Accounts = 4,
  Actors = 5,
  Policy = 6,
  Coverages = 7,
  InsuredItems = 8,
  Reserves = 9,
  Appointments = 10,
}

export enum ClaimEditMode {
  ActorClaimInsert,
  ActorClaimEdit,
  CompaniesDetails,
  ClaimSummary,
  MissingProps,
  ClaimDesc,
  ClaimPlace,
  DamageData,
  ManualChoice,
  RiskLocation,
}

export enum ClaimSections {
  Actor = 'actor',
  Company = 'assignment',
  Summary = 'data',
  Description = 'description',
  RiskLocation = 'risk',
  ClaimAddress = 'place',
  Damage = 'damage',
  Goods = 'goods',
  Note = 'note',
  ProceduralIndications = 'indications',
  Reserve = 'reserve',
  Policy = 'policy',
  Coverage = 'coverage',
  InsuredItem = 'insuredItem',
  Contact = 'contact',
  Account = 'account',
}

export enum ClaimsFilters {
  Apertura = 1,
  Accertamenti = 2,
  Perizia = 3,
  Chiusura = 4,
  Riapertura = 5,
  Riparazione = 6,
  Tutte = 7,
  Urgenti = 8,
  NonAssegnate = 9,
  Favorites = 10,
  Cancelled = 11,
  Suspended = 12
}

export enum ClaimsOrderBy {
  CompanyName = 0,
  DateOfLoss = 1,
  InstructionDate = 2,
  ClaimReferenceNumber = 3,
  UserCreator = 4,
  ExpertUser = 5,
  CurrentUser = 6,
  Name = 7,
  Urgent = 8,
}

export enum HeaderType {
  Inbox = 'Inbox',
  UsersSettings = 'UsersSettings',
  ClaimDetails = 'ClaimDetails',
  InsertClaim = 'InsertClaim',
}

export enum Language {
  Italian = 'it-IT',
  English = 'en-GB',
}

export class ClaimEditFieldsVisibility {
  actorClaim: boolean = false;
  companiesDetails: boolean = false;
  claimSummary: boolean = false;
  missingProps: boolean = false;
  claimDesc: boolean = false;
  claimPlace: boolean = false;
  damageData: boolean = false;
  manualChoice: boolean = false;
  riskLocation: boolean = false;
  goods: boolean = false;
  note: boolean = false;
  proceduralIndications: boolean = false;
  reserve: boolean = false;
  policy: boolean = false;
  coverage: boolean = false;
  insuredItem: boolean = false;
  contact: boolean = false;
}

export enum NonSeqTasksEnum {
  SospendiIncarico = 1,
  AnnullaSospensione = 2,
  GestioneContestazione = 3,
  PracticeHibernated = 4,
  BillingManagement = 5,
  NegativeExpertise = 6,
  CancelAssignment = 7,
  ReAssignmentTask = 8,
  SubmitAct = 9,
  InvioComunicazioni = 10,
  WriteInterlocutory = 11,
  NotesPortalCompany = 12,
  CollegaIncarico = 13,
  RequestDocuments = 14,
  NuoviDocumentiRicevuti = 15,
  Solicitation = 16,
}

export enum ClaimNotification {
  NewTaskAssignedToYou = 'NewTaskAssignedToYou',
  ClaimChangedStatus = 'ClaimChangedStatus',
}

export enum ClaimStatus {
  Opened = 'Opened',
  Closed = 'Closed',
  Cancelled = 'Cancelled',
  Suspended = 'Suspended',
  Inconsistent = 'Inconsistent',
}

export enum SharedEntitiesKeys {
  Companies,
  InsuranceCompanies,
  DamageTypes,
  InstructionTypes,
  CondominiumAdmins,
  Brokers,
  RepairsAgencies,
  Clds,
  Cities,
  Goods,
}

export enum SessionStorageKeys {
  Cities = 'aea_cities',
  Companies = 'aea_companies',
  InsuranceCompanies = 'aea_inscompanies',
  DamageTypes = 'aea_damagetypes',
  InstructionTypes = 'aea_instructiontypes',
  Goods = 'aea_goods',
  CondominioAdmins = 'aea_condominioadmins',
  Brokers = 'aea_brokers',
  RiparazioneAgency = 'aea_azienderiparazione',
  Clds = 'aea_clds',
}
