<mat-toolbar class="header-container">
  <img class="logo" src="../../../assets/images/logo-symphony-claim.png" alt="symphony-claim-logo"/>
  <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="false">
    {{ "MAIN_HEADER_MENU.INBOX" | translate }}
  </a>
  <a routerLink="/opzioni" routerLinkActive="active" *ngIf="false">
    {{ "MAIN_HEADER_MENU.OPTIONS" | translate }}
  </a>
  <a routerLink="/profilazione" routerLinkActive="active" *ngIf="false">
    {{ "MAIN_HEADER_MENU.PROFILE" | translate }}
  </a>
  <a routerLink="/regole" routerLinkActive="active" *ngIf="false">
    {{ "MAIN_HEADER_MENU.RULES" | translate }}
  </a>
  <a routerLink="/assegnazione-multipla"  routerLinkActive="active" *ngIf="false">
    {{ "MAIN_HEADER_MENU.MULTIPLE_ASSIGNMENT" | translate }}
  </a>
  <a routerLink="/help" routerLinkActive="active" *ngIf="false">
    {{ "MAIN_HEADER_MENU.HELP" | translate }}
  </a>
  <a routerLink="/dmn-engine" routerLinkActive="active" *ngIf="isSuperUser">
    {{ "MAIN_HEADER_MENU.DMN" | translate }}
  </a>
  <a routerLink="/users-settings" routerLinkActive="active" *ngIf="isSuperUser">
    {{ "MAIN_HEADER_MENU.USER_SETTINGS" | translate }}
  </a>
  <a mat-raised-button class="btn-round btn-white new_claim" routerLink="/claims" routerLinkActive="active">
    {{ "MAIN_HEADER_MENU.NEW_PRACTICE" | translate }}
    <mat-icon iconPositionEnd>add_circle</mat-icon>
  </a>
  <span class="divider">|</span>
  <!-- Commented because it is not in the mock -->
  <!--<a type="button" (click)="logout()">Logout</a>-->
  <span class="profile">
    <span *ngIf="isAutenticated">{{ activeUser }}</span>
    <mat-icon class="account-icon">account_circle</mat-icon>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>expand_more</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>