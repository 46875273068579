import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UrlBuilderService } from './common/url-builder.service';
import { HttpService } from './common/http.service';
import { ApiRoutes } from './api-routes';
import { User } from '../models/user';
import { MsalService } from '@azure/msal-angular';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  public baseUrl: string = environment.businessLayerUrl;
  user = new BehaviorSubject<User>(null);

  constructor(
    private urlBuilderService: UrlBuilderService,
    private httpService: HttpService,
    private authService: MsalService,
    ) { }

  taskReassignment(request, taskInstanceId: number): Observable<object> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.userRoutes.taskReassignment)
      .withRoutedValues(taskInstanceId.toString())
      .build();
    return this.httpService.put(url, request);
  }

  multipleTaskReassignment(request): Observable<object> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.userRoutes.tasksReassignment)
      .build();
    return this.httpService.put(url, request);
  }

  setUser(user) {
    // Uncomment and comment getUser when Msal return isSuperUser property
    // let activeUser = {
    //   name: user.name,
    //   userName: user.userName,
    //   isSuperUser: user.isSuperUser
    // }
    // this.user.next(activeUser)
  }

  getUser() {
    let activeAccount = this.authService.instance.getActiveAccount().username;
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.userRoutes.getUser)
      .withRoutedValues(activeAccount)
      .build();
    this.httpService.get(url).subscribe(
      {
        next: (user: User) => { this.user.next(user) },
        error: (err) => { console.error(err) }
      }
    );
  }
}
