import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

type PopupTypes = 'red' | 'yellow' | '#3f51b5'

interface DialogData {
  title_key: string;
  btnColor: PopupTypes;
  message_key: string;
}

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent {

  confirmAction$ = new EventEmitter<null>();
  abortAction$ = new EventEmitter<null>();

  title = '';
  message = '';

  constructor(
    public _translateService: TranslateService,
    public _dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    if (this.data.title_key === 'DELETE')
      this._translateService.get('SHARED_LABELS.CONFIRM_DELETION').subscribe(res => this.title = res);
    else if (this.data.title_key === 'PUBLISH')
      this._translateService.get('DMN_ENGINE.PUBLISH_DIALOG').subscribe(res => this.title = res);
    else if (this.data.title_key === 'CLOSE_REVIEW')
      this._translateService.get('DMN_ENGINE.CLOSE_REVIEW_DIALOG_TITLE').subscribe(res => this.title = res);

    if (!data.message_key)
      this._translateService.get('SHARED_LABELS.ARE_SURE_CONTINUE').subscribe(res => this.message = res);
    else {
      if (this.data.message_key === 'PUBLISH')
        this._translateService.get('DMN_ENGINE.PUBLISH_DIALOG_TEXT').subscribe(res => this.message = res);
      else if (this.data.message_key === 'CLOSE_REVIEW')
        this._translateService.get('DMN_ENGINE.CLOSE_REVIEW_DIALOG_TEXT').subscribe(res => this.message = res);
    }
  }

  confirm() {
    this.confirmAction$.emit();
  }

  abort() {
    this.abortAction$.emit();
  }
}
