import { AbstractControl, FormArray, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { formConfig } from './forms-config';
import { Actor, Good, Note, Policy, Reserve, BirthData, Cld } from 'src/app/models/post';
import { ErrorStateMatcher } from '@angular/material/core';
import { ClaimDetails } from '../claim-details';
import { AvailableSlot, City } from '../common-classes';
import { phonePattern } from 'src/app/claims/form-edit';

export const claimDatesValidator: ValidatorFn = (form: AbstractControl): ValidationErrors | null => {
    const from = form.get('dateOfLoss').value ?? null;
    const to = form.get('instructionDate').value ?? null;
    if (from && to && from > to)
        return { claimDates: true }
    if (from > new Date())
        return { dateOfLoss: true }
    if (to > new Date())
        return { instructionDate: true }
    return null
}

export const policyDatesValidator: ValidatorFn = (form: AbstractControl): ValidationErrors | null => {
    const policy = form.get('policy');
    if (!policy) return null;

    const from = policy.get('dateFrom').value ?? null;
    if (from > new Date())
        return { dateFrom: true }
    return null
}

export const fiscalCodePattern = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
export const ibanPattern = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const vatNumberPattern = /^([A-Z]{2})?[0-9]{11}$/;
export const numericPattern = /^[\d]{1,}$/;

export const multiEmailValidator = (control: FormControl) => {
    const emails = control.value.split(',');
    const valid = emails.every(email => emailPattern.test(email.trim()) || email == '');
    return valid ? null : { invalidEmails: true };
};

export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null) {
        if (form.errors?.['dateOfLoss']) return true
        if (form.errors?.['instructionDate']) return true
        if (form.errors?.['claimDates']) return true
        if (form.errors?.['dateFrom']) return true
        return false;
    }
}

export class ClaimFormGroup {
    claimForm = new FormGroup(new ClaimForm(), { validators: [claimDatesValidator] })
}

export class ClaimForm {
    caseCode = new FormControl<string | null>(null, {})
    // claimReferenceNumber = new FormControl<string | null>(null) // for tests only
    // companyId = new FormControl<string | null>(null) // for tests only
    // insuranceCompanyId = new FormControl<string | null>(null) // for tests only
    // instructionTypeId = new FormControl<number | null>(null) // for tests only
    // damageTypeId = new FormControl<number | null>(null) // for tests only
    // dateOfLoss = new FormControl<Date | null>(null) // for tests only
    // instructionDate = new FormControl<Date | null>(null) // for tests only
    // quickSettlement = new FormControl<boolean | null>(null) // for tests only
    claimReferenceNumber = new FormControl<string | null>(null, [Validators.required])
    companyId = new FormControl<string | null>(null, Validators.required)
    insuranceCompanyId = new FormControl<string | null>(null, Validators.required)
    instructionTypeId = new FormControl<number | null>(null, Validators.required)
    damageTypeId = new FormControl<number | null>(null, Validators.required)
    dateOfLoss = new FormControl<Date | null>(null, [Validators.required])
    instructionDate = new FormControl<Date | null>(null, [Validators.required])
    quickSettlement = new FormControl<boolean | null>(null, [Validators.required])
    riskId = new FormControl<string | null>(null, {}) // da aggiungere in template (insert, edit)
    notificationDate = new FormControl<Date | null>(null, {})
    notificationText = new FormControl<string | null>(null, {})
    proceduralIndications = new FormControl<string | null>(null, {})
    requestedAmount = new FormControl<number | null>(null, {})
    lossLocation = new FormGroup(new AddressForm(), {})
    riskLocation = new FormGroup(new AddressForm(), {})
    policy = new FormGroup(new PolicyForm(), {})
    cgaId = new FormControl<number | null>({ value: null, disabled: true }, {})
    actors = new FormArray([])
    urgent = new FormControl<boolean | null>(false, {})
    favorite = new FormControl<boolean | null>(false, {})
    goodFamilies = new FormArray([])
    notes = new FormArray([])
    sameRiskLocation = new FormControl<boolean | null>(true, {})
    reserve = new FormGroup<ReserveForm | null>(new ReserveForm(), {})
    insuranceCompanyPortalCrmId = new FormControl<string | null>({ value: null, disabled: true }, {})
    coInsurance = new FormControl<boolean | null>(false, {})
    coInsuranceAmount = new FormControl<number | null>(null, {})

    constructor(data?: ClaimDetails) {
        if (data) {
            this.caseCode.setValue(data?.caseCode);
            this.claimReferenceNumber.setValue(data?.claimReferenceNumber); this.claimReferenceNumber.clearValidators();
            this.companyId.setValue(data?.companyCrmId); this.companyId.clearValidators();
            this.insuranceCompanyId.setValue(data?.insuranceCompanyCrmId); this.insuranceCompanyId.clearValidators();
            this.instructionTypeId.setValue(data?.instructionTypeId); this.instructionTypeId.clearValidators();
            this.damageTypeId.setValue(data?.damageTypeId); this.damageTypeId.clearValidators();
            this.riskId.setValue(data?.riskId);
            this.dateOfLoss.setValue(data?.dateOfLoss); this.dateOfLoss.clearValidators();
            this.notificationDate.setValue(data?.notificationDate)
            this.notificationText.setValue(data?.notificationText)
            this.instructionDate.setValue(data?.instructionDate); this.instructionDate.clearValidators();
            this.proceduralIndications.setValue(data?.proceduralIndications)
            this.quickSettlement.setValue(data?.quickSettlement)
            this.requestedAmount.setValue(data?.requestedAmount)
            this.lossLocation.patchValue(data?.lossLocation)
            this.riskLocation.patchValue(data?.riskLocation)
            this.policy = new FormGroup(new PolicyForm(data?.policy), {})
            this.cgaId.setValue(data?.cgaId); if (this.cgaId.value) this.cgaId.enable();
            data?.actors.forEach(el => this.actors.push(new FormGroup(new ActorForm(el))))
            this.urgent.setValue(data?.urgent)
            this.favorite.setValue(data?.favorite)
            data?.goodFamilies.forEach(el => this.goodFamilies.push(new FormGroup(new GoodForm(el))))
            data?.notes.forEach(el => this.notes.push(new FormGroup(new NoteForm(el))))
            this.sameRiskLocation.setValue(data?.sameRiskLocation)
            this.insuranceCompanyPortalCrmId.setValue(data?.insuranceCompanyPortalCrmId); if (this.insuranceCompanyPortalCrmId.value) this.insuranceCompanyPortalCrmId.enable();
            this.coInsurance.setValue(data?.policy.coInsurance)
            this.coInsuranceAmount.setValue(data?.policy.coInsuranceAmount)
        }
    }
}

export class ActorForm {
    id = new FormControl<number | null>(null, {})
    code = new FormControl<string | null>(null, {})
    crmId = new FormControl<string | null>(null, {})
    actorTypeIds = new FormControl<number[]>([], [Validators.required])
    isLegalPerson = new FormControl<boolean | null>(false, [Validators.required])
    name = new FormControl<string | null>(null, {})
    lastName = new FormControl<string | null>(null, {})
    businessName = new FormControl<string | null>(null, {})
    fiscalCode = new FormControl<string | null>(null, Validators.pattern(fiscalCodePattern))
    vatNumber = new FormControl<string | null>(null, [Validators.pattern(vatNumberPattern)])
    iban = new FormControl<string | null>(null, [Validators.pattern(ibanPattern)])
    email = new FormControl<string | null>(null, [Validators.pattern(emailPattern)])
    phoneNumber = new FormControl<string | null>(null, [Validators.pattern(phonePattern)])
    phoneNumber2 = new FormControl<string | null>(null, formConfig.phoneNumber2.validation)
    mobilePhoneNumber = new FormControl<string | null>(null, [formConfig.mobilePhoneNumber.validators['field']])
    birthData = new FormGroup(new BirthDataForm(), {}) // da aggiungere in template
    address = new FormGroup(new AddressForm(), {})
    notes = new FormControl<string | null>(null, {})

    constructor(data?: Actor) {
        if (data) {
            this.id.setValue(data?.id)
            this.code.setValue(data?.code)
            this.crmId.setValue(data?.crmId)
            this.actorTypeIds.setValue(data?.actorTypeIds ?? [])
            this.isLegalPerson.setValue(data?.isLegalPerson)
            this.name.setValue(data?.name)
            this.lastName.setValue(data?.lastName)
            this.businessName.setValue(data?.businessName)
            this.fiscalCode.setValue(data?.fiscalCode)
            this.vatNumber.setValue(data?.vatNumber)
            this.iban.setValue(data?.iban)
            this.email.setValue(data?.email)
            this.phoneNumber.setValue(data?.phoneNumber)
            this.phoneNumber2.setValue(data?.phoneNumber2)
            this.mobilePhoneNumber.setValue(data?.mobilePhoneNumber)
            this.birthData = new FormGroup(new BirthDataForm(data?.birthData))
            this.address = new FormGroup(new AddressForm(data?.address))
            this.notes.setValue(data?.notes)
        }
    }
}

export class AddressForm {
    address = new FormControl<string | null>(null, {})
    city = new FormControl<string | null>(null, {})
    cityId = new FormControl<number | null>(null, {})
    province = new FormControl<string | null>(null, {})
    country = new FormControl<string | null>(null, {})
    postalCode = new FormControl<string | null>(null, {})

    constructor(data?) {
        if (data) {
            this.address.setValue(data.address)
            let value = null;
            if (data.city)
                value = data.city
            if (data.postalCode)
                value = value + ' ' + data.postalCode
            this.city.setValue(value);
            this.cityId.setValue(data?.cityId);
        }
    }
}

export class BirthDataForm {
    dateOfBirth = new FormControl<Date | null>(null, {})
    cityOfBirth = new FormControl<string | null>(null, {})
    provinceOfBirth = new FormControl<string | null>(null, {})
    countryOfBirth = new FormControl<string | null>(null, {})

    constructor(data?: BirthData) {
        if (data) {
            this.dateOfBirth.setValue(data?.dateOfBirth)
            this.cityOfBirth.setValue(data?.cityOfBirth)
            this.provinceOfBirth.setValue(data?.provinceOfBirth)
            this.countryOfBirth.setValue(data?.countryOfBirth)
        }
    }
}

export class AdvancedSearchForm {
    pageNumber = new FormControl<number | null>(1, {})
    pageSize = new FormControl<number | null>(null, {})
    orderBy = new FormControl<string | null>("CompanyName", {})
    orderType = new FormControl<string | null>("Ascending", {})
    fullText = new FormControl<string | null>(null, {})
    claimReferenceNumber = new FormControl<string | null>(null, {})
    instructionDateFrom = new FormControl<Date | null>(null, {})
    instructionDateTo = new FormControl<Date | null>(null, {})
    dateOfLossFrom = new FormControl<Date | null>(null, {})
    dateOfLossTo = new FormControl<Date | null>(null, {})
    companyCrmId = new FormControl<string | null>(null, {})
    damageType = new FormControl<string | null>(null, {})
    assignedUserEmail = new FormControl<string | null>(null, {})
    actor = new FormControl<string | null>(null, {})

    constructor() {
        let pageSize = window.localStorage.getItem('pageSize') ? +window.localStorage.getItem('pageSize') : 10
        this.pageSize.setValue(pageSize)
    }
}

export class GoodForm {
    id = new FormControl<number>(null, [Validators.required])
    quantity = new FormControl<number>(null, [Validators.required])

    constructor(data?: Good) {
        if (data) {
            this.id.setValue(data?.id)
            this.quantity.setValue(data?.quantity)
        }
    }
}

export class NoteForm {
    id = new FormControl<number | null>(null, {})
    important = new FormControl<boolean | null>(false, [Validators.required])
    text = new FormControl<string | null>(null, [Validators.required])
    date = new FormControl<Date | null>(null, {})
    user = new FormControl<string | null>(null, {})
    taskTypeId = new FormControl<number | null>(null, {})

    constructor(data?: Note) {
        if (data) {
            this.id.setValue(data?.id)
            this.important.setValue(data?.important)
            this.text.setValue(data?.text)
            this.date.setValue(data?.date)
            this.user.setValue(data?.user)
            this.taskTypeId.setValue(data?.taskTypeId)
        }
    }
}

export class PolicyForm {
    contractorTypeId = new FormControl<number | null>(null, [Validators.required])
    policyNumber = new FormControl<string | null>(null, [Validators.required])
    administrativeRegularity = new FormControl<boolean | null>(null, [Validators.required])
    // contractorTypeId = new FormControl<number | null>(null) // for tests only
    // policyNumber = new FormControl<string | null>(null) // for tests only
    // administrativeRegularity = new FormControl<boolean | null>(null) // for tests only
    dateFrom = new FormControl<Date | null>(null, {})
    dateTo = new FormControl<Date | null>(null, {})
    obligations = new FormControl<boolean | null>(false, {})
    obligationDueDate = new FormControl<Date | null>(null, {})
    obligationInstitute = new FormControl<string | null>(null, {})
    premiumPaymentDate = new FormControl<Date | null>(null, {})
    coInsurance = new FormControl<boolean | null>(false, {})
    coInsuranceAmount = new FormControl<number | null>(null, {})

    constructor(data?: Policy) {
        if (data) {
            this.contractorTypeId.setValue(data?.contractorTypeId); this.dateFrom.clearValidators();
            this.dateFrom.setValue(data?.dateFrom); this.dateFrom.clearValidators();
            this.dateTo.setValue(data?.dateTo); this.dateTo.clearValidators();
            this.policyNumber.setValue(data?.policyNumber); this.dateFrom.clearValidators();
            this.obligations.setValue(data?.obligations)
            this.obligationDueDate.setValue(data?.obligationDueDate)
            this.obligationInstitute.setValue(data?.obligationInstitute)
            this.administrativeRegularity.setValue(data?.administrativeRegularity); this.dateFrom.clearValidators();
            this.premiumPaymentDate.setValue(data?.premiumPaymentDate)
            if (typeof data.coInsurance == 'string')
                if (data.coInsurance == 'True')
                    this.coInsurance.setValue(true)
                else this.coInsurance.setValue(false)
            else this.coInsurance.setValue(data?.coInsurance)
        }
    }
}

export class ReserveForm {
    reserveChangeComunicated = new FormControl<boolean>(false, {})
    reserveChangeMotivation = new FormControl<string | null>(null, {})
    amount = new FormControl<number | null>(null, {})
    reserveInsertionDate = new FormControl<Date | null>(null, {})
    user = new FormControl<string | null>(null, {})
    taskTypeId = new FormControl<number | null>(null, {})

    constructor(data?: Reserve) {
        if (data) {
            this.reserveChangeComunicated.setValue(data?.reserveChangeComunicated)
            this.reserveChangeMotivation.setValue(data?.reserveChangeMotivation)
            this.amount.setValue(data?.amount)
            this.reserveInsertionDate.setValue(data?.reserveInsertionDate)
            this.user.setValue(data?.user)
            this.taskTypeId.setValue(data?.taskTypeId)
        }
    }
}

export class ManualChoiceForm {
    serviceTypeID = new FormControl<number | null>(null, Validators.required)

    constructor(data?) {
        if (data) {
            this.serviceTypeID.setValue(data?.serviceTypeID)
        }
    }
}

export class EmailForm {
    receiver = new FormControl<string | null>(null, [Validators.pattern(emailPattern), Validators.required])
    cc = new FormControl<string | null>('', [multiEmailValidator])
    ccn = new FormControl<string | null>('', [multiEmailValidator])
    subject = new FormControl<string | null>(null, [Validators.required])
    body = new FormControl<string | null>(null, [Validators.required])
    listForDocumentsRequestIds = new FormControl<number[] | null>([], [Validators.required])
}

export class SmsForm {
    receiver = new FormControl<string | null>(null, [formConfig.mobilePhoneNumber.validators['field'], Validators.required])
    body = new FormControl<string | null>(null, [Validators.required])
}

export class AppointmentForm {
    selectedActor = new FormControl<Actor>(null, [Validators.required])
    selectedTimeSlot = new FormControl<Date>(null, [Validators.required])
    selectedDate = new FormControl<Date>(null, [Validators.required])
    selectedUser = new FormControl<AvailableSlot>(null, [Validators.required])
}

export class InspectionForm {
    referenceActorId = new FormControl<string | null>(null, [Validators.required])
    addressOfActorClaimId = new FormControl<string | null>(null)
    inspectionAddress = new FormControl<any>(null, [Validators.required])
    inspectorEmailAddress = new FormControl<string | null>(null, [Validators.required])
    inspectorOverrideMotivation = new FormControl<string | null>(null)
    newAddress = new FormGroup(new AddressForm())
    isManualCat = new FormControl<boolean>(false)
    note = new FormControl<string | null>(null)
}
