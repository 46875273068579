<div class="dialog">
  <div class="dialog-header" *ngIf="dialogData.task">
    <span class="dialog-title">{{ dialogData.claim.claimNumber }}</span>
    <div class="urgency">
      <span class="dots light" [ngClass]="dialogData.task.status || 'green'"> <span></span><span></span><span></span> </span>
      <span class="title-info"> {{ "DIALOG_TASK_REASSIGNMENT.EXPIRES_AT" | translate }}</span>
      <span class="title-info bold">{{ dialogData.task.expireAt | dateFormatter : "shortDate" }}</span>
    </div>
    <span class="dialog-task link" (click)="claimDetails()">{{ dialogData.task.taskName }}</span>
  </div>

  <div class="dialog-content">

    <section *ngIf="assignedUser">
      <span class="section-title">{{ "MESSAGES.CURRENT_USER" | translate }}</span>
      <div class="profile">
        <mat-icon class="profile-icon">person</mat-icon>
        <span class="info">{{ assignedUser }}</span>
      </div>
    </section>

    <section *ngIf="!assignedUser && dialogData.task" class="profile">
      <mat-icon class="warning">error</mat-icon>
      <span class="info">{{ "DIALOG_TASK_REASSIGNMENT.NO_ASSIGNEE" | translate }}</span>
    </section>

    <section *ngIf="(users && users.length == 0)" class="profile">
      <mat-icon class="warning">error</mat-icon>
      <span class="info">{{ "MESSAGES.NO_USERS" | translate }}</span>
    </section>

    <section *ngIf="!users" class="profile">
      <mat-icon class="warning">error</mat-icon>
      <span class="info">{{ "ERRORS.SERVER" | translate }}</span>
    </section>

    <section *ngIf="users && users.length > 0">
      <span class="section-title">{{ "DIALOG_TASK_REASSIGNMENT.REASSIGN_LABEL" | translate }}</span>
      <div class="dialog-search-bar">
        <input type="text" class="search-input" matInput (input)="filterAssignableUsers($event.target)"
        placeholder="{{ 'DIALOG_TASK_REASSIGNMENT.SEARCH_INPUT_PLACEHOLDER' | translate }}"/>
        <mat-icon class="icon-search" matSuffix>search</mat-icon>
      </div>
      <div class="box">
      <div *ngFor="let user of usersFiltered" class="profile link">
        <span><mat-icon class="profile-icon">person_outline</mat-icon></span>
        <span (click)="reassignTaskInstance(user.email)"><strong>{{ user.name }}</strong> {{ user.email }}</span>
      </div>
    </div>
    </section>

  </div>
</div>