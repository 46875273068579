<div class="dialog">
    <div class="row">
        <span class="subtitle">{{ "DIALOG_TASKS.TITLE" | translate }}</span>
        <a (click)="closeDialog()">{{ "BUTTONS.CLOSE" | translate }}</a>
    </div>
    <div *ngFor="let task of tasks" class="task">
        <div class="row">
            <span class="dots light" [ngClass]="task.status">
                <span></span><span></span><span></span>
            </span>
            <span *ngIf="task.expireAt">{{ "CLAIM_DETAILS.ASSIGNMENT_DATA.EXPIRING_DATE" | translate }}{{ task.expireAt
                | dateFormatter : "shortDate" }}</span>
            <span class="right">{{ "CLAIM_DETAILS.MISSING_FIELDS_SECTION.ASSIGNED_TO" | translate }} {{ user.userName ==
                task.assignedUser ? ("CLAIM_DETAILS.MISSING_FIELDS_SECTION.YOU" | translate) : task.assignedUser
                }}</span>
        </div>
        <div class="row">
            <span class="title">{{ task.taskName }}</span>
            <a class="mini-fab" (click)="openClaim(task)"><mat-icon>keyboard_arrow_right</mat-icon></a>
        </div>
    </div>
</div>