import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TaskTypeId } from '../models/enums/data-base';
import { UrlBuilderService } from './common/url-builder.service';
import { ApiRoutes } from './api-routes';
import { HttpService } from './common/http.service';
import { TranslationsService } from './common/translations.service';
import { Appointment, Task } from '../models/common-classes';
import { CityToAddress } from '../models/post';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  public baseUrl: string = environment.businessLayerUrl;
  task = new BehaviorSubject<Task>(null);
  appointments = new BehaviorSubject<Appointment[]>(null);
  successfulAppointment = new Subject<boolean>();
  deletedAppointment = new Subject<boolean>();

  constructor(
    private httpService: HttpService,
    private urlBuilderService: UrlBuilderService,
    private translationsService: TranslationsService
  ) { }

  get(taskId: number) {
    if (taskId) {
      let url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.getById)
        .withRoutedValues(taskId?.toString())
        .build();
      this.httpService.get(url).subscribe({
        next: (res) => {
          let task = new Task(res, this.translationsService)
          this.task.next(task)
        },
        error: (err) => { console.error(err) }
      })
    }
  }

  completeClaimOpeningExit(claimId): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.completeClaimOpeningExit)
      .withRoutedValues(claimId.toString())
      .build();
    return this.httpService.post(url);
  }

  completeClaimCreation(claimId): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.completeClaimCreation)
      .withRoutedValues(claimId.toString())
      .build();
    return this.httpService.post(url);
  }

  completeManualChoice(claimId: number, body): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.manualChoice)
      .withRoutedValues(claimId.toString())
      .build();
    return this.httpService.put(url, body);
  }

  getActorsForAppointment(claimId, taskTypeId): Observable<any> {
    let route;
    if (taskTypeId === TaskTypeId.ManualStartVisio) {
      route = ApiRoutes.tasksRoutes.getVisioActorAppointmentAvailability;
    }
    else if (taskTypeId === TaskTypeId.ManualStartDocumental) {
      route = ApiRoutes.tasksRoutes.getDeskActorAppointmentAvailability;
    }

    let url = this.urlBuilderService
      .withUrl(route)
      .withRoutedValues(claimId.toString())
      .build();
    return this.httpService.get(url);
  }

  completeAppointments(claimId: number, taskTypeId, request): Observable<any> {
    let url;
    if (taskTypeId == TaskTypeId.ManualStartVisio)
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.completeStartVisio)
        .withRoutedValues(claimId.toString())
        .build();
    else if (taskTypeId == TaskTypeId.ManualStartDocumental)
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.completeStartDesk)
        .withRoutedValues(claimId.toString())
        .build();
    else if (taskTypeId == TaskTypeId.Inspection)
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.completeInspection)
        .withRoutedValues(claimId.toString())
        .build();
    return this.httpService.post(url, request);
  }

  cleanPutObject(body) {
    let putRequest = JSON.parse(JSON.stringify(body))
    delete putRequest.endAppointment;
    delete putRequest.email;
    delete putRequest.displayName;
    delete putRequest.isManualCat;
    return putRequest;
  }

  sendAppointment(claimId: number, body, taskTypeId) {
    let url;
    let putRequest = this.cleanPutObject(body)
    if (taskTypeId == TaskTypeId.ManualStartVisio)
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.sendVisioAppointment)
        .withRoutedValues(claimId.toString())
        .build();
    else if (taskTypeId == TaskTypeId.ManualStartDocumental)
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.sendDeskAppointment)
        .withRoutedValues(claimId.toString())
        .build();
    else if (taskTypeId == TaskTypeId.Inspection)
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.sendInspectionAppointment)
        .withRoutedValues(claimId.toString())
        .build();
    this.httpService.post(url, putRequest).subscribe({
      next: res => {
        this.getAllAppointments(claimId)
        this.successfulAppointment.next(true)
      },
      error: err => {
        console.error(err)
        this.successfulAppointment.next(false)
      }
    });
  }

  getAllAppointments(claimId) {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.getAllAppointments)
      .withRoutedValues(claimId.toString())
      .build();
    this.httpService.get(url).subscribe((res: Appointment[]) => {
      if (res && res.length > 0) {
        this.appointments.next(res);
      }
      else if (res) {
        this.appointments.next(null)
      }
    })
  }

  assignInspection(claimId, taskTypeId, body): Observable<any> {
    if (!body.inspectionAddress.postalCode) body.inspectionAddress = new CityToAddress(body.inspectionAddress)
    let url;
    let putRequest = this.cleanPutObject(body)
    if (taskTypeId == TaskTypeId.ManualAssignInspector)
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.assignInspection)
        .withRoutedValues(claimId.toString())
        .build();
    else if (taskTypeId == TaskTypeId.ManualAssignInspectorPCE)
      url = this.urlBuilderService
        .withUrl(ApiRoutes.tasksRoutes.assignInspectionPCE)
        .withRoutedValues(claimId.toString())
        .build();
    return this.httpService.post(url, putRequest)
  }

  checkNullValues(claimId): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.checkNullValues)
      .withRoutedValues(claimId.toString())
      .build();
    return this.httpService.get(url);
  }

  checkCompletedData(claimId): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.checkCompletedData)
      .withRoutedValues(claimId.toString())
      .build();
    return this.httpService.get(url);
  }

  sellRepair(claimId, body): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.sellRepair)
      .withRoutedValues(claimId.toString())
      .build();
    return this.httpService.put(url, body);
  }

  getTaskAssignableUsers(taskId): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.getAssignableUsers)
      .withRoutedValues(taskId?.toString())
      .build();
    return this.httpService.get(url);
  }

  getAllUsersForReassignment(): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.getAllUsersForReassignment)
      .build();
    return this.httpService.get(url);
  }

  getInspectionAssignableUsers(claimId, taskId, address): Observable<any> {
    if (!address.postalCode) address = new CityToAddress(address)
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.getInspectionUserAvailability)
      .withRoutedValues(taskId?.toString())
      .build();
    return this.httpService.post(url, { claimId: claimId, taskTypeId: taskId, address: address });
  }

  getAllCat(): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.getAllInspectionUsers)
      .build();
    return this.httpService.get(url);
  }

  getNonSeqTasks(user): Observable<any> {
    let url = this.urlBuilderService
      .withUrl(ApiRoutes.tasksRoutes.getNonSeqTasks)
      .withRoutedValues(user.toString())
      .build();
    return this.httpService.get(url, { username: user, input: user });
  }

  sendCommunications(claimId, putRequest, type): Observable<any> {
    putRequest = this.mapDocumentCommunicationRequest(putRequest)
    let url;
    if (type == 1)
      url = this.urlBuilderService.withUrl(ApiRoutes.nonSeqTasksRoutes.sendSms)
        .withRoutedValues(claimId).build();
    else
      url = this.urlBuilderService.withUrl(ApiRoutes.nonSeqTasksRoutes.sendEmail)
        .withRoutedValues(claimId).build();
    return this.httpService.post(url, putRequest);
  }

  cancelAssignment(taskId: number, motivation: string): Observable<any> {
    let url = this.urlBuilderService.withUrl(ApiRoutes.claimsRoutes.cancel)
      .withRoutedValues(taskId.toString()).build();
    return this.httpService.put(url, { reason: motivation });
  }

  getSuspendMotivations(): Observable<any> {
    let url = this.urlBuilderService.withUrl(ApiRoutes.nonSeqTasksRoutes.getAllSuspendMotivations).build();
    return this.httpService.get(url);
  }

  suspendAssignment(command): Observable<any> {
    let url = this.urlBuilderService.withUrl(ApiRoutes.nonSeqTasksRoutes.suspendAssignment).build();
    return this.httpService.post(url, command);
  }

  sendDocumentsRequest(claimId, putRequest): Observable<any> {
    putRequest = this.mapDocumentCommunicationRequest(putRequest)
    let url = this.urlBuilderService.withUrl(ApiRoutes.nonSeqTasksRoutes.sendDocumentsRequest)
      .withRoutedValues(claimId).build();
    return this.httpService.post(url, putRequest);
  }

  mapDocumentCommunicationRequest(request) {
    if ((request.cc && request.ccn) || (request.cc == '' && request.ccn) || (request.cc && request.ccn == '') || (request.cc == '' && request.ccn == '')) {
      let arr;
      if (request.cc == "" || !request.cc) request.cc = []
      else if (typeof request.cc == 'string') {
        arr = request.cc.split(/[ ,]+/)
        request.cc = arr.filter((el: any, i) => arr.indexOf(el) === i)
      }
      if (request.ccn == "" || !request.ccn) request.ccn = []
      else if (typeof request.ccn == 'string') {
        arr = request.ccn.split(/[ ,]+/)
        request.ccn = arr.filter((el: any, i) => arr.indexOf(el) === i)
      }
    }
    return request
  }

  getCompanyInsSettings(companyId, insuranceCompanyId): Observable<any> {
    let body = {
      companyId: companyId,
      insuranceCompanyId: insuranceCompanyId
    }
    let url = this.urlBuilderService.withUrl(ApiRoutes.nonSeqTasksRoutes.getCompanyInsSettings).build();
    return this.httpService.get(url, body);
  }

  getDocumentList(): Observable<any> {
    let url = this.urlBuilderService.withUrl(ApiRoutes.nonSeqTasksRoutes.getDocumentList).build();
    return this.httpService.get(url);
  }
}