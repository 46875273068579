<mat-toolbar class="horizontal-menu">
  <div style="display: flex;">
    <span class="link" (click)="goToInbox()">
      <mat-icon iconPositionEnd>arrow_back_ios</mat-icon>
      <p>torna alla inbox</p>
    </span>
    <h2 class="page-title">{{pageTitle}}</h2>
  </div>
  <div style="display: flex; align-items: center;">
    <span class="divider">|</span>
    <span class="profile" style="font-size: 14px;">
      <span *ngIf="isAutenticated">{{ activeUser }}</span>
      <mat-icon class="account-icon">account_circle</mat-icon>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>expand_more</mat-icon>
      </button>
    </span>
  </div>

</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>