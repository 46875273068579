import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UrlBuilderService } from './common/url-builder.service';
import { HttpService } from './common/http.service';
import { TranslationsService } from './common/translations.service';
import { TasksService } from './tasks.service';
import { InsurancePractice, PageClaims } from '../models/common-classes';
import { ApiRoutes } from './api-routes';
import { ClaimDetails } from '../models/claim-details';

@Injectable({ providedIn: 'root' })
export class ClaimsSharedService {

  drawerComponent = new BehaviorSubject<ClaimDetails | InsurancePractice | string>(null);

  constructor(
    private _urlBuilderService: UrlBuilderService,
    private _httpService: HttpService,
    private _translationsService: TranslationsService,
    private _tasksService: TasksService
  ) {}

  updateUrgent(claimId: number, urgent): Observable<PageClaims> {
    let url = this._urlBuilderService
      .withUrl(ApiRoutes.claimsRoutes.updateUrgent)
      .withRoutedValues(claimId?.toString())
      .build();
    return this._httpService.put(url, urgent);
  }

  updateFavorite(claimId: number, favorite): Observable<PageClaims> {
    let url;
    if (favorite) {
      url = this._urlBuilderService
        .withUrl(ApiRoutes.claimsRoutes.removeFavorite)
        .withRoutedValues(claimId?.toString())
        .build();
      return this._httpService.delete(url);
    } else {
      url = this._urlBuilderService
        .withUrl(ApiRoutes.claimsRoutes.addFavorite)
        .withRoutedValues(claimId?.toString())
        .build();
      return this._httpService.post(url);
    }
  }
}
